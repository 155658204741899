:root {
  --header-height: 5em;
  --footer-height: 4em;
  --toolbar-height: 4.25em;
  --footer-bottom: 0;
  --primary: #403027;
  --primary-light: #50423b;
  --light-primary: #6f718b;
  --light2-primary: rgba(111, 113, 139, 0.15);
  --clr-secondary: #e63934;
  --clr-green: #00c180;
  --clr-light-green: rgba(0, 194, 129, 0.15);
  --clr-yellow: #ffbe00;
  --clr-yellow2: #fff700;
  --clr-light-yellow: #fff9db;
  --clr-red: #e63934;
  --clr-red2: #fd0000;
  --clr-red3: #c10000;
  --clr-grey: #9697a3;
  --clr-light-grey: #d0d0d0;
  --clr-light-grey2: #ececec;
  /* --clr-chat-right: #3f51b5;
  --clr-chat-left: #6675cc; */
  --clr-chat-right: #ececec;
  --clr-chat-left: #ffddc8;
  --font-family: "Roboto", sans-serif;
  --fz-main: 1rem;
  --fz-extra-large: 1.15rem;
  --fz-large: 0.85rem;
  --fz-medium: 0.8rem;
  --fz-normal: 0.75rem;
  --fz-small: 0.7rem;
  --fz-extra-small: 0.6rem;
}

body {
  font-size: 14px !important;
}

.new-style.not-logged-in {
  /* --footer-bottom: 58px; */
  --footer-bottom: 0px;
}

.new-style .searchWrap {
  padding: 26px 18px 0 18px;
  height: 60px;
  margin-bottom: 16px;
}

.new-style .sideDrawerList {
  width: 242px;
}

.new-style .searchDropdown,
.new-style .searchInput,
.new-style .searchIcon,
.new-style .searchDropdown .MuiSelect-select,
.new-style .searchDropdown .MuiSelect-select:focus {
  background-color: #fdf7ec;
}

.new-style .searchInput.white,
.new-style .searchIcon.white {
  background-color: #fff;
}

.new-style .MuiButton-root {
  text-transform: none !important;
}

.new-style div.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  left: auto;
  right: auto;
  max-width: 460px;
}

.new-style .sticky + .searchBar {
  padding-top: 48px;
}

.new-style .searchDropdown {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 15px;
  padding-top: 2px;
  border-right: 1px solid #d2d2d2;
}

.new-style .price-group {
  color: var(--primary);
  display: inline-block;
  line-height: 0.9rem;
  font-size: 0.9rem;
  white-space: break-spaces;
  padding-left: 4px;
}

.new-style .searchDropdown .MuiInput-root {
  max-width: 100%;
}

.new-style .searchDropdown .MuiInput-root,
.new-style .searchDropdown .MuiInput-underline::before,
.new-style .searchDropdown .MuiInput-underline::after {
  border: 0px !important;
}

.new-style .searchIcon {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.new-style .searchInput,
.new-style .searchIcon {
  background-color: var(--clr-light-grey);
}

.new-style .searchIcon svg {
  margin-top: 12px;
  margin-right: 10px;
  float: right;
  fill: #6d6d6d;
}

.new-style .searchInfo {
  padding: 0 10px 0 25px;
  font-size: 12px;
  color: #595959;
}

.new-style .searchInput {
  padding-top: 4px;
  padding-left: 18px;
  border-radius: 25px 0 0 25px;
}

.new-style .searchInput .MuiInputBase-input {
  font-size: 14px;
}

.new-style .titleSectionWrap {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #2a2a2a;
  color: #ffff00;
  width: 100%;
  /* position: fixed; 
  bottom: 0;
  z-index: 100;*/
}

.new-style .titleSectionWrap button {
  background-color: transparent;
  color: #ff0;
  border: none;
  border-radius: 16px;
  text-decoration: underline;
  font-size: 0.9rem;
}

.new-style .sortFlexBox.current {
  color: #ffff00;
}

.new-style .tab-panel {
  display: inline-block;
  width: 100vw;
  vertical-align: top;
  height: calc(100vh - var(--header-height) - calc(var(--footer-height) + var(--footer-bottom)));
  background-color: #00000000;
  transition: transform 0.4s ease-out;
  scroll-snap-align: center;
  overflow-y: auto;
  max-width: 460px;
  transition: height 0.4s linear;
  background: #fff;
  margin-top: var(--header-height);
}

.new-style .tab-panel.expanded {
  height: calc(100vh - 2px);
}

.new-style .puja-homa.tab-panel {
  background-color: #f8ddb4;
}

.new-style .titleSectionWrap h3 {
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.new-style .listItemIcon svg {
  fill: #ffffff;
}

.new-style .titleSectionWrap > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.new-style .titleSectionWrap .MuiSvgIcon-root {
  font-size: 20px !important;
}

.new-style .astrologerDetailsWrap .MuiGrid-container {
  flex-wrap: nowrap;
}

/* .searchParentDiv.new-style .astrologerDetailsWrap .MuiGrid-container {
  flex-wrap: wrap;
}

.searchParentDiv.new-style .astrologerDetailsWrap .MuiGrid-grid-xs-9 {
  max-width: inherit;
} */

.new-style .btn-view-profile {
  display: block;
  background: var(--clr-grey) !important;
  color: #fff;
  margin-top: 0;
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 1rem;
  border-radius: 0 0 12px 12px;
}

.new-style .btn-view-profile.online {
  background: var(--clr-green) !important;
}

.new-style .astrologerDetailsWrap.viewProfileBtn {
  border-radius: 12px 12px 0 0;
}

.new-style .astrologerDetailsWrap .MuiAvatar-root {
  height: 74px;
  width: 74px;
  margin: 0;
  border-radius: 18px !important;
}

.new-style .astrologerDetailsWrap img {
  padding: 1px;
  border-radius: 16px;
}

.new-style .astrologerDetails {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.new-style .astrologer-profile {
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 104;
  transform: translate3d(-50%, 0, 10px);
  background-color: #ffffff;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
  max-width: 460px;
  display: none;
}

.new-style .astrologer-profile #astrologerBg {
  overflow-y: auto;
  height: 100%;
}

.new-style .astrologer-profile #fa-ast-story {
  position: absolute;
}

.new-style .astrologer-profile.open {
  display: block;
}

.new-style .astrologerRating {
  font-size: 1rem;
  text-align: center;
  margin-top: 12px;
  padding-top: 4px;
}

.new-style .astrologerRating b {
  color: var(--primary);
}

.new-style .astrologerRating svg {
  width: 16px;
  margin-top: -2px;
  vertical-align: middle;
}

.new-style .astrologerRating small {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8rem;
  color: #6d6d6d;
}

.new-style .astrologerReview {
  text-align: center;
  line-height: 1;
  margin-top: 14px;
}

.new-style .astrologerReview span {
  font-size: 12px;
}

.new-style .homeFilterBox,
.new-style #homeNotificationsBox,
.new-style #notificationsDiv {
  color: #ffe6d1 !important;
}

.new-style .homeFilterBox .MuiDivider-root {
  margin: 20px 0;
  background: white;
}

.new-style #homeNotificationsBox .MuiDivider-root {
  margin-top: 19px;
  margin-bottom: 9%;
  background: #ffe6d1;
}

.new-style .homeFilterBox .MuiDrawer-paper {
  background: var(--primary);
  height: 72%;
}

.new-style .homeSortBox .MuiDrawer-paper {
  height: auto !important;
  width: 100%;
  max-width: 450px;
  left: 50%;
  transform: translate3d(-50%, 0, 10px) !important;
}

.new-style .navDrawer .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.65);
}

.new-style #homeNotificationsBox .MuiDrawer-paper {
  background: var(--primary);
  height: 80%;
}

.new-style .dialogImage {
  width: 69px;
  height: 69px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #ffffff;
  position: relative;
  background-color: #f5d6a7;
}

.new-style .dialogImage img {
  max-width: 100%;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  opacity: 1;
}

.new-style .dialogImage img.loaded {
  opacity: 1;
}

.new-style .dialogHead .dialogTitle {
  display: inline-block;
  width: calc(100% - 78px);
  margin-left: 8px;
  vertical-align: middle;
}

.new-style .dialogHead span {
  display: block;
  font-size: 0.9rem;
}

.new-style .dialogHead span:last-child {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-style .dialogBody span {
  display: inline-block;
  margin-top: 4px;
  color: #575757;
}

.new-style .astrologerDialog {
  max-width: 440px;
  margin: auto;
}

.new-style .dialogBody .error,
.new-style .dialogBody .success {
  padding: 8px 0 0 0;
  color: #ff0000;
}

.new-style .pendingReviewPanel {
  transition: bottom 0.4s ease-out;
  position: fixed;
  bottom: -300vh;
  background-color: #ffffff;
  z-index: 107;
  width: 100%;
  left: auto;
  right: auto;
  max-width: 460px;
  box-shadow: 0 -4px 16px rgb(0 0 0 / 50%);
  padding: 16px;
  height: auto;
  padding-bottom: 64px;
  transform: translateZ(16px);
  white-space: break-spaces;
}

.new-style .pendingReviewPanel.open {
  bottom: var(--footer-height);
}

.new-style .pendingReviewPanel .rating-error {
  animation: shakeerror 1.8s;
  animation-direction: alternate;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 72px 0;
  }
}

@keyframes shakeerror {
  0%,
  6%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  1%,
  5% {
    transform: translate3d(-10px, 0, 0);
  }

  3% {
    transform: translate3d(10px, 0, 0);
  }

  40%,
  60% {
    /* transform: translate3d(4px, 0, 0); */
  }
}

.new-style .smooth-height {
  will-change: height;
}

.new-style .autoHideHeader {
  position: fixed;
  width: 100%;
  max-width: 460px;
  z-index: 100;
  left: auto;
  top: 0;
  transition: top 0.4s ease-out 0.2s;
  will-change: top;
}

.new-style .autoHideHeader.folded {
  top: -64px;
}

.new-style .autoHideHeader.folded + .app-install-banner {
  top: calc(120px - 64px);
  opacity: 1;
}

.new-style .autoHideHeader ~ .searchBar .header-spacer {
  height: var(--header-height);
}

.new-style .autoHideHeader.folded ~ .searchBar .header-spacer {
  height: 58px;
}

.new-style .autoHideHeader.folded ~ .searchBar ~ .tab-panel {
  height: calc(100vh - 58px);
}

.new-style .pendingReviewPanel .ratingBar {
  width: calc(100% - 112px);
  display: inline-block;
  margin-left: 8px;
}

.new-style .pendingReviewPanel .dialogImage {
  width: 94px;
  height: 94px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  vertical-align: top;
  margin-top: 4px;
}

.new-style .pendingReviewPanel .dialogImage .MuiAvatar-root {
  width: 94px;
  height: 94px;
  border-radius: 0;
}

.new-style .pendingReviewPanel .text {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  font-size: 0.9rem;
  line-height: normal;
  margin-bottom: 12px;
}

.new-style .pendingReviewPanel .reviewText {
  border: none;
  background-color: #eeeeee;
  border-radius: 5px;
  height: 64px;
  padding: 8px;
  color: #323232;
  font-size: 0.9rem;
  font-family: sans-serif;
  min-width: 100%;
  resize: none;
}

.new-style .pendingReviewPanel .reviewText::placeholder {
  font-family: sans-serif;
}

.new-style .dialogBody .success {
  color: #5e5e5e;
}

.new-style .astrologerDialog .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.65);
}

.new-style .astrologerDialog .MuiDialog-paperScrollPaper {
  background-color: #fbead0;
  border-radius: 18px;
  overflow: hidden;
  width: 90vw;
  margin: 8px;
  max-width: 400px;
}

.new-style .dialog {
  overflow-y: auto;
}

.new-style .dialogButton .MuiButton-label {
  white-space: nowrap;
}

.new-style .dialogButton.primary,
.new-style .dialogButton.secondary,
.new-style .dialogButton.booking,
.new-style .dialogButton.secondary:hover {
  color: #fff;
  border: none;
  background-color: #00c180 !important;
  height: 36px;
  border-radius: 18px;
  box-shadow: none;
  font-size: 0.9rem;
  min-width: 90px;
  padding: 0 16px;
  box-shadow: none !important;
}

.new-style .dialogButton.booking {
  background-color: var(--primary) !important;
  margin-left: 4px;
  vertical-align: middle;
  padding: 0 12px;
  margin-top: 4px;
}

.new-style .dialogButton.booking:disabled {
  background-color: #cfcfcf !important;
}

.new-style .dialogButton.secondary,
.new-style .dialogButton.secondary:hover {
  background-color: #b9b9b9 !important;
}

.new-style .ctrl-group span {
  font-size: 0.8rem;
  color: #555;
  width: 40%;
  display: inline-block;
}

.new-style span.offerBanner {
  display: block;
  text-align: center;
  background-color: #795548;
  color: #ffffff;
  width: 100%;
  font-size: 0.8rem;
  padding: 6px 0;
}

.new-style span.warningBanner {
  display: block;
  text-align: center;
  background-color: #ffff00;
  color: #323232;
  width: 100%;
  font-size: 0.8rem;
  padding: 8px;
}

.new-style .dialogBody span:nth-child(even) {
  width: calc(60%);
  vertical-align: top;
}

.new-style .dialogBody span:nth-child(odd) {
  width: 40%;
  font-weight: 700;
}

.new-style .dialogBody {
  padding: 16px;
  font-size: 0.8rem;
}

.new-style .dialogBody a {
  text-decoration: underline;
  color: var(--primary);
}

.new-style .dialogHead {
  padding: 16px;
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 18px 18px 0 0;
}

.new-style .homeFilterBox .MuiBackdrop-root,
.new-style #homeNotificationsBox .MuiBackdrop-root {
  background: none !important;
}

.new-style .homeFilterBox.homeSortBox {
  background: #000a;
  transform: translateZ(10px);
}

.new-style .filterOptionFlex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.new-style .filterOptionText {
  background: rgb(255 255 255 / 55%) !important;
  border: 1px solid rgb(255, 255, 255, 0.6);
  padding: 10px 0;
  margin: 10px 1% 0 1%;
  font-size: 0.9em;
  border-radius: 6px;
  width: 31%;
  text-align: center;
}

.new-style .filterOptionTextActive {
  background: rgb(255, 255, 255) !important;
}

.new-style #homeFilterBoxDiv {
  padding: 20px 25px 50px 25px;
}

.new-style .homeFilterBoxCategoryText {
  color: white;
  margin-bottom: 5px;
}

.new-style #notificationsFlexBox {
  display: flex;
  justify-content: space-between;
}

.new-style #notificationsDiv {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 25px;
}

.new-style #notificationsText {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.new-style .notificationsChildFlex {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.new-style #newText {
  margin-bottom: 30px;
}

.new-style .notificationsChildHeading,
.new-style #newText {
  font-weight: bold;
}

.new-style #notificationsParentDiv {
  font-size: 0.766em;
}

.new-style .notificationsAvatar .MuiAvatar-colorDefault {
  height: 46px !important;
  width: 46px !important;
}

.new-style .notificationsDateDiv,
.new-style .notificationTextDiv {
  color: #ffe3bbb8;
  text-align: justify;
}

.new-style .notificationsAvatar .MuiAvatar-colorDefault {
  color: #fafafa !important;
  background-color: #ffbf6052 !important;
  padding: 4px !important;
}

.new-style #notificationsParentDiv .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 8px;
  padding-right: 8px;
}

.new-style .notificationsDateDiv {
  letter-spacing: -0.5px;
}

.new-style #helloGuestDrawerText {
  font-size: 1.8em;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 45px;
  color: #ffe6d1;
}

.new-style .homeFilterFooter {
  padding: 8px;
  background-color: rgb(255 255 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: right;
}

.new-style .homeFilterButtons,
.new-style .homeFilterButtonsClear {
  margin-left: 5px;
  margin-right: 0px;
  padding: 3px 10px;
  border-radius: 3px;
}

.new-style .homeFilterButtonsClear {
  color: var(--primary);
}

.new-style .homeFilterButtons {
  color: white;
  background-color: var(--primary);
}

.new-style .circle-loading-20px {
  margin-left: 0px !important;
}

.new-style .onlineCardsOnly .offline,
.new-style .onlineCardsOnly .away,
.new-style .astrologerDetailsWrap .masked {
  display: none !important;
}

.new-style .toastBar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
  height: 28px;
  font-size: 0.8rem;
  line-height: 28px;
  border-radius: 0;
  white-space: nowrap;
  border-top: 1px solid #ef7938;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 80px;
}

.new-style .cashbackLabel {
  font-size: 0.8rem;
  color: #ffffff;
  background-color: #8a1a40;
  line-height: 0.9rem;
  transform: translateY(0);
  text-align: center;
  padding: 8px;
  border-radius: 12px 12px 0 0;
}

.new-style .toastBar.toastBarOpen {
  display: block;
  z-index: 100;
  opacity: 1;
  position: static;
  top: auto;
  order: -1;
  border: 0;
}

.new-style .toastBar.toastBarOpen.online {
  background-color: rgba(14, 154, 70, 0.9);
}

.new-style .toastBar.toastBarOpen.offline {
  background-color: rgba(0, 0, 0, 0.7);
}

.new-style .astrologerDetailsWrap {
  border-radius: 12px;
  background-color: #fdf7ec;
  margin-top: 14px;
  padding: 10px;
  height: auto;
  position: relative;
  overflow: hidden;
  transition: opacity 0.4s ease-out, height 0.4s ease-out 0.3s;
}

.ribbon {
  background: #f65800;
  background-image: linear-gradient(45deg, #ed2500, #ff970f);
  text-align: center;
  color: #fff;
  padding: 0.3rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  overflow: hidden;
  position: relative;
}

.ribbon:before {
  content: "";
  height: 100%;
  width: 100px;
  background: red;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotateZ(-45deg);
  margin-left: -90px;
}

.ribbon-both {
  --ribbon-size: 1rem;
  --gradient-start: #ed2500;
  --gradient-end: #ff970f;
  color: #fff;
  background-image: linear-gradient(90deg, var(--gradient-start), var(--gradient-end));
  /* padding: 0 var(--ribbon-size); */
  padding: 0;
  font-size: calc(var(--ribbon-size) - 0.15rem);
  height: calc(var(--ribbon-size) * 2);
  line-height: calc(var(--ribbon-size) * 2);
  position: relative;
  text-align: center;
  margin: 0 calc(var(--ribbon-size) * 2);
  isolation: isolate;
  margin-top: 0.5rem;
}

.ribbon-both:before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: calc(-2 * var(--ribbon-size));
  border: var(--ribbon-size) solid var(--gradient-start);
  border-left-color: transparent;
  z-index: -1;
}

.ribbon-both:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  right: calc(-2 * var(--ribbon-size));
  border: var(--ribbon-size) solid var(--gradient-end);
  border-right-color: transparent;
}

.ribbon-both span {
  display: block;
  margin: 0 calc(var(--ribbon-size) * -1);
  z-index: 1;
  position: relative;
}

.new-style .tabbled-container-app {
  scroll-behavior: smooth;
}

.new-style .tabbed-head {
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  height: var(--footer-height);
}

.new-style .tabbed-head span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.new-style .new-style.tabbed-head.tabbed-head-col-2 span {
  width: 50%;
}

.new-style .tabbed-head span b {
  transition: background-color 0.4s ease-out;
  background-color: var(--primary);
  padding: 0.5em 1em 0em 1em;
  opacity: 0.87;
  border-bottom: 6px solid transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
}

.new-style .tabbed-head svg {
  font-size: 1.6rem;
}

.new-style .tabbed-head b span {
  font-size: 0.8rem;
}

.new-style .tabbed-head.tabbed-head-col-2 span b {
  border-bottom: none;
  opacity: 1;
}

.new-style .tabbed-head span.current b {
  border-color: var(--clr-red);
}

.new-style .astrologerDetailsWrap button.busy {
  animation: progress-bar-stripes 2s linear infinite;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  text-align: center;
  color: #fff;
  background: #62c281;
  background-image: linear-gradient(
    45deg,
    #4d9a66 25%,
    transparent 25%,
    transparent 50%,
    #4d9a66 50%,
    #4d9a66 75%,
    transparent 75%,
    transparent
  );
  background-size: 36px 36px;
}

.new-style .astrologerDetailsWrap button.book.busy {
  background-image: none;
  background: #f17c3c;
}

.new-style .astrologerDetailsWrap button.busy:disabled {
  background-image: none;
}

.new-style .offerStampBanner {
  margin-top: 4px;
  padding: 8px;
  background-color: #f17c38;
  color: #ffffff;
  position: relative;
}

.new-style .nextCallOffer {
  height: auto;
  position: relative;
  overflow: hidden;
}

.new-style .nextCallOffer .backdrop {
  position: absolute;
  right: 0;
  top: -66px;
  width: 56%;
  opacity: 0.3;
}

.new-style .nextCallOffer .offerStampBanner {
  height: 100%;
  border-radius: 12px;
  background-color: #d1fb29;
  color: #5d5d5d;
  overflow: hidden;
}

.new-style .confetti {
  z-index: 1000;
  position: relative;
  transform: translate(100%, -48px);
  width: 50%;
}

.new-style .nextCallOffer .offerStampBanner .txtIcon {
  color: #ffffff;
  font-size: 1.3rem;
  left: 22px;
  width: 36px;
  top: 50%;
  transform: translateY(-50%);
}

.new-style .nextCallOffer .offerStampBanner .offerIcon {
  fill: #f17c38;
  width: 64px;
  height: 64px;
}

.new-style .offerStampBanner .offerText {
  width: calc(100% - 72px);
  line-height: 0.9rem;
  display: inline-block;
  font-size: 0.9rem;
  vertical-align: middle;
  padding-top: 2px;
}

.new-style .offerStampBanner .offerText small {
  font-size: 0.8rem;
}

.new-style .MuiSvgIcon-root.filterIcon {
  width: 0px;
  height: 0px;
  display: inline-block;
  vertical-align: middle;
  fill: var(--primary);
  transition: all 0.2s ease-out;
}

.new-style .filterButton.active .MuiSvgIcon-root.filterIcon {
  width: 16px;
  height: 16px;
  transform: translateY(-1px);
}

.new-style .MuiSvgIcon-root.filterIcon + b {
  font-weight: 400;
  display: inline-block;
  padding: 6px 4px 4px 4px;
}

.new-style .offerStampBanner .txtIcon {
  color: #f17c38;
  position: absolute;
  left: 10px;
  display: inline-block;
  width: 24px;
  top: 12px;
  font-weight: 700;
  text-align: center;
}

.new-style .expertiseFilter {
  text-align: left;
  padding: 24px 10px;
  color: #5e5e5e;
  font-weight: 700;
  font-size: 0.9rem;
}

.new-style .offerStampBanner .offerIcon {
  display: inline-block;
  margin-right: 8px;
  fill: #fff;
  vertical-align: middle;
}

.new-style .titleBarIcon {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 4px;
  color: white;
  font-size: 0.9rem;
  margin-right: 1px;
  border-radius: 0;
  min-width: 32px;
}

.new-style .titleBarIcon svg {
  fill: white;
}

.new-style .offerStamp span {
  position: absolute;
  z-index: 2;
  right: -3px;
  text-align: center;
  top: 2px;
  width: 32px;
  line-height: 2.1rem;
  height: 32px;
}

.new-style .offerStamp {
  position: absolute;
  right: 12px;
  top: 8px;
  color: #ffffff;
  font-weight: 700;
  z-index: 4;
  width: 32px;
  height: 32px;
  border-radius: 0 12px 0 8px;
  text-align: center;
  line-height: 29px;
}

.new-style .promotionalList {
  /*border-radius: 0 0 10px 10px;*/
  overflow: hidden;
}

.new-style .promotionalList .offerStamp svg {
  fill: #8a1a40 !important;
}

.new-style .promotionalList .astrologerDetailsWrap {
  background-color: #ffdba4;
  border-radius: 0;
  margin-top: 0;
}

.new-style

/*.promotionalList > div:last-child .astrologerDetailsWrap {
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #d6af74;
}*/
.new-style .astrologerProfileImage {
  border-radius: 50%;
  width: 12px;
  display: inline-block;
  height: 12px;

  vertical-align: middle;
}

.new-style .astrologerProfileImageRed {
  background-color: #9c9c9c;
}

.new-style .astrologerProfileImageGreen {
  background-color: #00c180 !important;
}

.new-style .notFoundPaper {
  margin: 5% 0;
  padding: 30px 10px;
  text-align: center;
  font-size: 19px;
}

.new-style #sideDrawerLogo {
  height: 30px;
  margin: 10px 0px 4px 0px;
}

.new-style #sideDrawerLogoDiv {
  border-bottom: 1px solid#ff903869 !important;
  text-align: left;
  padding-left: 16px;
}

.new-style .sortByText {
  color: white;
  margin: 10px 15px;
}

.new-style .sortFlexBox {
  display: flex;
  align-items: center;
  border-top: 1px #ffffff29 solid;
  padding: 10px 0px 10px 15px;
  color: white;
}

.new-style .sortOptionBox {
  width: 100%;
  margin-left: 15px;
}

.new-style .availableFilterActive {
  background-color: var(--primary) !important;
  color: white !important;
}

.new-style #dailyIconDiv svg,
.new-style #dailyIconDiv p,
.new-style #blogIconDiv svg,
.new-style #blogIconDiv p,
.new-style #weeklyIconDiv svg,
.new-style #weeklyIconDiv p {
  pointer-events: none;
}

.new-style .searchWrap .MuiSelect-icon {
  top: calc(50% - 15px);
}

.new-style .filter-panel {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 460px;
  right: calc(-100vw - 48px);
  top: 0;
  z-index: 101;
  transition: transform 0.4s ease-out;
  display: flex;
  justify-content: flex-end;
  transform: translate3d(0, 0, 10px);
}

.new-style .filter-panel .container b,
.new-style .filter-panel .container .title {
  color: var(--primary);
  font-weight: 700;
  margin-bottom: 10px;
}

.new-style .apply-action > div + button,
.new-style .apply-action > div + button + button {
  height: calc(100% - 24px);
}

.new-style .apply-action .count,
.new-style .apply-action .error {
  height: 24px;
  white-space: nowrap;
  padding: 0 8px;
  color: #323232;
  background: #ffff00;
  font-weight: 700;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.new-style .apply-action .count.noonline,
.new-style .apply-action .error {
  background: #f00;
  color: #fff;
}

.new-style .filter-set.disabled {
  color: #f82c2c;
}

.new-style .apply-action button:disabled {
  background: #ffc8c8;
  color: #ff0000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-style .filter-panel .container .warn-icon + span {
  display: none;
}

.new-style .filter-set label {
  max-width: calc(100% - 56px);
  display: flex;
}

.new-style .filter-set label:hover ~ svg.warn-icon + span,
.new-style .filter-set svg.warn-icon:hover + span {
  position: absolute;
  display: none;
  background-color: #323232;
  display: inline-block;
  padding: 10px;
  width: auto;
  z-index: 4;
  max-width: 260px;
  color: #fff;
  border-radius: 10px;
  right: 0;
  transform: translateY(24px);
}

.new-style .filter-set img.warn-icon + span b {
  margin-bottom: 10px;
  display: inline-block;
}

.new-style .filter-set img {
  opacity: 0.6;
}

.new-style .filter-set.none label {
  opacity: 0.5;
  filter: grayscale(1);
}

.new-style .apply-action button.disabled {
  background-color: #aaa;
}

.new-style .filter-set img.warn-icon:hover + span,
.new-style .filter-set img.warn-icon + span:hover {
  position: fixed;
  background-color: #fbead0;
  display: inline-block;
  padding: 16px;
  width: calc(100% - 48px);
  z-index: 105;
  max-width: 450px;
  font-size: 0.8rem;
  color: #323232;
  border-radius: 10px;
  right: 2.5%;
  top: 50%;
  max-height: 70vh;
  overflow-y: auto;
  transform: translate(0, -50%);
  box-shadow: 0 0 6px rgb(0 0 0 / 30%);
}

.new-style .pdl-xx {
  padding-left: 24px;
}

.new-style .filter-panel .container .primary-cta {
  color: #fff;
  padding: 5px 15px;
  z-index: 11;
  font-size: 0.8rem;
  min-width: 80px;
  background: #00c180;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 20px;
  border: none;
  height: 36px;
}

.new-style .filter-panel .container .section {
  line-height: 1.6rem;
  margin-bottom: 24px;
}

.new-style .filter-set input,
.new-style .filter-set input + span {
  display: inline-block;
  max-width: calc(100% - 27px);
  vertical-align: top;
}

.new-style .filter-panel .container {
  background-color: #fff;
  width: calc(100% - 32px);
  height: calc(100% - 10px);
  margin-top: 10px;
  max-width: 400px;
  box-shadow: -12px -5px 44px rgb(0 0 0 / 50%);
  padding: 16px 16px 16px 24px;
  overflow-y: auto;
  padding-bottom: 80px;
  border-radius: 18px 0 0 18px;
}

.new-style .filter-summary {
  position: fixed;
  left: 50%;
  bottom: 0;
  background-color: #323232;
  color: #fff;
  z-index: 100;
  width: 100vw;
  max-width: 460px;
  transform: translate3d(-50%, 0, 6px);
  display: flex;
  justify-content: space-between;
}

.new-style .filter-summary span {
  display: flex;
  align-items: center;
}

.new-style .filter-summary span b {
  color: #ffff00;
  padding: 4px 8px 4px 20px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
}

.new-style .filter-summary b {
  padding: 4px 8px;
}

.new-style .apply-action {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 32px;
  top: auto;
  height: 48px;
  background: var(--primary);
  padding: 0;
  height: 72px;
}

.new-style .apply-action button.cancel {
  background-color: #cfcfcf;
  color: #323232;
  width: calc(40% - 16px);
}

.new-style .apply-action button {
  border: none;
  background-color: #00c180;
  color: #fff;
  padding: 8px 16px;
  width: calc(60% - 16px);
  height: 100%;
  box-sizing: border-box;
}

.new-style .filter-panel.open {
  transform: translate3d(calc(-100vw - 48px), 0, 0);
  /*backface-visibility: hidden;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;*/
}

.new-style .filter-set input {
  margin-right: 10px;
}

.new-style .group-container > div:only-child > div:first-child {
  display: none;
}

.new-style .fab.filter-list {
  width: 56px;
  height: 56px;
  background: #6b1f32;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 54px;
  right: 18px;
  z-index: 102;
  transform: translate3d(0, 0, 5px);
}

.new-style .fab img {
  width: 24px;
  height: auto;
  fill: #fff;
}

.new-style .filter-set {
  display: flex;
  align-items: start;
  margin-bottom: 8px;
  position: relative;
}

.new-style .filter-item {
  padding: 10px 0;
}

.new-style .hidden {
  display: none !important;
}

@media (min-width: 801px) {
  .new-style .searchWrap {
    display: flex !important;
    margin-left: 0;
  }

  .new-style .searchInfo {
    padding: 0px 15px;
  }

  .new-style .titleSectionWrap {
    padding-top: 15px;
  }

  .new-style .apply-action {
    max-width: 432px;
    left: 60px;
  }
}

.new-style .switch-slider {
  display: inline-block;
  width: 46px;
  position: relative;
  margin-left: 4px;
}

.new-style .switch-slider .handle {
  position: absolute;
  background-color: #ffffff;
  display: block;
  height: 8px;
  left: 8px;
  width: calc(100% - 24px);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  z-index: 3;
}

.new-style .switch-slider input {
  opacity: 0;
}

.new-style .switch-slider input + i.roll {
  background-color: #cfcfcf;
  padding: 8px;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  transition: left 0.2s ease-out;
  z-index: 4;
}

.new-style .switch-slider input:checked + i.roll {
  left: 18px;
  background-color: var(--primary);
}

.new-style .titleSectionWrap > div .MuiAvatar-rounded:last-child {
  margin-right: 8px;
}

.new-style .testimonial {
  font-size: 0.8rem;
  padding: 4px 16px 24px 16px;
  background: #ffffff;
  margin-top: 26px;
  border-radius: 12px;
  color: #5a5a5a;
}

.new-style .testimonial span {
  color: #747474;
  margin-top: 12px;
  display: block;
  font-size: 0.9rem;
}

.new-style .review {
  text-align: right;
  font-size: 0.8rem;
  padding: 4px 16px;
  color: #ee6230;
}

.new-style .newAstrologer {
  position: absolute;
  z-index: 5;
  width: 119px;
  text-align: center;
  background: #f44336;
  color: #fff;
  transform: rotate(-45deg);
  left: -48px;
  top: -6px;
  line-height: 0.9rem;
  font-size: 0.8rem;
  padding: 16px 30px 6px 30px;
  font-weight: 700;
  box-shadow: 4px 3px 4px rgb(0 0 0 / 30%);
}

.new-style .promoListItems {
  overflow-x: auto;
  /* border: 2px solid #8a2840; */
  border-radius: 0 0 14px 14px;
  padding: 0 1px;
  margin-bottom: 2px;
}

.new-style .promoListItems .row {
  /* background-color: #ecd0a4; */
  padding-right: 0px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

@media screen and (max-width: 600px) {
  .new-style .promoListItems .row::-webkit-scrollbar,
  .new-style .confirm-booking .dialog-content::-webkit-scrollbar {
    display: none;
  }
}

.new-style .promotionalList .astrologerDetailsWrap {
  margin: 0;
  margin-right: 8px;
  border-radius: 14px;
  background-color: #8a2840;
  width: calc(100vw - 56px);
  /* width: 306px; */
  max-width: 412px;
  color: #fff;

  margin-bottom: 8px;
}

.new-style .promotionalList .single .row .astrologerDetailsWrap {
  width: calc(100vw - 36px);
}

.new-style .fapromotionalList.full,
.new-style .noFavAstrologer {
  width: 100%;
}

.new-style .fapromotionalList.full .astrologerDetailsWrap {
  width: 100%;
  margin-top: 10px;
}

.new-style .promoListItems .row > div {
  display: inline-block;
  width: calc(100% - 30px);
  margin-bottom: 4px;
  scroll-snap-align: center;
}

.new-style .promoListItems .astrologerDetailsWrap {
  margin-bottom: 0px;
}

.new-style .promoListItems .row > div {
  margin-right: 8px;
  vertical-align: top;
}

.new-style .promoListItems .row > div.visible:last-of-type {
  margin-right: 0;
}

.new-style .promoListItems .row > div.visible:only-child {
  width: 100%;
}

.new-style .fapromotionalList .offerStamp svg {
  fill: #8a2840 !important;
}

/* high contrast promo theme */
.new-style .promotionalList .offerStamp svg {
  fill: #ffffff !important;
}

.new-style .promotionalList .astrologerDetails * {
  color: #fff !important;
}

.new-style .promotionalList .astrologerDetails button.away *,
.new-style .promotionalList .astrologerDetails .freemin {
  color: #5d5d5d !important;
  fill: #5d5d5d !important;
}

.new-style .promotionalList .offerStamp span,
.new-style .promotionalList .offerStamp small {
  color: #8a1a40 !important;
}

.new-style .promotionalList .astrologerRating small {
  color: #fff;
}

.new-style .promotionalList .n-title {
  padding-right: 70px;
}

.new-style .promotionalList .fav-icon {
  fill: #ffffff;
}

.new-style .promotionalList .fav-icon-dis {
  fill: #4f1725;
}

.new-style .noFavAstrologer {
  background-color: #fdf7ec;
  text-align: center;
  padding: 24px;
  border-radius: 14px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.new-style .MuiButton-root.away {
  background-color: #9f9f9f;
}

.new-style .astrologerDetailsWrap span.MuiButton-label {
  white-space: nowrap;
}

.new-style .group-container {
  background-color: transparent;
  padding: 1px 8px;
  margin: 0;
  border-radius: 12px;
  width: 100%;
  max-width: 460px;
}

.new-style .group-container:empty {
  display: none;
}

.new-style .group-container.offer-astrologers {
  background-color: #f8ddb4;
  min-height: 204px;
  border-radius: 0 0 20px 20px;
  margin-top: -16px;
  padding-top: 16px;
}

.new-style .group-container.offer-astrologers .pred-cover {
  background-color: transparent;
  height: 16px;
  border-radius: 0 0 20px 20px;
  width: calc(100% + 16px);
  margin: -16px 0 0 -8px;
  max-width: 460px;
}

.new-style .group-container.offer-astrologers:empty {
  visibility: hidden;
  min-height: auto;
  padding: 16px 8px;
}

.new-style .group-container-dp.predictions-gp {
  margin-top: 0;
  padding: 0;
  margin-bottom: 0;
  background-color: #fdf7ec;
  overflow: hidden;
  border-radius: 0;
  height: 330px;
}

/*https://opensource.apple.com/source/WebCore/WebCore-1889.1/css/mediaControls.css.auto.html*/
.new-style .ast-audio audio::-webkit-media-controls-current-time-display,
.new-style .ast-audio audio::-webkit-media-controls-time-remaining-display,
.new-style .ast-audio audio::-webkit-media-controls-mute-button,
.new-style .ast-audio audio::-webkit-media-controls-play-button,
.new-style .ast-audio audio::-webkit-media-controls-volume-slider,
.new-style .ast-audio audio::-webkit-media-controls-timeline,
.new-style .ast-audio audio::-webkit-media-controls-seek-back-button {
  filter: invert(1);
  text-shadow: none;
}

.new-style .ast-audio audio::-webkit-media-controls-panel {
  background-color: var(--audio-panel);
  border-radius: 0;
}

.new-style .ast-audio audio {
  box-shadow: none;
}

.new-style ::-webkit-media-slider-thumb {
  display: none;
}

.new-style .ast-audio {
  --audio-panel: #8b0a2e;
  background-color: var(--audio-panel);
  border-radius: 0 0 10px 10px;
  height: 36px;
  overflow: hidden;
}

.new-style .promoListItems .ast-audio {
  --audio-panel: #323232;
}

.new-style .ast-audio audio {
  height: 48px;
  width: calc(100% + 46px);
  margin-left: -8px;
  margin-top: -6px;
}

/* .new-style .astrologerDetailsWrap.has-audio {
  border-radius: 10px 10px 0 0;
} */

.new-style .book-controls .MuiInputAdornment-positionEnd {
  display: none;
}

.new-style .book-controls .MuiFormControl-root {
  width: calc(55% - 4px);
  margin-right: 4px;
  background-color: #fff;
  border-radius: 6px;
}

.new-style .book-controls .MuiOutlinedInput-input {
  padding: 12px;
  font-size: 0.9rem;
  background-color: #0000;
}

.new-style .book-controls .MuiSelect-select:focus {
  background-color: #0000;
}

.new-style .book-controls {
  padding: 16px 16px 0 16px;
}

.new-style .book-controls .ctrl-group {
  margin-bottom: 10px;
}

.new-style .book-controls .error-balance {
  color: #f00;
  display: inline-flex;
  align-items: center;
  padding: 7px 0;
  font-weight: 700;
}

.new-style .book-controls a.btn {
  text-decoration: underline;
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  padding: 10px 10px;
  color: #00c180;
  font-weight: 700;
  border-radius: 32px;
  width: auto;
  text-align: center;
  min-width: 80px;
}

.new-style .book-controls .error-balance svg {
  margin-right: 4px;
  width: 18px;
}

.new-style .ctrl-group img.info-icon {
  width: 24px;
  vertical-align: middle;
}

.new-style .ctrl-group .free-min + div {
  display: none;
  position: absolute;
  background: #000;
  width: 90%;
  padding: 8px;
  border-radius: 4px;
  transform: translateZ(10px);
  z-index: 100;
  color: #ccc;
}

.new-style .ctrl-group .free-min:hover + div {
  display: block;
}

.new-style .upcoming-bookings {
  position: fixed;
  bottom: 84px;
  background-color: #6b1f32;
  width: 56px;
  height: 56px;
  padding: 8px;
  color: #fff;
  text-align: center;
  /* box-shadow: 0 0 6px rgb(0 0 0 / 50%); */
  z-index: 99;
  font-weight: 700;
  overflow: visible;
  border-radius: 50%;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-style .upcoming-bookings span {
  display: block;
  width: 24px;
  height: 24px;
  background: #f00;
  border-radius: 50%;
  font-size: 0.8rem;
  padding: 4px 0;
  position: absolute;
  right: -4px;
  top: -4px;
  text-align: center;
}

.new-style .book-controls .MuiOutlinedInput-input:disabled,
.new-style .book-controls .MuiInputBase-input.Mui-disabled {
  opacity: 0.5;
}

.new-style .confirm-booking .dialog-content {
  width: 100%;
  overflow-y: auto;
  max-height: 60vh;
  padding-bottom: 40px;
}

.new-style .confirm-booking .book-slot {
  background-color: #e9e9e9;
  margin: 4px 0;
  font-size: 0.8rem;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  transition: all 0.4s ease-out;
}

.new-style .confirm-booking .book-slot-wrap {
  width: 100%;
  margin-bottom: 10px;
  min-height: 160px;
}

.new-style .confirm-booking .MuiFormControl-root {
  width: 160px;
  padding-left: 8px;
  margin: 14px 0;
}

.new-style .confirm-booking .MuiFormControl-root .MuiInputAdornment-root {
  display: none;
}

.new-style .confirm-booking .MuiFormControl-root input {
  padding: 8px 10px;
}

.new-style .confirm-booking .ctrl-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 324px) {
  .new-style .promotionalList .astrologerDetailsWrap button {
    width: 84px;
  }

  .new-style .promotionalList .astrologerDetailsWrap button span.MuiButton-label,
  .new-style .astrologerDetailsWrap button span.MuiButton-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  .new-style .promotionalList .astrologerDetailsWrap button span.MuiButton-startIcon,
  .new-style .astrologerDetailsWrap button span.MuiButton-startIcon {
    display: none;
  }

  .new-style .promotionalList .astrologerDetailsWrap .status-caption {
    display: inline-block;
    width: 84px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .new-style .ast-card-ctabar .item:last-child {
    width: 50%;
  }

  .new-style .astrologerDialog .MuiDialog-paperScrollPaper {
    min-height: auto;
  }

  .new-style .dialogButton.booking {
    width: 80%;
    margin-top: 8px;
  }
}

.new-style

/* .promoListItems .row > div:first-child .astrologerDetailsWrap {
  animation: astrologerCardSlideIn 0.4s ease-out 2s;
}
@keyframes astrologerCardSlideIn {
  .new-style from {
    margin-left: 66px;
  }
  .new-style to {
    margin-left: 8px;
  }
} */
.new-style .MuiButton-iconSizeMedium>svg {
  font-size: 20px;
  margin-right: 4px;
}

.new-style .MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-right: 0;
}

.new-style .footer-bar {
  position: fixed;
  width: 100%;
  bottom: var(--footer-bottom);
  max-width: 460px;
  z-index: 100;
  left: auto;
  transition: top 0.4s ease-out 0.2s;
  will-change: top;
}

/* .newbui .new-style .header-bar {
  background: red;
} */

.new-style .circle-loading {
  left: 0;
}

.new-style .header-bar {
  display: flex;
  justify-content: space-between;
}

.new-style .header-bar-logo {
  /* visibility: hidden; */
  display: none;
}

.new-style .infinite-scroll-component {
  white-space: normal;
}

/* Drawer bg */
#root:has(.new-style) ~ .MuiDrawer-root.navDrawer .MuiDrawer-paper {
  background: var(--primary) !important;
}

#root:has(.new-style) ~ .MuiDrawer-root.navDrawer .MuiDrawer-paper #sideDrawerLogoDiv,
#root:has(.new-style) ~ .MuiDrawer-root.navDrawer .MuiDrawer-paper .MuiListItem-root {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

body:has(.new-style) .MuiAlert-filledWarning.warningSnackBar,
body.newbui .MuiAlert-filledWarning.warningSnackBar {
  background: var(--primary);
}

.CCDialog {
  text-align: center;
  max-width: 460px;
}

.CCDialog .MuiBackdrop-root {
  background: rgba(47, 49, 72, 0.75);
}

.CCDialog .MuiDialog-paperScrollPaper {
  background: #fff;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  margin: 1.25rem;
}

.CCDialog .dialogBody {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem;
}

.CCDialog .header {
  background-color: var(--clr-green);
  text-align: center;
  color: #fff;
  font-size: 1.15rem;
  padding: 0.75rem 0;
  height: var(--height);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CCDialog .header .MuiSvgIcon-root {
  position: relative;
  top: -3px;
}

.CCDialog .header {
  background-color: #f6f6f6;
  color: var(--clr-green);
}

.CCDialog .avatarImage {
  --size: 6.66rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

.CCDialog .actionInfo {
  font-size: 0.9rem;
}

.CCDialog .astrologerName {
  font-size: 1.1rem;
  font-weight: 600;
}

.CCDialog .astrologerCnInfo {
  color: var(--clr-grey);
}

.CCDialog .free_cc .free-tag,
.free_cc_external .free-tag {
  background: var(--clr-green);
  color: #fff;
  font-weight: 500;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  display: inline-block;
  font-size: 0.9rem;
  margin: 0.35rem 0;
}

.content-free-chat-info,
.content-paid-chat-info {
  font-size: 0.9rem;
}

.CCDialog .footer {
  padding: 0 !important;
}

.CCDialog .footer .dialogButton {
  box-shadow: none;
  border-radius: 0;
  margin: 0 !important;
  flex: 1 1;
  padding: 1rem !important;
  height: inherit;
  border-top: 1px solid #dcdcdc;
}

.CCDialog .footer .dialogButton.secondary {
  background-color: #fff !important;
  color: #5b5b5b;
}

.CCDialog .footer .dialogButton.primary {
  background-color: var(--clr-green) !important;
  border-color: var(--clr-green);
}

.CCDialog.overlay-birth-form .MuiBackdrop-root {
  background: #fff;
}

.CCDialog.overlay-birth-form .MuiDialog-paperScrollPaper {
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%);
}

#new-chat #chat-conv-empty {
  display: none;
}

#new-chat .chat-main-window {
  display: none;
}

.new-ui-chat-window {
  box-shadow: none;
}

.new-ui-chat-window .chat-window-header {
  background: var(--primary);
}

.new-ui-chat-window .chat-window-previous-conversation button {
  background: #dddddd;
  color: black;
}

.new-ui-chat-window .chat-window-body {
  background: #fff;
}

div.active-call-newUI {
  bottom: var(--footer-height);
}

div.active-call-newUI p {
  white-space: normal;
}

div.active-call-newUI button {
  background: var(--clr-red) !important;
  cursor: pointer;
}

.new-search-ui {
  background: #fff;
}

.new-search-ui .searchArrowIcon {
  color: var(--primary);
}

.new-ui-chat-window .chat-message.left {
  background: var(--clr-chat-left);
  color: #000;
  min-width: 118px;
}

.new-ui-chat-window .chat-message.right {
  background: var(--clr-chat-right);
  color: #000;
}

.new-t2a,
.new-t2a .consultation {
  white-space: break-spaces;
}

.new-t2a .tab-panel {
  margin-top: 0;
}

.new-t2a .ast-section-title {
  display: none;
}

.new-style .headerOptMenuIcon .MuiSvgIcon-root {
  font-size: 1.25rem;
}

.newbui .headerOptMenu .MuiMenuItem-root {
  font-size: 0.95rem;
  line-height: 1.25;
  min-height: 2.5rem;
}

.newbui .MuiPickersToolbar-toolbar,
.newbui .MuiPickersDay-daySelected,
.newbui .MuiPickersClock-pin,
.newbui .MuiPickersClockPointer-pointer,
.newbui .MuiPickersClockPointer-noPoint,
.newbui .MuiPickersDay-daySelected:hover {
  background-color: var(--clr-green);
}

.newbui .MuiButton-textPrimary {
  color: var(--clr-green);
}

.newbui .MuiPickersMonth-monthSelected,
.newbui .MuiTypography-colorPrimary {
  color: var(--clr-green);
}

.newbui .MuiPickersClockPointer-thumb {
  border-color: var(--clr-green);
}

.threeDotAnimation:after {
  content: "";
  animation: threeDotAnimation 2s infinite;
  width: 0;
  display: inline-block;
  margin-right: 1em;
}

@keyframes threeDotAnimation {
  0% {
    content: "";
  }

  20% {
    content: ".";
  }

  40% {
    content: "..";
  }

  60% {
    content: "...";
  }

  95% {
    content: "";
  }

  100% {
    content: "";
  }
}

.anchor-link {
  text-decoration: underline;
  cursor: pointer;
}

.a-link {
  display: inline-flex;
  background: var(--clr-light-green);
  color: var(--clr-green);
  align-items: center;
  gap: 0.5rem;
  height: 18px;
  padding: 0.65rem 0.6rem;
  border-radius: 13px;
  position: relative;
  margin: 0.3rem 0;
  cursor: pointer;
}

.a-link-icon {
  padding-left: 30px;
}

.a-link svg {
  position: absolute;
  left: 10px;
  font-size: 1rem;
}

.a-link-whatsapp {
  color: #fff;
  background: var(--clr-green);
}

/* Instant Payment Popup */
.instant-popup .MuiDrawer-paper {
  max-width: 460px;
  margin: auto;
  overflow: visible;
}

.card-v2 {
  background: #fff;
}

.card-v2__header {
  background: var(--clr-green);
  text-align: center;
  color: #fff;
  padding: 1rem;
  font-weight: bold;
}

#root:has(.new-style) ~ .MuiDrawer-root.instant-popup .MuiDrawer-paper {
  background: transparent !important;
}

.instant-popup .MuiDrawer-paper .card-v2,
.instant-popup .MuiDrawer-paper .card-v2__header {
  border-radius: 1rem 1rem 0 0;
}

.card-v2__header_pic {
  --pic-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(calc(var(--pic-size) / 2.5) * -1);
  margin-bottom: calc(var(--pic-size) / 15);
}

.card-v2__header_pic .MuiAvatar-root {
  width: var(--pic-size);
  height: var(--pic-size);
  box-shadow: 0 0 0 var(--clr-green), 0 0 0 rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s 0.1s, box-shadow ease-in-out 0.3s 0.2s;
  transform: scale(0.75) translateY(20px);
}

.instant-popup .card-v2__header_close {
  position: absolute;
  right: 0;
  top: 2px;
}

.instant-popup .card-v2__header_close .MuiIconButton-root {
  color: #fff;
}

.instant-popup .card-v2__header_close .MuiSvgIcon-root {
  font-size: 1.5rem;
}

.MuiPaper-root[style*="transform: none"] .card-v2__header_pic .MuiAvatar-root {
  transform: scale(1);
  box-shadow: 0 0 0 5px var(--clr-green), 0 5px 10px rgba(0, 0, 0, 0.5);
}

.card-v2__content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-v2__content_info {
  white-space: pre-line;
}

.card-v2__options_before {
  font-weight: bold;
}

.count-down-timer {
  display: flex;
  gap: 0.25rem;
}

.count-down-timer__timer {
  font-weight: bold;
  color: var(--clr-red);
}

/* Recharge options */
.recharge-option {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.recharge-option__item .wrap {
  --recharge-btn-width: 100px;
  display: flex;
  border-radius: 0.75rem;
  overflow: hidden;
}

.recharge-option__item .info {
  padding: 0.75rem;
  background: var(--clr-light-grey2);
  flex: 100%;
}

.recharge-option__item .actions {
  width: var(--recharge-btn-width);
  display: flex;
}

.recharge-option__item .action-button {
  background: var(--clr-green) !important;
  border-radius: 0;
  color: #fff;
  font-size: var(--fz-large);
}

.recharge-option__item .action-button .icon {
  margin-top: 6px;
}

.recharge-option__item .price {
  display: flex;
  gap: 0.3rem;
}

.recharge-option__item .price .actual-amount {
  color: var(--clr-red);
  text-decoration: line-through;
}

.recharge-option__item .price .discount {
  background: var(--clr-yellow2);
  padding: 0 0.2rem;
}

.recharge-option__item.wallet-item .texts {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.recharge-option__item.wallet-item .column.icon .MuiSvgIcon-root {
  font-size: var(--fz-extra-large);
}

.recharge-option__item.wallet-item .action-button {
  box-shadow: none;
}

.recharge-option__item.wallet-item .accordion {
  margin-left: -1rem;
  margin-top: 0.75rem;
  margin-bottom: -0.75rem !important;
  box-shadow: none;
  background: rgb(252, 255, 158);
  margin-right: calc(var(--recharge-btn-width) * -1);
  padding-right: var(--recharge-btn-width);
}

.recharge-option__item.wallet-item .accordion .MuiAccordionSummary-root,
.recharge-option__item.wallet-item .accordion:before {
  display: none;
}

.recharge-option__item.wallet-item .accordion table {
  width: 100%;
  border-collapse: collapse;
}

.recharge-option__item.wallet-item .accordion th {
  font-size: var(--fz-medium);
}

.recharge-option__item.wallet-item .accordion th,
.recharge-option__item.wallet-item .accordion td {
  padding: 0.15rem 0.5rem;
}

.recharge-option__item.wallet-item .accordion th:last-child,
.recharge-option__item.wallet-item .accordion td:last-child {
  text-align: right;
}

.recharge-option__item.wallet-item .accordion .highlight-row {
  --radius: 0.5rem;
  font-weight: bold;
  background: var(--clr-light-grey2);
  border-radius: var(--radius);
}

.recharge-option__item.wallet-item .accordion .highlight-row td:first-child {
  border-radius: var(--radius) 0 0 var(--radius);
}

.recharge-option__item.wallet-item .accordion .highlight-row td:last-child {
  border-radius: 0 var(--radius) var(--radius) 0;
}

.recharge-option__item.wallet-item .accordion .highlight-row.payable + tr td {
  padding-top: 0.75rem;
}

.recharge-option__item.wallet-item .accordion .MuiAccordionDetails-root {
  padding: 0.75rem;
}

.recharge-option__item.wallet-item .info {
  background-color: #f1f1f1;
  padding-right: 0;
  font-size: var(--fz-normal);
  padding-right: var(--recharge-btn-width);
}

.recharge-option__item.wallet-item .actions {
  position: absolute;
  bottom: 0;
  top: 0;
  right: calc(var(--recharge-btn-width) * -1);
  margin: -0.75rem 0;
}

.recharge-option__item.highlight .info {
  background: rgb(238, 40, 0);
  background: linear-gradient(90deg, rgba(238, 40, 0, 1) 0%, rgba(255, 143, 0, 1) 100%);
  color: #fff;
  transition: all ease 0.3s;
  position: relative;
  isolation: isolate;
}

.recharge-option__item.highlight .info:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(270deg, rgba(238, 40, 0, 1) 0%, rgba(255, 143, 0, 1) 100%);
  animation: rotateBG 2s linear calc(-1 * 2s) infinite;
  isolation: isolate;
  z-index: -1;

  animation: rotateBG 100s linear infinite alternate;
  isolation: isolate;
  z-index: -1;
  opacity: 0.5;
  margin: -25%;
  border-radius: 25%;
}

.recharge-option__item.highlight .info:before {
  right: var(--recharge-btn-width);
}

@keyframes rotateBG {
  0% {
    transform-origin: left top;
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform-origin: right bottom;
  }
}

@keyframes OpacityAnim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.recharge-option__item.highlight .column.icon .MuiIconButton-root {
  color: #fff;
}

.recharge-option__item.highlight .accordion {
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
}

.recharge-option__item.wallet-item .accordion .highlight-row {
  background: rgba(0, 0, 0, 0.1);
}

.recharge-option__item.highlight .accordion .highlight-row {
  background: rgba(0, 0, 0, 0.25);
}

.recharge-option__item.highlight .highlight-text span {
  background: #fff;
  color: rgb(238, 40, 0);
  padding: 0 0.5rem;
  display: inline-block;
  border-radius: 0.25rem;
  font-weight: bold;
}

.recharge-option__item.wallet-item.highlight .highlight-text {
  top: -3px;
  left: -3px;
  position: absolute;
}

.recharge-option__item.wallet-item.highlight .highlight-text + .amount {
  margin-top: 18px;
}

.recharge-option__item .text-green {
  font-weight: bold;
}

.recharge-option__item:not(.highlight) .text-green {
  color: var(--clr-green);
}

.recharge-preload {
  margin-top: 0.5rem;
}

.recharge-preload .MuiSkeleton-root {
  width: 100%;
  max-width: inherit;
  transform: scale(1);
  border-radius: 1rem;
  margin-bottom: 5px;
}

.recharge-option__item.wallet-item + .wallet-item {
  padding-top: 0.5rem;
}

.recharge-option__item.wallet-item .option-text {
  font-size: var(--fz-medium);
}

.recharge-option__item.wallet-item .option-text span {
  color: var(--clr-green);
}

.recharge-option__item.wallet-item.expand-recharge-btn .info {
  position: relative;
}

.recharge-option__item.wallet-item.expand-recharge-btn .texts {
  position: static;
}

.recharge-option__item.wallet-item.expand-recharge-btn .actions {
  right: 0;
  z-index: 100;
}

.recharge-option__item.wallet-item.expand-recharge-btn.highlight .highlight-text {
  margin: 12px;
}

.recharge-option__item.deal-item .info .texts {
  font-size: 1rem;
}

.recharge-option__item.deal-item .info .texts .amount-price {
  font-size: 1.25rem;
}

@media (max-width: 385px) {
  .recharge-option__item .amount .gst {
    display: block;
  }
}

/* @media (max-width: 360px) {
  .recharge-option__item .amount .amount-gst {
    display: block;
  }

  .recharge-option__item .amount .gst {
    display: inline;
  }
} */

.card-v2__before_footer {
  color: var(--clr-grey);
}

.instant-popup-btn-wrap {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 15px;
  z-index: 100;
}

.instant-popup-btn-wrap .text {
  color: #000;
  text-align: center;
  font-size: 13px;
  margin-top: 8px;
  background: #fff;
  border-radius: 10px;
  padding: 0.1rem 0.4rem;
}

.instant-popup-btn-wrap .btn-hold {
  transition: all 0.3s ease;
  display: none;
  transform-origin: center;
  position: relative;
  transform: scale(0);
}

.instant-popup-btn-wrap.show .btn-hold {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: expandCircle 0.3s forwards;
  animation-timing-function: cubic-bezier(0, 0.72, 0.18, 1.35);
}

.btn-instant-toggler {
  --size: 52px;
  height: var(--size);
  width: var(--size);
  background: rgb(255 142 2) !important;
  box-shadow: 0 8px 10px rgb(255 142 2 / 45%);
  animation: zoomie 0.5s infinite alternate;
}

.btn-instant-toggler svg {
  height: auto;
}

@keyframes expandCircle {
  0% {
    /* transform: scale(0); */
    transform: translateY(100px);
  }

  100% {
    /* transform: scale(1); */
    transform: translateY(0px);
  }
}

@keyframes zoomie {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.15);
  }
}

.app-page {
  --page-gutter: 1rem;
  --page-width: 460px;
  display: flex;
  padding: 0 var(--page-gutter);
  width: 100%;
  max-width: var(--page-width);
  flex-direction: column;
  gap: var(--page-gutter);
  position: relative;
}

.app-page__header {
  background-color: var(--primary);
  margin: 0 calc(var(--page-gutter) * -1);
  padding: 0 var(--page-gutter) 0 calc(var(--page-gutter) / 2);
  display: flex;
  height: var(--header-height);
  align-items: center;
  gap: calc(var(--page-gutter) / 2);
  color: #fff;
  max-width: var(--page-width);
  min-height: var(--header-height);
}

.app-page__header__title {
  font-size: 1.15rem;
}

.app-page__header__back-icon.MuiIconButton-root {
  color: #fff;
}

.wallet__title,
.MuiButton-root.wallet__title {
  color: var(--clr-red);
  font-weight: bold;
  font-size: var(--fz-medium);
}

.wallet__info__points {
  font-size: 2.75rem;
  font-weight: 300;
}

.wallet__info__points sub {
  font-size: 1.5rem;
  position: relative;
  top: -10px;
}

.wallet__info__ins {
  text-decoration: none;
  margin-top: -7px;
  display: block;
  color: var(--clr-grey);
}

.MuiButton-root.wallet__transactions_link {
  position: absolute;
  right: 6px;
  top: 80px;
  text-decoration: underline;
  padding: 3px 10px;
  border-radius: 0.5rem;
  border: 0;
  background: transparent;
  font-family: var(--font-family);
}

.reviews-panel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.reviews-panel__title {
  font-weight: bold;
  text-align: center;
  margin-bottom: -1rem;
}

.reviews-panel__astrologer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  align-items: center;
}

.reviews-panel__astrologer-name {
  font-weight: bold;
  font-size: var(--fz-large);
}

.reviews-panel__astrologer-reviews {
  flex: 100% 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.reviews-card,
.reviews-panel__review {
  background-color: var(--clr-light-yellow);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: var(--fz-medium);
}

.reviews-card .col-div {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.reviews-card .MuiRating-root {
  font-size: 1rem;
}

.reviews-card .reviews-card__username {
  padding: 0.5em 0.8em 0.5em;
  flex: 1;
  font-weight: 700;
}

.reviews-card .reviews-content {
  padding: 0.6em 0.2em 0.4em;
  font-size: 0.85rem;
  color: #414141;
}

.reviews-card .reviews-footer-text {
  color: #e55a17;
  font-style: italic;
  font-size: 0.7rem;
  padding: 0.5em 0.8em;
}

.reviews-panel__review .MuiSvgIcon-fontSizeInherit {
  font-size: 1rem;
}

.review-preload .MuiCardHeader-root {
  padding: 16px 0;
}

@media (min-width: 400px) {
  .recharge-option__item.wallet-item .info .texts {
    font-size: var(--fz-large);
  }
}

.newbui .chat-window-body.status-auto-end + .chat-window-footer .chat-not-accepted,
.newbui .chat-window-body.status-rejected + .chat-window-footer .chat-not-accepted {
  display: none;
}

.newbui .chat-window-body.status-auto-end .chat-message + .try-another-astrologer > div,
.newbui .chat-window-body.status-rejected .chat-message + .try-another-astrologer > div {
  padding-top: 0;
}

/*
.newbui .chat-window-header.reconnecting .astrologer-display,
.newbui .chat-window-footer.reconnecting {
  display: none;
}
*/

/* .home-main-circle-loading {
  z-index: 999999;
} */
@media (max-width: 370px) {
  .new-style .astrologerInfo .btn-action.MuiButton-root {
    padding: 0.3rem 0.75rem;
  }
}

@media (max-width: 335px) {
  .new-style .astrologerInfo .btn-action.MuiButton-root {
    padding: 0.25rem 0.5rem;
  }
}

@media screen and (max-width: 334px) {
  .new-style .promotionalList .astrologerDetailsWrap button span.MuiButton-startIcon,
  .new-style .astrologerDetailsWrap button span.MuiButton-startIcon {
    display: none;
  }
}
