.modalWrapper {
  --gap: 2rem;
  --height: 52px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

.modalWrapper,
.modalWrapper :global(*) {
  box-sizing: border-box;
}

.modalWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 100;
  padding: 0 calc(var(--gap) / 2);
}

.modalWrapper:global(.bottom-drawer) > :global(div[class*="modalWrap"]) {
  align-items: flex-end;
}

.modalHolder {
  display: flex;
  background: #fff;
  flex-direction: column;
  width: 100%;
  max-width: calc(460px - var(--gap));
  border-radius: 1.5rem;
  overflow: hidden;
  z-index: 50;
  transition: transform ease 0.3s, opacity ease 0.2s;
}

.modalWrapper:global(.bottom-drawer) :global(div[class*="modalHolder"]) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  background: var(--primary);
  opacity: 0.5;
  z-index: 20;
  transition: opacity ease 0.3s;
}

.modalHeader {
  background-color: var(--clr-green);
  text-align: center;
  color: #fff;
  font-size: 1.15rem;
  padding: 0.75rem 0;
  height: var(--height);
  font-weight: 600;
}

.modalHeader:global(.invert) {
  background-color: #f6f6f6;
  color: var(--clr-green);
}

.modalHeader:global(.error) {
  background-color: var(--clr-red);
}

.modalContent {
  min-height: 12rem;
}

.modalAction {
  margin-top: 1rem;
  margin-top: 1rem;
  display: flex;
}

.modalAction > * {
  flex: 1;
  padding: 1rem;
  font-size: 0.95rem;
  height: var(--height);
}

.modalAction :global(.MuiButtonBase-root) {
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: #fff;
  border-top: 1px solid #dcdcdc;
  margin: 0 !important;
}

.modalAction :global(.MuiButtonBase-root):hover {
  background-color: #f8f8f8;
}

.modalAction > :global(.primary) {
  background-color: var(--clr-green) !important;
  color: white;
}

.modalAction > :global(.primary):hover {
  background-color: var(--clr-green);
  filter: brightness(0.95);
}

/* Loading */
.modalWrapper :global(div[class*="modalContent"]) {
  position: relative;
}

.modalWrapper :global(div[class*="modalContent"]) :global(.content),
.modalWrapper :global(.loading),
.modalWrapper :global(div[class*="modalAction"]) {
  transition: opacity ease 0.3s;
  opacity: 1;
}

.modalWrapper :global(.loading) {
  position: absolute;
  inset: 0;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  margin-top: 2em;
}

.modalWrapper :global(.MuiCircularProgress-colorPrimary) {
  color: var(--clr-yellow);
}

.modalWrapper:global(.loading) :global(.content) {
  opacity: 0.5 !important;
  pointer-events: none;
}

.modalWrapper:global(.loading) :global(.loading) {
  opacity: 1;
}

.modalWrapper:global(.loading) :global(div[class*="modalAction"]) {
  filter: grayscale(1) opacity(0.5);
  pointer-events: none;
}

.modalWrapper :global(.waiting-loader) {
  display: none;
  color: #fff;
  position: relative;
  z-index: 100;
}

.modalWrapper:global(.waiting) :global(div[class*="modalHolder"]) {
  display: none;
}

.modalWrapper:global(.waiting) :global(.waiting-loader) {
  display: block;
}

.modalWrapper:global(.waiting) :global(div[class*="modalOverlay"]) {
  opacity: 0.95 !important;
}
