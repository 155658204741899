.header {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  background: #ee600d;
  align-items: center;
}

.title {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.bottomCurve {
  height: 20px;
  border-radius: 0 0 20px 20px;
  background: #ee600d;
}

.formwrap {
  margin: 2px 10px 0 10px;
  padding: 0 16px 16px 16px;
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}

.userInput {
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  background-color: #ffffff;
}

.plLabel {
  margin-top: 20px;
}

.tableWrap {
  margin-top: 10px;
}

.submitButton {
  color: white !important;
  border-radius: 40px !important;
  padding: 10px !important;
  background: #00c180 !important;
  position: static !important;
  bottom: 20px;
  width: 100%;
  margin-left: auto !important;
  max-width: 370px;
}

.submitButtonArrow {
  border-radius: 24px !important;
  background: rgba(255, 255, 255, 0.35);
  border: none !important;
  position: absolute;
  right: 15px;
}

.rupeeicon {
  padding-left: 4px;
  padding-right: 8px;
  color: #626262;
  font-size: 1.2rem;
  font-weight: 400;
}
.labelitem {
  display: block;
  padding: 4px 0;
}
.labelitem:nth-child(3) {
  border-top: 1px solid #999999;
  margin-top: 8px;
  padding-top: 8px;
}
.labelname {
  display: inline-block;
  width: calc(100% - 100px);
}
.labelvalue {
  display: inline-block;
  width: 100px;
}
.infopanel {
  padding: 8px 0px;
  background-color: transparent;
  margin: 16px 0;
  border-radius: 8px;
}
.warning {
  color: red;
  padding-top: 10px;
}
.warning:empty {
  display: none;
}
.gst {
  font-size: 0.8rem;
  color: #a10000;
  padding-top: 4px;
  padding-left: 0;
}
.offer {
  text-align: center;
  color: #f44336;
  font-size: 0.9rem;
  padding-top: 12px;
  font-weight: 700;
}
.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.navbar svg {
  fill: white;
  margin-right: 10px;
}
.titleBubble {
  padding: 8px 16px;
  margin: 8px;
  color: #ed6237;
  border-radius: 16px;
  font-weight: 700;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 32px;
}
div.formControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
}
.logo {
  display: flex;
  padding-top: 36px;
  align-items: center;
  justify-content: center;
}
