.searchTopArea {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
}
.similarTopArea {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
}
.searchArrowIcon {
  color: #ff8400;
  margin: 26px 15px 0 0px;
}
.searchChildTopArea {
  margin-top: 30px;
  line-height: 6px;
  font-size: 19px;
}
.similarChildTopArea {
  margin-top: 32px;
  line-height: 6px;
  font-size: 19px;
}
.searchText {
  color: #ee600d;
  margin-bottom: -0.83em;
  line-height: 30px;
  word-break: break-all;
}
.searchParentDiv {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #f8ddb4;
  min-height: 100vh;
}
.similarSearchText {
  line-height: 22px;
  margin-top: 0px;
  margin-bottom: -10px;
}
.extra-circle {
  padding-top: 10%;
  align-items: start;
}
.extra-circle2 {
  position: relative;
  margin-left: 0px !important;
}
.searchPageyoumightalsolikeText {
  text-align: center;
  padding: 10px;
  font-size: 19px;
  color: grey;
}
.searchPageNotFoundPaper {
  margin: 5% 0;
  padding: 30px 0;
  text-align: center;
  font-size: 19px;
}
