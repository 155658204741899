@font-face {
  font-family: astrovisionregular;
  src: url(https://ca-resources.s3.ap-south-1.amazonaws.com/fonts/astrovision-webfont.eot);
  src: url(https://ca-resources.s3.ap-south-1.amazonaws.com/fonts/astrovision-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(https://ca-resources.s3.ap-south-1.amazonaws.com/fonts/astrovision-webfont.woff) format("woff"),
    url(https://ca-resources.s3.ap-south-1.amazonaws.com/fonts/astrovision-webfont.ttf) format("truetype"),
    url(https://ca-resources.s3.ap-south-1.amazonaws.com/fonts/astrovision-webfont.svg#astrovisionregular) format("svg");
  font-weight: 400;
  font-style: normal;
}
