.accountParentDiv {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  padding-bottom: 16px;
}

.sectionSwitch button {
  border: none;
  height: 40px;
  width: 50%;
  background-color: #eee;
  color: #333;
  transition: all 0.2s linear;
}

.sectionSwitch button.active {
  background-color: #ee600d;
  color: #fff;
}

.sectionSwitch {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
}

.accountTopArea {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  color: #5d5d5d;
}

.accountChildTopArea {
  margin-top: 36px;
  line-height: 6px;
  font-size: 19px;
}

.accountChildTopArea2 {
  margin-top: 36px;
  line-height: 6px;
  font-size: 19px;
}

.accountText {
  color: #ee600d;
  margin-bottom: 37px;
}

#accountDiv {
  background-color: #fbead0;
  position: relative;
  height: auto;
  width: 100%;
  color: #5d5d5d;
  border-radius: 12px;
  margin-right: auto;
  margin-left: auto;
  font-size: 0.9rem;
  padding: 10px;
}

#accountDiv #accountDetailDiv {
  line-height: 1.8em;
  display: flex;
  flex-direction: column;
  margin-left: -16px;
}

#accountDiv .MuiAvatar-root {
  height: 70px;
  width: 70px;
  background-color: #ee600d;
  margin: auto;
  border-radius: 50% !important;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

#accountDiv #accountEditButton {
  position: absolute;
  color: #ee600d;
  border-radius: 5px;
  height: 20px !important;
  right: 10px;
  top: 10px;
}

#accountName {
  font-weight: 600;
}

#mailIcon,
#phoneIcon {
  font-size: 15px;
  margin-bottom: -4px;
  margin-right: 7px;
}

.accountOptions {
  border-bottom: 1px solid rgb(228, 228, 228);
  height: auto;
  display: flex;
  align-items: center;
  padding: 8px 0 16px 0;
}

.accountOptionsIcon {
  position: relative;
  background-color: #fff;
  border-radius: 20%;
  padding: 10px;
  font-size: 50px !important;
  color: #ee600d;
}

.accountOptionsText {
  margin-left: 18px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #5d5d5d;
}

.accountOptionsArrow {
  margin-left: auto;
  margin-right: -6px;
}

#accountWalletPoints {
  font-size: 14px;
  margin-left: auto;
  margin-right: 2px;
}

#accountLogoutButton {
  box-shadow: none;
  background-color: transparent;
  width: 151px;
  display: flex;
  border: none;
  border-radius: 10px;
  padding: 7px;
}

#accountLogoutIcon {
  color: #ee600d;
  font-size: 30px;
  margin-left: 5px;
  margin-right: -8px;
}

#accountButton {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 64px;
}

#accountLogoutText {
  margin-left: 12px;
  color: #5d5d5d;
}

.walletItemDiv > span small {
  font-size: 0.8rem;
  color: #6a6a6a;
}

.walletDiv {
  background-color: #fbead0;
  height: auto;
  width: 100%;
  color: #ee600d;
  border-radius: 15px;
  margin: 10px auto 20px auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.reloadButtonDiv {
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  color: #ee600d;
}

#reloadButton,
#reloadButtonCons {
  background: #fbead0;
  color: #ee600d;
  height: 23px;
  box-shadow: none;
  padding: 6px;
  border-radius: 7px;
  margin: 15px 15px 0 0;
}

#reloadIcon,
#reloadIconCons {
  font-size: 14px;
}

#reloadText,
#reloadTextCons {
  font-size: 10px;
  font-weight: normal;
  padding-left: 4px;
}

.walletBalanceText {
  font-size: 18px;
  margin: 30px 0 0 30px;
}

.walletPointsText {
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 4rem;
  text-align: center;
  padding: 24px 0;
}

.pointsText {
  font-weight: normal;
  letter-spacing: 0px;
  font-size: 14px;
  position: relative;
  top: -2px;
  left: 5px;
}

.walletName {
  margin: 20px 0 0 30px;
  font-size: 14px;
}

#addMoney {
  font-weight: 600;
  font-size: 15px;
  margin-left: 11px;
  margin-bottom: -29px;
}

#payHistory {
  font-weight: 600;
  font-size: 15px;
  margin-left: 11px;
  margin-bottom: 14px;
}

.dayHistory {
  font-size: 14px;
  margin-left: 11px;
  margin-bottom: 3%;
  margin-top: 8%;
  font-weight: bold;
}

#payHistorySub {
  font-size: 12px;
  margin-left: 11px;
  margin-bottom: 14px;
  margin-top: -9px;
}

#amountInput {
  font-size: 1.7em;
}

.walletItemDiv {
  list-style: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 16px 0 16px 10px;
  border-bottom: 1px solid #c1af93;
}

.walletItemDiv .rc-button,
.walletItemDiv .rc-button:hover {
  background: #ffaa00;
  width: 100%;
  padding: 4px 8px;
  color: #ffffff;
  border-radius: 24px;
  text-align: center;
  font-weight: 400;
}

.walletItemDiv > span:first-child {
  width: 30.33%;
}

.walletItemDiv > span {
  display: inline-block;
  width: 33.33%;
  font-size: 1rem;
  color: #5d5d5d;
}

.walletItemDiv > span.points {
  text-align: right;
  padding-right: 8px;
}

.walletItemDiv > span.premium {
  color: #00c180;
  font-weight: 600;
}

.walletItemDiv > span.bign {
  font-size: 1.1rem;
  color: #009ac1;
}

.walletItemDiv > span.econ + span button,
.walletItemDiv > span.econ + span button:hover {
  background-color: #ee600d;
  color: #ffffff;
}

.walletItemDiv > span.premium + span button,
.walletItemDiv > span.premium + span button:hover {
  background-color: #00c180;
  color: #ffffff;
}

.walletItemDiv > span.bign + span button,
.walletItemDiv > span.bign + span button:hover {
  background-color: #009ac1;
  color: #ffffff;
}

.accountParentDiv .MuiInput-root {
  border: none;
  padding-bottom: 3px;
  padding-left: 33px;
  background: none;
  margin-right: 20px;
  margin-left: 20px;
}

#rupeeSign {
  position: relative;
  font-weight: 600;
  font-size: 31px;
  top: 46px;
  left: 19px;
}

#walletSaveButton {
  color: white;
  margin-bottom: 35px;
  width: 100%;
  margin-top: 9%;
  border-radius: 40px;
  padding: 10px;
  background: #ee600d;
  font-weight: bold;
}

#walletSubmitArrowIcon {
  color: white;
  background: rgba(255, 255, 255, 0.35);
  border: none !important;
  border-radius: 20px !important;
  position: absolute;
  right: 15px;
}

#walletAmountOptions {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  padding-left: 2px;
  margin-top: 20px;
  padding-bottom: 64px;
}

.amountDiv {
  margin: 5px !important;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #dadada !important;
  background: #f5f5f5 !important;
  color: black !important;
  opacity: 0.9 !important;
}

.amountDiv:active {
  border: 1px solid #ee600d !important;
  background: #ffd6bc !important;
  color: black !important;
  opacity: 0.9 !important;
}

.accountParentDiv .MuiGrid-grid-xs-3 {
  max-width: 30%;
  flex-basis: 100%;
}

.walletIconCons {
  background: #ffe4d4;
  font-size: 38px !important;
  padding: 8px;
  color: #ee600d;
  border-radius: 50%;
}

.flexParent {
  margin-bottom: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #fff7f7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flexChild1 {
  margin-left: 8px;
}

.flexChild2 {
  flex-grow: 2;
  font-size: 14px;
  margin-left: 6%;
}

.flexChild2 h3 {
  margin: 4px 0;
  color: #323232;
}

.flexChild3 {
  margin-right: 8px;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: right;
  max-width: 100px;
}

.statusgreen {
  color: #009a13 !important;
}

.statusred {
  color: #ff0c00 !important;
}

.statusyellow {
  color: #ff8800 !important;
}

.statusblue {
  color: #008cff !important;
}

.historyDateText {
  color: #8e8e8e;
  font-size: 12px;
}

@media (max-width: 320px) {
  .reloadButtonDiv {
    left: 69%;
  }

  #accountLogoutButton {
    padding: 5px;
    width: 127px;
  }

  #accountWalletPoints {
    font-size: 12px;
    margin-top: -12px;
  }

  #accountName {
    font-size: 16px;
  }

  #mailIcon,
  #phoneIcon {
    font-size: 14px;
  }

  .accountParentDiv .MuiGrid-grid-xs-3 {
    max-width: 29%;
    flex-basis: 100%;
  }

  .accountOptionsText {
    font-size: 14px;
  }

  #accountLogoutText {
    font-size: 14px;
  }
}

.favoritesDetailsWrap {
  border: 1px solid rgb(206, 206, 206);
  border-radius: 10px;
  margin-top: 10px;
  background-color: #f7f7f7c2;
  margin-bottom: 10px;
  padding: 15px 10px;
}

.favoritesDetailsWrap .MuiGrid-container {
  flex-wrap: nowrap;
}

.favoritesDetailsWrap .MuiAvatar-root {
  height: 60px;
  width: 60px;
  border: 1.5px dashed #ee600d;
  margin: auto;
  border-radius: 50% !important;
}

.favoritesDetailsWrap img {
  padding: 1px;
  border-radius: 50%;
}

.favoritesRating {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

.favoritesReview {
  text-align: center;
  line-height: 1;
}

.favoritesReview span {
  font-size: 12px;
}

.favoritesDetails {
  font-size: 0.8em;
  padding-left: 5px;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}

.favoritesName {
  font-size: 1.2em;
  font-weight: bold;
}

.noResults {
  text-align: center;
  padding: 10px;
  font-size: 20px;
  color: grey;
}

.accountArrowIcon,
.accountArrowIcon2 {
  color: #5d5d5d !important;
  margin: 24px 15px 0 0px;
}

.accountArrowIcon {
  color: #ff8400 !important;
  margin: 24px 15px 0 0px;
  background-color: #e563392b !important;
}

.favoritesNotFoundPaper {
  margin: 5% 0;
  padding: 30px 0;
  text-align: center;
  font-size: 19px;
}

.favoritePageNoReviews {
  text-align: center;
  font-size: 0.7rem;
}

.invoice {
  color: #5d5d5d;
}

.invoice.cashback.cancelled {
  display: none;
}

.invoice.consultation {
  background-color: #feefe7;
}

.invoice.transaction {
  background-color: #fdf2d1;
}

.invoice.discount,
.invoice.refunded {
  background-color: #f2fff3;
}

.invoice .status.cancelled {
  color: #d44242;
}

.invoice .status.initiated {
  color: #ff6600;
}

.invoice .status.completed {
  color: #009900;
}

.invoice .amount {
  color: #525252;
}

.invoice .type span {
  text-transform: uppercase;
  color: #5d5d5d;
}

.invoice.consultation.completed .amount {
  color: #df2200;
}

.invoice.initiated .amount {
  color: #ff6600;
}

.deleteDialog .MuiDialog-paper {
  border-radius: 12px;
}

.invoice.booking {
  background: #fcffda;
}

.invoice.booking.refund {
  background: #fffccc;
}

.newbui .flexParent {
  background: var(--light2-primary);
}

.newbui .walletIconCons {
  background: var(--light2-primary);
  color: var(--primary);
}

.newbui .free-chat span {
  background: var(--clr-green);
  color: #fff;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin-top: 0.5rem;
}

.newbui .historyDateText-button {
  color: #ee600d;
  font-size: 15px;
  text-decoration: underline;
}

.newbui .walletItemDiv {
  border: 0;
  border-top: 1px solid #c1af93;
}

.newbui .walletItemDiv:first-child {
  border: 0;
}

.newbui .walletItemDiv.highlight {
  border: 3px solid orange;
  border-radius: 10px;
  padding: 14px;
}

.newbui .walletItemDiv.highlight + .walletItemDiv {
  border-top: 0;
}

.newbui .walletItemDiv .highlight-text {
  font-size: 0.85rem;
  margin-top: -13px;
  letter-spacing: 0.05rem;
}

.newbui .walletItemDiv .highlight-text span {
  background: red;
  color: #fff;
  display: inline-block;
  padding: 0.2rem 0.6rem;
  margin: -17px;
  margin-bottom: 5px;
  border-radius: 7px 0;
}

.newbui .accountParentDiv.instant-payment {
  width: var(--page-width);
}

.newbui .accountParentDiv.instant-payment .accountTopArea,
.newbui .accountParentDiv.instant-payment .walletDiv,
.newbui .accountParentDiv.instant-payment .transactions-wrap,
.newbui .accountParentDiv.instant-payment #walletAmountOptions,
.newbui .accountParentDiv.instant-payment .wallet__info,
.newbui .accountParentDiv.instant-payment .recharge-options,
.newbui .accountParentDiv.instant-payment .secure-payment,
.newbui .accountParentDiv.instant-payment .reviews,
.newbui .accountParentDiv.instant-payment .wallet__transactions_link {
  display: none;
}

.newbui .accountParentDiv.instant-payment .astrologer-reserved-message {
  display: none;
}
