.searchWrap {
  padding: 26px 18px 0 18px;
  height: 60px;
  margin-bottom: 16px;
}

.sideDrawerList {
  width: 242px;
}

.searchDropdown,
.searchInput,
.searchIcon,
.searchDropdown .MuiSelect-select,
.searchDropdown .MuiSelect-select:focus {
  background-color: #fdf7ec;
}

.searchInput.white,
.searchIcon.white {
  background-color: #fff;
}

.MuiButton-root {
  text-transform: none !important;
}

div.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  left: auto;
  right: auto;
  max-width: 460px;
}

.sticky+.searchBar {
  padding-top: 48px;
}

.searchDropdown {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 15px;
  padding-top: 2px;
  border-right: 1px solid #d2d2d2;
}

.price-group {
  color: #ee600d;
  display: inline-block;
  line-height: 0.9rem;
  font-size: 0.9rem;
  white-space: break-spaces;
  padding-left: 4px;
}

.searchDropdown .MuiInput-root {
  max-width: 100%;
}

.searchDropdown .MuiInput-root,
.searchDropdown .MuiInput-underline::before,
.searchDropdown .MuiInput-underline::after {
  border: 0px !important;
}

.searchIcon {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.searchInput,
.searchIcon {
  background-color: #fdf7ec;
}

.searchIcon svg {
  margin-top: 12px;
  margin-right: 10px;
  float: right;
  fill: #6d6d6d;
}

.searchInfo {
  padding: 0 10px 0 25px;
  font-size: 12px;
  color: #595959;
}

.searchInput {
  padding-top: 4px;
  padding-left: 18px;
  border-radius: 25px 0 0 25px;
}

.searchInput .MuiInputBase-input {
  font-size: 14px;
}

.titleSectionWrap {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #2a2a2a;
  color: #ffff00;
  width: 100%;
  /* position: fixed; 
  bottom: 0;
  z-index: 100;*/
}

.titleSectionWrap button {
  background-color: transparent;
  color: #ff0;
  border: none;
  border-radius: 16px;
  text-decoration: underline;
  font-size: 0.9rem;
}

.sortFlexBox.current {
  color: #ffff00;
}

.tab-panel {
  display: inline-block;
  width: 100vw;
  vertical-align: top;
  height: calc(100vh - 120px);
  background-color: #00000000;
  transition: transform 0.4s ease-out;
  scroll-snap-align: center;
  overflow-y: auto;
  max-width: 460px;
  transition: height 0.4s linear;
}

.tab-panel.expanded {
  height: calc(100vh - 2px);
}

.puja-homa.tab-panel {
  background-color: #f8ddb4;
}

.titleSectionWrap h3 {
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.listItemIcon svg {
  fill: #ffffff;
}

.titleSectionWrap>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleSectionWrap .MuiSvgIcon-root {
  font-size: 20px !important;
}

.astrologerDetailsWrap .MuiGrid-container {
  flex-wrap: nowrap;
}

.astrologerDetailsWrap .MuiAvatar-root {
  height: 74px;
  width: 74px;
  margin: 0;
  border-radius: 18px !important;
}

.astrologerDetailsWrap img {
  padding: 1px;
  border-radius: 16px;
}

.astrologerDetails {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.astrologer-profile {
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 104;
  transform: translate3d(-50%, 0, 10px);
  background-color: #ffffff;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
  max-width: 460px;
  display: none;
}

.astrologer-profile #astrologerBg {
  overflow-y: auto;
  height: 100%;
}

.astrologer-profile #fa-ast-story {
  position: absolute;
}

.astrologer-profile.open {
  display: block;
}

.astrologerRating {
  font-size: 1rem;
  text-align: center;
  margin-top: 12px;
  padding-top: 4px;
}

.astrologerRating b {
  color: #ee600d;
}

.astrologerRating svg {
  width: 16px;
  margin-top: -2px;
  vertical-align: middle;
}

.astrologerRating small {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8rem;
  color: #6d6d6d;
}

.astrologerReview {
  text-align: center;
  line-height: 1;
  margin-top: 14px;
}

.astrologerReview span {
  font-size: 12px;
}

.homeFilterBox,
#homeNotificationsBox,
#notificationsDiv {
  color: #ffe6d1 !important;
}

.homeFilterBox .MuiDivider-root {
  margin: 20px 0;
  background: white;
}

#homeNotificationsBox .MuiDivider-root {
  margin-top: 19px;
  margin-bottom: 9%;
  background: #ffe6d1;
}

.homeFilterBox .MuiDrawer-paper {
  background: #ee600d;
  height: 72%;
}

.homeSortBox .MuiDrawer-paper {
  height: auto !important;
  width: 100%;
  max-width: 450px;
  left: 50%;
  transform: translate3d(-50%, 0, 10px) !important;
}

.navDrawer .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.65);
}

#homeNotificationsBox .MuiDrawer-paper {
  background: #ee600d;
  height: 80%;
}

.dialogImage {
  width: 69px;
  height: 69px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #ffffff;
  position: relative;
  background-color: #f5d6a7;
}

.dialogImage img {
  max-width: 100%;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  opacity: 1;
}

.dialogImage img.loaded {
  opacity: 1;
}

.dialogHead .dialogTitle {
  display: inline-block;
  width: calc(100% - 78px);
  margin-left: 8px;
  vertical-align: middle;
}

.dialogHead span {
  display: block;
  font-size: 0.9rem;
}

.dialogHead span:last-child {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialogBody span {
  display: inline-block;
  margin-top: 4px;
  color: #575757;
}

.astrologerDialog {
  max-width: 440px;
  margin: auto;
}

.dialogBody .error,
.dialogBody .success {
  padding: 8px 0 0 0;
  color: #ff0000;
}

.pendingReviewPanel {
  transition: bottom 0.4s ease-out;
  position: fixed;
  bottom: -300vh;
  background-color: #ffffff;
  z-index: 107;
  width: 100%;
  left: auto;
  right: auto;
  max-width: 460px;
  box-shadow: 0 -4px 16px rgb(0 0 0 / 50%);
  padding: 16px;
  height: auto;
  padding-bottom: 64px;
  transform: translateZ(16px);
}

.pendingReviewPanel.open {
  bottom: 0;
}

.pendingReviewPanel .rating-error {
  animation: shakeerror 1.8s;
  animation-direction: alternate;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 72px 0;
  }
}

@keyframes shakeerror {

  0%,
  6%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  1%,
  5% {
    transform: translate3d(-10px, 0, 0);
  }

  3% {
    transform: translate3d(10px, 0, 0);
  }

  40%,
  60% {
    /* transform: translate3d(4px, 0, 0); */
  }
}

.smooth-height {
  will-change: height;
}

.autoHideHeader {
  position: fixed;
  width: 100%;
  max-width: 460px;
  z-index: 100;
  left: auto;
  top: 0;
  transition: top 0.4s ease-out 0.2s;
  will-change: top;
}

.autoHideHeader.folded {
  top: -64px;
}

.autoHideHeader.folded+.app-install-banner {
  top: calc(120px - 64px);
  opacity: 1;
}

.autoHideHeader~.searchBar .header-spacer {
  height: 118px;
}

.autoHideHeader.folded~.searchBar .header-spacer {
  height: 58px;
}

.autoHideHeader.folded~.searchBar~.tab-panel {
  height: calc(100vh - 58px);
}

.pendingReviewPanel .ratingBar {
  width: calc(100% - 112px);
  display: inline-block;
  margin-left: 8px;
}

.pendingReviewPanel .dialogImage {
  width: 94px;
  height: 94px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  vertical-align: top;
  margin-top: 4px;
}

.pendingReviewPanel .dialogImage .MuiAvatar-root {
  width: 94px;
  height: 94px;
  border-radius: 0;
}

.pendingReviewPanel .text {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  font-size: 0.9rem;
  line-height: normal;
  margin-bottom: 12px;
}

.pendingReviewPanel .reviewText {
  border: none;
  background-color: #eeeeee;
  border-radius: 5px;
  height: 64px;
  padding: 8px;
  color: #323232;
  font-size: 0.9rem;
  font-family: sans-serif;
  min-width: 100%;
  resize: none;
}

.pendingReviewPanel .reviewText::placeholder {
  font-family: sans-serif;
}

.dialogBody .success {
  color: #5e5e5e;
}

.astrologerDialog .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.65);
}

.astrologerDialog .MuiDialog-paperScrollPaper {
  background-color: #fbead0;
  border-radius: 18px;
  overflow: hidden;
  width: 90vw;
  margin: 8px;
  max-width: 400px;
}

.dialog {
  overflow-y: auto;
}

.dialogButton .MuiButton-label {
  white-space: nowrap;
}

.dialogButton.primary,
.dialogButton.secondary,
.dialogButton.booking,
.dialogButton.secondary:hover {
  color: #fff;
  border: none;
  background-color: #00c180 !important;
  height: 36px;
  border-radius: 18px;
  box-shadow: none;
  font-size: 0.9rem;
  min-width: 90px;
  padding: 0 16px;
  box-shadow: none !important;
}

.dialogButton.booking {
  background-color: #ee600d !important;
  margin-left: 4px;
  vertical-align: middle;
  padding: 0 12px;
  margin-top: 4px;
}

.dialogButton.booking:disabled {
  background-color: #cfcfcf !important;
}

.dialogButton.secondary,
.dialogButton.secondary:hover {
  background-color: #b9b9b9 !important;
}

.ctrl-group span {
  font-size: 0.8rem;
  color: #555;
  width: 40%;
  display: inline-block;
}

span.offerBanner {
  display: block;
  text-align: center;
  background-color: #795548;
  color: #ffffff;
  width: 100%;
  font-size: 0.8rem;
  padding: 6px 0;
}

span.warningBanner {
  display: block;
  text-align: center;
  background-color: #ffff00;
  color: #323232;
  width: 100%;
  font-size: 0.8rem;
  padding: 8px;
}

.dialogBody span:nth-child(even) {
  width: calc(60%);
  vertical-align: top;
}

.dialogBody span:nth-child(odd) {
  width: 40%;
  font-weight: 700;
}

.dialogBody {
  padding: 16px;
  font-size: 0.8rem;
}

.dialogBody a {
  text-decoration: underline;
  color: #ee600d;
}

.dialogHead {
  padding: 16px;
  background-color: #ee600d;
  color: #ffffff;
  border-radius: 18px 18px 0 0;
}

.homeFilterBox .MuiBackdrop-root,
#homeNotificationsBox .MuiBackdrop-root {
  background: none !important;
}

.homeFilterBox.homeSortBox {
  background: #000a;
  transform: translateZ(10px);
}

.filterOptionFlex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.filterOptionText {
  background: rgb(255 255 255 / 55%) !important;
  border: 1px solid rgb(255, 255, 255, 0.6);
  padding: 10px 0;
  margin: 10px 1% 0 1%;
  font-size: 0.9em;
  border-radius: 6px;
  width: 31%;
  text-align: center;
}

.filterOptionTextActive {
  background: rgb(255, 255, 255) !important;
}

#homeFilterBoxDiv {
  padding: 20px 25px 50px 25px;
}

.homeFilterBoxCategoryText {
  color: white;
  margin-bottom: 5px;
}

#notificationsFlexBox {
  display: flex;
  justify-content: space-between;
}

#notificationsDiv {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 25px;
}

#notificationsText {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.notificationsChildFlex {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

#newText {
  margin-bottom: 30px;
}

.notificationsChildHeading,
#newText {
  font-weight: bold;
}

#notificationsParentDiv {
  font-size: 0.766em;
}

.notificationsAvatar .MuiAvatar-colorDefault {
  height: 46px !important;
  width: 46px !important;
}

.notificationsDateDiv,
.notificationTextDiv {
  color: #ffe3bbb8;
  text-align: justify;
}

.notificationsAvatar .MuiAvatar-colorDefault {
  color: #fafafa !important;
  background-color: #ffbf6052 !important;
  padding: 4px !important;
}

#notificationsParentDiv .MuiGrid-spacing-xs-2>.MuiGrid-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 8px;
  padding-right: 8px;
}

.notificationsDateDiv {
  letter-spacing: -0.5px;
}

#helloGuestDrawerText {
  font-size: 1.8em;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 45px;
  color: #ffe6d1;
}

.homeFilterFooter {
  padding: 8px;
  background-color: rgb(255 255 255);
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: right;
}

.homeFilterButtons,
.homeFilterButtonsClear {
  margin-left: 5px;
  margin-right: 0px;
  padding: 3px 10px;
  border-radius: 3px;
}

.homeFilterButtonsClear {
  color: #ee600d;
}

.homeFilterButtons {
  color: white;
  background-color: #ee600d;
}

.circle-loading-20px {
  margin-left: 0px !important;
}

.onlineCardsOnly .offline,
.onlineCardsOnly .away,
.astrologerDetailsWrap .masked {
  display: none !important;
}

.toastBar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
  height: 28px;
  font-size: 0.8rem;
  line-height: 28px;
  border-radius: 0;
  white-space: nowrap;
  border-top: 1px solid #ef7938;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 80px;
}

.cashbackLabel {
  font-size: 0.8rem;
  color: #ffffff;
  background-color: #8a1a40;
  line-height: 0.9rem;
  transform: translateY(0);
  text-align: center;
  padding: 8px;
  border-radius: 12px 12px 0 0;
}

.toastBar.toastBarOpen {
  display: block;
  z-index: 100;
  opacity: 1;
  position: static;
  top: auto;
}

.toastBar.toastBarOpen.online {
  background-color: rgba(14, 154, 70, 0.9);
}

.toastBar.toastBarOpen.offline {
  background-color: rgba(0, 0, 0, 0.7);
}

.astrologerDetailsWrap {
  border-radius: 12px;
  background-color: #fdf7ec;
  margin-top: 14px;
  padding: 10px;
  height: auto;
  position: relative;
  overflow: hidden;
  transition: opacity 0.4s ease-out, height 0.4s ease-out 0.3s;
}

.tabbled-container-app {
  scroll-behavior: smooth;
}

.tabbed-head {
  background-color: #ee600d;
  color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
}

.tabbed-head span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
  height: 100%;
}

.tabbed-head.tabbed-head-col-2 span {
  width: 50%;
}

.tabbed-head span b {
  transition: background-color 0.4s ease-out;
  background-color: #ee600d;
  padding: 4px 16px;
  border-radius: 18px;
  opacity: 0.87;
  border-bottom: 4px solid #ee623c;
}

.tabbed-head.tabbed-head-col-2 span b {
  border-bottom: none;
  opacity: 1;
}

.tabbed-head span.current b {
  color: #ffffff;
  border-bottom: 4px solid #fff;
  border-radius: 0;
  opacity: 1;
}

.tabbed-head.tabbed-head-col-2 span.current b {
  background-color: #ffffff;
  color: #ee600d;
  border-bottom: none;
  border-radius: 18px;
}

.astrologerDetailsWrap button.busy {
  animation: progress-bar-stripes 2s linear infinite;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  text-align: center;
  color: #fff;
  background: #62c281;
  background-image: linear-gradient(45deg,
      #4d9a66 25%,
      transparent 25%,
      transparent 50%,
      #4d9a66 50%,
      #4d9a66 75%,
      transparent 75%,
      transparent);
  background-size: 36px 36px;
}

.astrologerDetailsWrap button.book.busy {
  background-image: none;
  background: #f17c3c;
}

.astrologerDetailsWrap button.busy:disabled {
  background-image: none;
}

.offerStampBanner {
  margin-top: 4px;
  padding: 8px;
  background-color: #f17c38;
  color: #ffffff;
  position: relative;
}

.nextCallOffer {
  height: auto;
  position: relative;
  overflow: hidden;
}

.nextCallOffer .backdrop {
  position: absolute;
  right: 0;
  top: -66px;
  width: 56%;
  opacity: 0.3;
}

.nextCallOffer .offerStampBanner {
  height: 100%;
  border-radius: 12px;
  background-color: #d1fb29;
  color: #5d5d5d;
  overflow: hidden;
}

.confetti {
  z-index: 1000;
  position: relative;
  transform: translate(100%, -48px);
  width: 50%;
}

.nextCallOffer .offerStampBanner .txtIcon {
  color: #ffffff;
  font-size: 1.3rem;
  left: 22px;
  width: 36px;
  top: 50%;
  transform: translateY(-50%);
}

.nextCallOffer .offerStampBanner .offerIcon {
  fill: #f17c38;
  width: 64px;
  height: 64px;
}

.offerStampBanner .offerText {
  width: calc(100% - 72px);
  line-height: 0.9rem;
  display: inline-block;
  font-size: 0.9rem;
  vertical-align: middle;
  padding-top: 2px;
}

.offerStampBanner .offerText small {
  font-size: 0.8rem;
}

.MuiSvgIcon-root.filterIcon {
  width: 0px;
  height: 0px;
  display: inline-block;
  vertical-align: middle;
  fill: #ee600d;
  transition: all 0.2s ease-out;
}

.filterButton.active .MuiSvgIcon-root.filterIcon {
  width: 16px;
  height: 16px;
  transform: translateY(-1px);
}

.MuiSvgIcon-root.filterIcon+b {
  font-weight: 400;
  display: inline-block;
  padding: 6px 4px 4px 4px;
}

.offerStampBanner .txtIcon {
  color: #f17c38;
  position: absolute;
  left: 10px;
  display: inline-block;
  width: 24px;
  top: 12px;
  font-weight: 700;
  text-align: center;
}

.expertiseFilter {
  text-align: left;
  padding: 24px 10px;
  color: #5e5e5e;
  font-weight: 700;
  font-size: 0.9rem;
}

.offerStampBanner .offerIcon {
  display: inline-block;
  margin-right: 8px;
  fill: #fff;
  vertical-align: middle;
}

.titleBarIcon {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 4px;
  color: white;
  font-size: 0.9rem;
}

.titleBarIcon svg {
  fill: white;
}

.offerStamp span {
  position: absolute;
  z-index: 2;
  right: -3px;
  text-align: center;
  top: 2px;
  width: 32px;
  line-height: 2.1rem;
  height: 32px;
}

.offerStamp {
  position: absolute;
  right: 12px;
  top: 8px;
  color: #ffffff;
  font-weight: 700;
  z-index: 4;
  width: 32px;
  height: 32px;
  border-radius: 0 12px 0 8px;
  text-align: center;
  line-height: 29px;
}

.promotionalList {
  /*border-radius: 0 0 10px 10px;*/
  overflow: hidden;
}

.promotionalList .offerStamp svg {
  fill: #8a1a40 !important;
}

.promotionalList .astrologerDetailsWrap {
  background-color: #ffdba4;
  border-radius: 0;
  margin-top: 0;
}

/*.promotionalList > div:last-child .astrologerDetailsWrap {
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #d6af74;
}*/
.astrologerProfileImage {
  border-radius: 50%;
  width: 12px;
  display: inline-block;
  height: 12px;

  vertical-align: middle;
}

.astrologerProfileImageRed {
  background-color: #9c9c9c;
}

.astrologerProfileImageGreen {
  background-color: #00c180 !important;
}

.notFoundPaper {
  margin: 5% 0;
  padding: 30px 10px;
  text-align: center;
  font-size: 19px;
}

#sideDrawerLogo {
  height: 30px;
  margin: 10px 0px 4px 0px;
}

#sideDrawerLogoDiv {
  border-bottom: 1px solid#ff903869 !important;
  text-align: left;
  padding-left: 16px;
}

.sortByText {
  color: white;
  margin: 10px 15px;
}

.sortFlexBox {
  display: flex;
  align-items: center;
  border-top: 1px #ffffff29 solid;
  padding: 10px 0px 10px 15px;
  color: white;
}

.sortOptionBox {
  width: 100%;
  margin-left: 15px;
}

.availableFilterActive {
  background-color: #ee600d !important;
  color: white !important;
}

#dailyIconDiv svg,
#dailyIconDiv p,
#blogIconDiv svg,
#blogIconDiv p,
#weeklyIconDiv svg,
#weeklyIconDiv p {
  pointer-events: none;
}

.searchWrap .MuiSelect-icon {
  top: calc(50% - 15px);
}

.filter-panel {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 460px;
  right: calc(-100vw - 48px);
  top: 0;
  z-index: 101;
  transition: transform 0.4s ease-out;
  display: flex;
  justify-content: flex-end;
  transform: translate3d(0, 0, 10px);
}

.filter-panel .container b,
.filter-panel .container .title {
  color: #ee600d;
  font-weight: 700;
  margin-bottom: 10px;
}

.apply-action>div+button,
.apply-action>div+button+button {
  height: calc(100% - 24px);
}

.apply-action .count,
.apply-action .error {
  height: 24px;
  white-space: nowrap;
  padding: 0 8px;
  color: #323232;
  background: #ffff00;
  font-weight: 700;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.apply-action .count.noonline,
.apply-action .error {
  background: #f00;
  color: #fff;
}

.filter-set.disabled {
  color: #f82c2c;
}

.apply-action button:disabled {
  background: #ffc8c8;
  color: #ff0000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-panel .container .warn-icon+span {
  display: none;
}

.filter-set label {
  max-width: calc(100% - 56px);
  display: flex;
}

.filter-set label:hover~svg.warn-icon+span,
.filter-set svg.warn-icon:hover+span {
  position: absolute;
  display: none;
  background-color: #323232;
  display: inline-block;
  padding: 10px;
  width: auto;
  z-index: 4;
  max-width: 260px;
  color: #fff;
  border-radius: 10px;
  right: 0;
  transform: translateY(24px);
}

.filter-set img.warn-icon+span b {
  margin-bottom: 10px;
  display: inline-block;
}

.filter-set img {
  opacity: 0.6;
}

.filter-set.none label {
  opacity: 0.5;
  filter: grayscale(1);
}

.apply-action button.disabled {
  background-color: #aaa;
}

.filter-set img.warn-icon:hover+span,
.filter-set img.warn-icon+span:hover {
  position: fixed;
  background-color: #fbead0;
  display: inline-block;
  padding: 16px;
  width: calc(100% - 48px);
  z-index: 105;
  max-width: 450px;
  font-size: 0.8rem;
  color: #323232;
  border-radius: 10px;
  right: 2.5%;
  top: 50%;
  max-height: 70vh;
  overflow-y: auto;
  transform: translate(0, -50%);
  box-shadow: 0 0 6px rgb(0 0 0 / 30%);
}

.pdl-xx {
  padding-left: 24px;
}

.filter-panel .container .primary-cta {
  color: #fff;
  padding: 5px 15px;
  z-index: 11;
  font-size: 0.8rem;
  min-width: 80px;
  background: #00c180;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 20px;
  border: none;
  height: 36px;
}

.filter-panel .container .section {
  line-height: 1.6rem;
  margin-bottom: 24px;
}

.filter-set input,
.filter-set input+span {
  display: inline-block;
  max-width: calc(100% - 27px);
  vertical-align: top;
}

.filter-panel .container {
  background-color: #fff;
  width: calc(100% - 32px);
  height: calc(100% - 10px);
  margin-top: 10px;
  max-width: 400px;
  box-shadow: -12px -5px 44px rgb(0 0 0 / 50%);
  padding: 16px 16px 16px 24px;
  overflow-y: auto;
  padding-bottom: 80px;
  border-radius: 18px 0 0 18px;
}

.filter-summary {
  position: fixed;
  left: 50%;
  bottom: 0;
  background-color: #323232;
  color: #fff;
  z-index: 100;
  width: 100vw;
  max-width: 460px;
  transform: translate3d(-50%, 0, 6px);
  display: flex;
  justify-content: space-between;
}

.filter-summary span {
  display: flex;
  align-items: center;
}

.filter-summary span b {
  color: #ffff00;
  padding: 4px 8px 4px 20px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
}

.filter-summary b {
  padding: 4px 8px;
}

.apply-action {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 32px;
  top: auto;
  height: 48px;
  background: #ee600d;
  padding: 0;
  height: 72px;
}

.apply-action button.cancel {
  background-color: #cfcfcf;
  color: #323232;
  width: calc(40% - 16px);
}

.apply-action button {
  border: none;
  background-color: #00c180;
  color: #fff;
  padding: 8px 16px;
  width: calc(60% - 16px);
  height: 100%;
  box-sizing: border-box;
}

.filter-panel.open {
  transform: translate3d(calc(-100vw - 48px), 0, 0);
  /*backface-visibility: hidden;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;*/
}

.filter-set input {
  margin-right: 10px;
}

.group-container>div:only-child>div:first-child {
  display: none;
}

.fab.filter-list {
  width: 56px;
  height: 56px;
  background: #6b1f32;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 54px;
  right: 18px;
  z-index: 102;
  transform: translate3d(0, 0, 5px);
}

.fab img {
  width: 24px;
  height: auto;
  fill: #fff;
}

.filter-set {
  display: flex;
  align-items: start;
  margin-bottom: 8px;
  position: relative;
}

.filter-item {
  padding: 10px 0;
}

.hidden {
  display: none !important;
}

@media (min-width: 801px) {
  .searchWrap {
    display: flex !important;
    margin-left: 0;
  }

  .searchInfo {
    padding: 0px 15px;
  }

  .titleSectionWrap {
    padding-top: 15px;
  }

  .apply-action {
    max-width: 432px;
    left: 60px;
  }
}

.switch-slider {
  display: inline-block;
  width: 46px;
  position: relative;
  margin-left: 4px;
}

.switch-slider .handle {
  position: absolute;
  background-color: #ffffff;
  display: block;
  height: 8px;
  left: 8px;
  width: calc(100% - 24px);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  z-index: 3;
}

.switch-slider input {
  opacity: 0;
}

.switch-slider input+i.roll {
  background-color: #cfcfcf;
  padding: 8px;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  transition: left 0.2s ease-out;
  z-index: 4;
}

.switch-slider input:checked+i.roll {
  left: 18px;
  background-color: #ee600d;
}

.titleSectionWrap>div .MuiAvatar-rounded:last-child {
  margin-right: 8px;
}

.testimonial {
  font-size: 0.8rem;
  padding: 4px 16px 24px 16px;
  background: #ffffff;
  margin-top: 26px;
  border-radius: 12px;
  color: #5a5a5a;
}

.testimonial span {
  color: #747474;
  margin-top: 12px;
  display: block;
  font-size: 0.9rem;
}

.review {
  text-align: right;
  font-size: 0.8rem;
  padding: 4px 16px;
  color: #ee6230;
}

.newAstrologer {
  position: absolute;
  z-index: 5;
  width: 119px;
  text-align: center;
  background: #f44336;
  color: #fff;
  transform: rotate(-45deg);
  left: -48px;
  top: -6px;
  line-height: 0.9rem;
  font-size: 0.8rem;
  padding: 16px 30px 6px 30px;
  font-weight: 700;
  box-shadow: 4px 3px 4px rgb(0 0 0 / 30%);
}

.promoListItems {
  overflow-x: auto;
  /* border: 2px solid #8a2840; */
  border-radius: 0 0 14px 14px;
  padding: 0 1px;
  margin-bottom: 2px;
}

.promoListItems .row {
  /* background-color: #ecd0a4; */
  padding-right: 0px;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

@media screen and (max-width: 600px) {

  .promoListItems .row::-webkit-scrollbar,
  .confirm-booking .dialog-content::-webkit-scrollbar {
    display: none;
  }
}

.promotionalList .astrologerDetailsWrap {
  margin: 0;
  margin-right: 8px;
  border-radius: 14px;
  background-color: #8a2840;
  width: calc(100vw - 56px);
  /* width: 306px; */
  max-width: 412px;
  color: #fff;

  margin-bottom: 8px;
}

.promotionalList .single .row .astrologerDetailsWrap {
  width: calc(100vw - 36px);
}

.fapromotionalList.full,
.noFavAstrologer {
  width: 100%;
}

.fapromotionalList.full .astrologerDetailsWrap {
  width: 100%;
  margin-top: 10px;
}

.promoListItems .row>div {
  display: inline-block;
  width: calc(100% - 30px);
  margin-bottom: 4px;
  scroll-snap-align: center;
}

.promoListItems .astrologerDetailsWrap {
  margin-bottom: 0px;
}

.promoListItems .row>div {
  margin-right: 8px;
  vertical-align: top;
}

.promoListItems .row>div.visible:last-of-type {
  margin-right: 0;
}

.promoListItems .row>div.visible:only-child {
  width: 100%;
}

.fapromotionalList .offerStamp svg {
  fill: #8a2840 !important;
}

/* high contrast promo theme */
.promotionalList .offerStamp svg {
  fill: #ffffff !important;
}

.promotionalList .astrologerDetails * {
  color: #fff !important;
}

.promotionalList .astrologerDetails button.away *,
.promotionalList .astrologerDetails .freemin {
  color: #5d5d5d !important;
  fill: #5d5d5d !important;
}

.promotionalList .offerStamp span,
.promotionalList .offerStamp small {
  color: #8a1a40 !important;
}

.promotionalList .astrologerRating small {
  color: #fff;
}

.promotionalList .n-title {
  padding-right: 70px;
}

.promotionalList .fav-icon {
  fill: #ffffff;
}

.promotionalList .fav-icon-dis {
  fill: #4f1725;
}

.noFavAstrologer {
  background-color: #fdf7ec;
  text-align: center;
  padding: 24px;
  border-radius: 14px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.MuiButton-root.away {
  background-color: #9f9f9f;
}

.astrologerDetailsWrap span.MuiButton-label {
  white-space: nowrap;
}

.group-container {
  background-color: #f8ddb4;
  padding: 1px 8px;
  margin: 0;
  border-radius: 12px;
  width: 100%;
  max-width: 460px;
}

.group-container:empty {
  display: none;
}

.group-container.offer-astrologers {
  background-color: #f8ddb4;
  min-height: 204px;
  border-radius: 0 0 20px 20px;
  margin-top: -16px;
  padding-top: 16px;
}

.group-container.offer-astrologers .pred-cover {
  background-color: transparent;
  height: 16px;
  border-radius: 0 0 20px 20px;
  width: calc(100% + 16px);
  margin: -16px 0 0 -8px;
  max-width: 460px;
}

.group-container.offer-astrologers:empty {
  visibility: hidden;
  min-height: auto;
  padding: 16px 8px;
}

.group-container-dp.predictions-gp {
  margin-top: 0;
  padding: 0;
  margin-bottom: 0;
  background-color: #fdf7ec;
  overflow: hidden;
  border-radius: 0;
  height: 330px;
}

/*https://opensource.apple.com/source/WebCore/WebCore-1889.1/css/mediaControls.css.auto.html*/
.ast-audio audio::-webkit-media-controls-current-time-display,
.ast-audio audio::-webkit-media-controls-time-remaining-display,
.ast-audio audio::-webkit-media-controls-mute-button,
.ast-audio audio::-webkit-media-controls-play-button,
.ast-audio audio::-webkit-media-controls-volume-slider,
.ast-audio audio::-webkit-media-controls-timeline,
.ast-audio audio::-webkit-media-controls-seek-back-button {
  filter: invert(1);
  text-shadow: none;
}

.ast-audio audio::-webkit-media-controls-panel {
  background-color: var(--audio-panel);
  border-radius: 0;
}

.ast-audio audio {
  box-shadow: none;
}

::-webkit-media-slider-thumb {
  display: none;
}

.ast-audio {
  --audio-panel: #8b0a2e;
  background-color: var(--audio-panel);
  border-radius: 0 0 10px 10px;
  height: 36px;
  overflow: hidden;
}

.promoListItems .ast-audio {
  --audio-panel: #323232;
}

.ast-audio audio {
  height: 48px;
  width: calc(100% + 46px);
  margin-left: -8px;
  margin-top: -6px;
}

.astrologerDetailsWrap.has-audio {
  border-radius: 10px 10px 0 0;
}

.book-controls .MuiInputAdornment-positionEnd {
  display: none;
}

.book-controls .MuiFormControl-root {
  width: calc(55% - 4px);
  margin-right: 4px;
  background-color: #fff;
  border-radius: 6px;
}

.book-controls .MuiOutlinedInput-input {
  padding: 12px;
  font-size: 0.9rem;
  background-color: #0000;
}

.book-controls .MuiSelect-select:focus {
  background-color: #0000;
}

.book-controls {
  padding: 16px 16px 0 16px;
}

.book-controls .ctrl-group {
  margin-bottom: 10px;
}

.book-controls .error-balance {
  color: #f00;
  display: inline-flex;
  align-items: center;
  padding: 7px 0;
  font-weight: 700;
}

.book-controls a.btn {
  text-decoration: underline;
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  padding: 10px 10px;
  color: #00c180;
  font-weight: 700;
  border-radius: 32px;
  width: auto;
  text-align: center;
  min-width: 80px;
}

.book-controls .error-balance svg {
  margin-right: 4px;
  width: 18px;
}

.ctrl-group img.info-icon {
  width: 24px;
  vertical-align: middle;
}

.ctrl-group .free-min+div {
  display: none;
  position: absolute;
  background: #000;
  width: 90%;
  padding: 8px;
  border-radius: 4px;
  transform: translateZ(10px);
  z-index: 100;
  color: #ccc;
}

.ctrl-group .free-min:hover+div {
  display: block;
}

.upcoming-bookings {
  position: fixed;
  bottom: 84px;
  background-color: #6b1f32;
  width: 56px;
  height: 56px;
  padding: 8px;
  color: #fff;
  text-align: center;
  /* box-shadow: 0 0 6px rgb(0 0 0 / 50%); */
  z-index: 99;
  font-weight: 700;
  overflow: visible;
  border-radius: 50%;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcoming-bookings span {
  display: block;
  width: 24px;
  height: 24px;
  background: #f00;
  border-radius: 50%;
  font-size: 0.8rem;
  padding: 4px 0;
  position: absolute;
  right: -4px;
  top: -4px;
  text-align: center;
}

.book-controls .MuiOutlinedInput-input:disabled,
.book-controls .MuiInputBase-input.Mui-disabled {
  opacity: 0.5;
}

.confirm-booking .dialog-content {
  width: 100%;
  overflow-y: auto;
  max-height: 60vh;
  padding-bottom: 40px;
}

.confirm-booking .book-slot {
  background-color: #e9e9e9;
  margin: 4px 0;
  font-size: 0.8rem;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  transition: all 0.4s ease-out;
}

.confirm-booking .book-slot-wrap {
  width: 100%;
  margin-bottom: 10px;
  min-height: 160px;
}

.confirm-booking .MuiFormControl-root {
  width: 160px;
  padding-left: 8px;
  margin: 14px 0;
}

.confirm-booking .MuiFormControl-root .MuiInputAdornment-root {
  display: none;
}

.confirm-booking .MuiFormControl-root input {
  padding: 8px 10px;
}

.confirm-booking .ctrl-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 324px) {
  .promotionalList .astrologerDetailsWrap button {
    width: 84px;
  }

  .promotionalList .astrologerDetailsWrap button span.MuiButton-label,
  .astrologerDetailsWrap button span.MuiButton-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  .promotionalList .astrologerDetailsWrap button span.MuiButton-startIcon,
  .astrologerDetailsWrap button span.MuiButton-startIcon {
    display: none;
  }

  .promotionalList .astrologerDetailsWrap .status-caption {
    display: inline-block;
    width: 84px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ast-card-ctabar .item:last-child {
    width: 50%;
  }

  .astrologerDialog .MuiDialog-paperScrollPaper {
    min-height: auto;
  }

  .dialogButton.booking {
    width: 80%;
    margin-top: 8px;
  }
}

/* .promoListItems .row > div:first-child .astrologerDetailsWrap {
  animation: astrologerCardSlideIn 0.4s ease-out 2s;
}
@keyframes astrologerCardSlideIn {
  from {
    margin-left: 66px;
  }
  to {
    margin-left: 8px;
  }
} */
.MuiButton-iconSizeMedium>svg {
  font-size: 20px;
  margin-right: 4px;
}

.MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-right: 0;
}