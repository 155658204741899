.new-style .astrologerInfo .ast-card-ctabar {
  background: transparent;
  width: auto;
  height: auto;
  margin: 0;
  /* gap: 0.5rem; */
}

.new-style .astrologerInfo .btn-action.MuiButton-root {
  color: white;
  border-radius: 0.5rem;
  font-weight: normal;
  font-size: 1rem;
  padding: 0.35rem 1.25rem;
  background-color: var(--clr-grey);
  min-width: 6.25rem;
  position: relative;
}

.new-style .astrologerInfo:not(.astrologerFooterBar) .btn-action.MuiButton-root ~ .btn-action.MuiButton-root {
  margin-left: 0.5rem;
}

.new-style .astrologerInfo .btn-action.online {
  background-color: var(--clr-green);
}

.new-style .astrologerInfo .btn-action.away {
  background-color: var(--clr-light-grey);
  color: #5d5d5d;
}

.new-style .astrologerInfo .btn-action.busy {
  --clr: rgba(0, 0, 0, 0.035);
  background-color: var(--clr-yellow) !important;
  /* background-image: linear-gradient(45deg, var(--clr) 25%, transparent 25%, transparent 50%, var(--clr) 50%, var(--clr) 75%, transparent 75%, transparent) !important; */
  background-image: none !important;
  color: var(--primary) !important;
}

.new-style .astrologerInfo .btn-action.busy ~ .btn-action.busy,
.new-style .astrologerInfo .btn-action.away ~ .btn-action.away {
  /* display: none; */
}

.new-style .astrologerInfo.astrologerSingleInfo .btn-action.busy {
  margin-top: 0.95rem;
}

.new-style .astrologerFooterBar .btn-action.busy .top-text {
  display: none;
}

.new-style .astrologerInfo .btn-action svg {
  font-size: 1.5rem !important;
  margin-right: 0.25rem;
}

.new-style .astrologerInfo .btn-action .top-text {
  position: absolute;
  top: -20px;
  font-size: 0.75rem;
}

.new-style .astrologerInfo .astrologerDetailsWrap {
  background: #fff !important;
  box-shadow: 0 0 0.5em rgb(0 0 0 / 15%);
  position: relative;
}

.new-style .astrologerInfo .astrologerDetailsWrap img {
  border-radius: 50%;
}

.new-style .astrologerInfo .rating {
  padding-top: 0.25rem;
}

.new-style .astrologerInfo .info-left {
  margin-bottom: -2.5rem;
  padding-right: 0.5rem;
}

.new-style .astrologerInfo .rating span {
  margin-right: -1px;
}

.new-style .astrologerInfo .astrologerDetailsWrap .MuiAvatar-root {
  --size: 5rem;
  height: var(--size);
  width: var(--size);
  border-radius: 50% !important;
}

.new-style .astrologerInfo .n-title {
  color: var(--primary);
  padding-bottom: 0;
  font-size: 0.98rem;
  padding: 0;
}

.new-style .astrologerInfo .info-block {
}

.new-style .astrologerInfo .info-block.limit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-style .astrologerInfo .astrologerDetails {
  padding-right: 2rem;
  padding-bottom: 0;
}

.new-style .astrologerInfo .fav {
  position: absolute;
  width: auto;
  height: auto;
  padding: 1rem;
}

.new-style .astrologerInfo .fav svg {
  --size: 1.45rem;
  position: static;
  fill: var(--clr-red);
  width: var(--size);
  height: var(--size);
}

.new-style .astrologerInfo .fav svg.fav-icon-dis {
  fill: var(--clr-light-grey);
}

.new-style .astrologerInfo .footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.new-style .astrologerInfo .price-wrap {
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.new-style .astrologerInfo .price-wrap.free .price {
  text-decoration: line-through;
  color: var(--clr-grey);
}

.new-style .astrologerInfo .price-wrap:not(.free) .price {
  color: var(--clr-red);
}

.new-style .astrologerInfo .price-wrap.free .free-text {
  color: var(--clr-green);
}

.new-style .astrologerInfo .newAstrologer {
  background: var(--clr-red);
  left: -44px;
  top: -3px;
  padding: 18px 30px 6px 30px;
  box-shadow: 4px 3px 4px rgb(0 0 0 / 10%);
  font-weight: normal;
}

.new-style .astrologerInfo .astrologer-infos {
  display: flex;
  justify-content: space-between;
}

.new-style .astrologerInfo .highlight-first {
  color: var(--clr-grey);
}

.new-style .astrologerInfo .highlight-first span:first-child {
  color: var(--primary);
}

.new-style .astrologerInfo {
  --avatar-wrap-width: 80px;
}

.new-style .astrologerLeft {
  width: var(--avatar-wrap-width);
}

.new-style .astrologerRight {
  padding-left: 14px;
  width: calc(100% - var(--avatar-wrap-width));
}

@media (max-width: 490px) {
  .astp.new-style .astrologerInfo:not(.astrologerFooterBar) .btn-action--deal-purchase .MuiButton-startIcon {
    display: none;
  }
}

@media (max-width: 360px) {
  .new-style .astrologerInfo .price-wrap {
    flex-direction: column;
    gap: 0;
  }

  .new-style .astrologerInfo .footer {
    margin-top: 0.25rem;
  }
}

@media (max-width: 370px) {
  .new-style .astrologerInfo .btn-action.MuiButton-root {
    padding: 0.35rem 0.25rem;
  }
}
