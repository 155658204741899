.astrologerModalContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 1em 0;
  padding-bottom: 0;
}

.astrologerModalContent :global(.pic) {
  --size: 6.66rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

.astrologerModalContent :global(.para) {
  font-size: 0.9rem;
}

.astrologerModalContent :global(.name) {
  font-size: 1.1rem;
  font-weight: 600;
}

.astrologerModalContent :global(.time) {
  color: var(--clr-grey);
}

.astrologerModalContent :global(.pic) {
  --size: 6.66rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

/* Astrologer Loading Modal */
.astrologerLoadingModal {
  white-space: normal;
}

.astrologerLoadingModal :global(div[class*="modalWrapper"]) {
  z-index: 1020;
}

.astrologerLoadingModal :global(div[class*="modalOverlay"]) {
  background: #fff;
  opacity: 1 !important;
}

.astrologerLoadingModal :global(div[class*="modalHeader"]) {
  background: #fff;
}

/* .astrologerLoadingModal :global(div[class*=modalWrapper]) {
  z-index: 999999;
} */

.astrologerModalContent :global(div[class*="error-availability"]) {
  color: var(--clr-red);
  margin: 0.25rem 0;
}

.astrologerModalContent :global(div[class*="buttons"]) {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  margin-top: 0.25rem;
}

.astrologerModalContent :global(button[class*="btn"]) {
  border-radius: 30px;
  background: var(--clr-green);
  color: #fff;
  box-shadow: none;
  font-weight: bold;
}

.astrologerModalContent :global(button[class*="btn-recharge"]) {
  background: var(--clr-secondary);
}
