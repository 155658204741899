.chatWrap {
  height: calc(100vh - 150px);
  overflow: scroll;
}

.chatWrapNew {
  height: calc(100vh - 40px);
  overflow: scroll;
}

.chatWrapNew :global(.chat-window) {
  width: 100%;
  right: 0;
  height: auto;
  top: 0;
}

.chatWrapNew :global(.chat-window-body) {
  height: calc(100% - 210px);
  padding-bottom: 0;
  padding-top: 0;
}

/* .chatWrapNew :global(.chat-window-footer) {} */

.chatWrapNew :global(.chat-not-accepted) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatWrapNew :global(.chat-message.meta:last-child) {
  margin-bottom: 0;
}

.chatWrapNew :global(.loaderWrap) {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatWrapNew :global(.loader) {
  color: var(--primary-light);
  opacity: 0.25;
}
