.chat-main-window {
  width: 100%;
}

.chat-latest-msg-wrapper {
  background: #fff;
  margin: 8px 8px 0 8px;
  border-radius: 12px;
  padding: 12px 8px;
  display: flex;
}

.chat-owner-msg-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  justify-content: space-evenly;
  width: calc(100% - 52px);
}

.chat-owner {
  font-weight: 700;
  color: #323232;
  font-size: 0.9rem;
}

.chat-latest-msg {
  font-size: 0.8rem;
  color: #000;
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.chat-avatar img {
  height: 100%;
}

.chat-avatar {
  height: 52px;
  width: 52px;
}

.chat-avatar .MuiAvatar-root {
  height: inherit;
  width: inherit;
}

.chat-latest-msg-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 75px);
  height: 35px;
}

.chat-latest-msg-time {
  width: 75px;
  text-align: right;
}

.chat-window {
  position: fixed;
  right: 24px;
  bottom: 0;
  width: 400px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 16px #000;
  z-index: 1301;
  transform: translateZ(12px);
  overflow: hidden;
}

.chat-window-except-mobile {
  position: relative;
  right: 0;
}

.MuiDialog-paperFullScreen {
  background: #e2d0b3 !important;
}

.chat-window-except-mobile {
  position: absolute;
  right: 50%;
  margin-right: -230px;
  width: 460px;
}

.chat-window-film {
  width: 100vw;
  height: 100vh;
  background-color: #000a;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1300;
  transform: translateZ(11px);
}

.chat-window-header {
  height: 58px;
  background-color: #ee600d;
  color: #fff;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.chat-window-header svg {
  width: 24px;
  right: 0;
  top: 0;
}

.chat-window-body {
  height: calc(100% - 172px);
  overflow-y: auto;
  padding: 12px;
  background-color: #f1f1f1;
}

.chat-window-footer,
.chat-form {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  transform: translateZ(10px);
}

textarea.chat-form-input-text {
  width: calc(100% - 40px);
  resize: none;
  border-color: #ccc;
  border-radius: 5px;
  height: 50px;
  font-family: "Roboto,sans-serif";
  font-size: 0.875rem;
  line-height: 1.43;
  font-weight: 400;
}

.chat-window-footer {
  height: 78px;
  flex-direction: column;
}

.chat-typing-status {
  height: 20px;
  font-size: 0.8rem;
}

.chat-form {
  padding: 8px;
  height: 58px;
}

.chat-window-footer input,
.chat-window-footer .input {
  height: 100%;
  display: inline-block;
  width: calc(100% - 48px);
  border: none;
  outline: none;
  padding: 8px 12px;
  font-size: 0.9rem;
  padding-top: 12px;
  border-radius: 24px;
  font-family: sans-serif;
  box-shadow: 0 0 2px #0004;
}

.chat-window-footer button {
  width: 48px;
  height: 48px;
  border: none;
  border-left: none;
  background: #ee600d;
  color: #fff;
  border-radius: 50%;
  margin-left: 8px;
  padding-left: 10px;
  padding-top: 4px;
}

.chat-message {
  margin: 8px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 0.9rem;
  border-radius: 8px;
  padding: 4px 10px;
  max-width: 80%;
}

.chat-message.left {
  background-color: #f28b4e;
  color: #fff;
}

.chat-message.right {
  margin-inline-start: auto;
  /* flex-wrap: wrap-reverse; */
  justify-content: flex-end;
  background-color: #fad3bd;
  color: #333;
}

.chat-message.full-width {
  width: calc(100% - 8px);
  max-width: 100%;
}

.chat-message.right .chat-message-meta {
  justify-content: flex-end;
}

.chat-status-read {
  color: #009df8;
}

.chat-message-text {
  display: flex;
  align-self: flex-start;
  padding: 0;
}

.chat-message-meta {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.chat-message-meta-time {
  font-size: 0.7rem;
  font-style: normal;
  opacity: 0.5;
}

.chat-action-bar {
  height: 36px;
  padding: 0 10px;
  font-size: 0.8rem;
  background-color: #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-action-bar button {
  background-color: #ee600d;
  color: #fff;
  border-radius: 24px;
  border: none;
  padding: 4px 12px;
}

.chat-action-bar button:disabled {
  background-color: #9f9f9f;
  opacity: 0.5;
}

.chat-message-meta-status {
  letter-spacing: -6px;
  margin-left: 4px;
}

.chat-message.meta {
  background-color: #dddddd;
  border-radius: 8px;
  text-align: center;
  margin: 16px auto;
  min-width: 100px;
  font-size: 0.8rem;
  padding: 8px 16px;
  max-width: 90%;
  color: #6f6d6d;
}

.chat-message.meta span {
  color: #323232;
}

.chat-message.meta.danger,
.chat-message.meta.danger span {
  background: #ffffff;
  color: #f00;
}

.chat-day-break {
  background-color: white;
  border-radius: 8px;
  font-size: 0.8rem;
  text-align: center;
  margin: 10px auto;
  margin-top: 24px;
  width: 120px;
  padding: 4px;
  color: #606060;
}

.chat-not-accepted {
  width: 100%;
  height: 100%;
  display: flex;
}

.chat-window-header .back {
  width: 48px;
  height: 48px;
  align-items: center;
  display: flex;
}

.chat-not-accepted > div {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-not-accepted button {
  background: #00c180;
  color: #fff;
  width: 60%;
  padding: 8px;
  border-radius: 24px;
  height: 40px;
}

.chat-not-accepted button:hover,
.chat-not-accepted button:focus {
  background: #00c180;
}

/* hiding chat script iframe */
body > iframe:last-child {
  top: 100vh !important;
}

.chat-window-previous-conversation {
  display: flex;
  justify-content: center;
}

.chat-window-previous-conversation button {
  background-color: #ee600d;
  color: #fff;
  border-radius: 24px;
  border: none;
  padding: 4px 12px;
}

div#chat-conv-empty {
  margin: 64px 36px;
}

.active-chat-ribbon {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #00c180;
  color: floralwhite;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: space-evenly;
  z-index: 1000;
  padding: 8px;
  font-weight: bold;
  box-shadow: -12px -5px 44px rgb(0 0 0 / 50%);
  cursor: pointer;
}

.active-chat-ribbon-desktop {
  max-width: 460px;
  left: 50%;
  margin-left: -230px;
}

.active-chat-status {
  display: flex;
  align-self: center;
}

/* .newbui .chat-right-btns {
 display: flex;
  gap: 0.5rem;
  flex: 1;
  padding-left: 0.5rem;
  justify-content: space-between;
} */

.newbui .chat-meta-link {
  text-decoration: underline;
  cursor: pointer;
}

.newbui .chat-meta-link.recharge {
  background: var(--clr-green);
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 0 0.35rem;
  margin: 0 0.05rem;
  border-radius: 1rem;
}

.newbui .chat-actionbar-timer.danger {
  color: var(--clr-yellow);
}

.newbui .chat-action-bar {
  background: var(--primary-light);
  color: #fff;
  padding: 0 0 0 16px;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
}

.newbui .chat-action-bar .chat-status {
  opacity: 1 !important;
}

.newbui .chat-action-bar .btn-action {
  border-radius: 0;
  padding: 4px 8px;
  height: 36px;
  min-width: 100px;
}

.newbui .chat-action-bar .btn-recharge {
  background-color: var(--clr-green);
}

.newbui .chat-action-bar .btn-end,
.newbui .chat-action-bar .btn-cancel-request {
  background-color: var(--clr-red2);
}

.newbui .chat-action-bar .btn-end:disabled {
  background-color: #9f9f9f;
}

.newbui .chat-message.meta.danger,
.newbui .chat-message.meta.danger span {
  background-color: #dddddd;
  color: #fc0101;
}

.newbui .PendingConsultationBox {
  background: #ececec !important;
  text-align: center;
  font-size: 97%;
}

.newbui .PendingConsultationBox-btn {
  display: none;
}

.newbui .chat-window-footer .chat-form button {
  background-color: var(--primary);
  width: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.newbui .chat-window-footer,
.newbui .chat-form {
  background: #fff;
}

.newbui .chat-window-footer {
  height: 75px;
  align-items: start;
}

.newbui .chat-form {
  padding: 5px 16px;
}

.newbui textarea.chat-form-input-text {
  border-radius: 35px;
  border: 0;
  background: #ececec;
  padding: 14px 20px;
}

.newbui textarea.chat-form-input-text:focus {
  outline: 1px solid var(--primary-light);
}

.newbui .chat-typing-status {
  height: 15px;
  position: relative;
  z-index: 10;
  text-align: left;
  margin-left: 23px;
}

.newbui .chat-action-bar-elapsed-timer {
  display: none;
}

.newbui .astrologer-display {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.newbui .inChat-avatar {
  --size: 28px;
  display: inline-flex;
  font-size: 100%;
  width: var(--size);
  height: var(--size);
}

.newbui .inChat-avatar-start {
  margin-bottom: -10px;
  margin-right: 10px;
  margin-top: 4px;
}

.newbui .chat-message.meta .inChat-avatar-start {
  margin-top: 0;
  margin-bottom: -15px;
  float: left;
}

.newbui .chat-wallet-balance {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  margin-left: auto;
  margin-right: 0.5rem;
}

.newbui .chat-wallet-balance .MuiSvgIcon-root {
  font-size: 0.95rem;
}

.newbui .chat-inactivity-timer.timer-running ~ .chat-wallet-balance {
  display: none;
}

/* .newbui .chat-action-bar.zero-balance {} */

/* .newbui .chat-action-bar.zero-balance .chat-status.status-pending .name,
.newbui .chat-action-bar.zero-balance .chat-status.status-completed .name, */
.newbui .chat-action-bar.zero-balance .chat-status .name {
  display: none;
}

.newbui .chat-message svg.MuiSvgIcon-root.check-icon {
  font-size: 0.9rem;
}

/* new-chat-ui */

.new-chat-ui {
  --clr-meta-bg: #f4f4f4;
  --clr-chat-left: #f3efb4;
  --clr-chat-right: #fffcd1;
}

.new-chat-ui .chat-window-body {
  padding-left: 0;
  padding-right: 0;
}

.new-chat-ui .chat-message.meta {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  background: var(--clr-meta-bg);
  color: #000;
}

.new-chat-ui .chat-message.left {
  --avatar-size: 32px;
  --avatar-gap: 10px;
  position: relative;
  margin-left: calc(var(--avatar-size) + calc(var(--avatar-gap) * 2));
  overflow: visible;
}

.new-chat-ui .chat-message.left .inChat-avatar {
  --size: var(--avatar-size);
  position: absolute;
  left: calc(calc(var(--avatar-size) + var(--avatar-gap)) * -1);
  margin-top: 0;
}

.new-chat-ui .astrologer-display {
  flex: 100%;
}

.new-chat-ui .astrologer-display .name {
  display: block;
  flex: 100%;
}

.new-chat-ui .chat-action-bar {
  background: #d8d8d8;
  color: #000;
}

.newbui .new-chat-ui .chat-action-bar button {
  background: rgba(255, 255, 255, 0.31);
}

.newbui .new-chat-ui .chat-action-bar .btn-recharge {
  color: var(--clr-green);
  border-right: 1px solid #b8b8b8;
}

.newbui .new-chat-ui .chat-action-bar .btn-recharge {
  background-color: var(--clr-green);
  color: #fff;
  border-right: 0;
}

.newbui .new-chat-ui .chat-action-bar .btn-end,
.newbui .new-chat-ui .chat-action-bar .btn-cancel-request {
  color: var(--clr-red2);
}

.newbui .new-chat-ui .chat-action-bar .btn-end:disabled {
  /* background-color: rgba(255, 255, 255, 0.31);
  opacity: .25; */
  background-color: rgb(92 92 92 / 24%);
  color: rgb(202 22 22 / 40%);
  opacity: 1;
}

.newbui .new-chat-ui .chat-actionbar-timer.danger {
  color: var(--clr-red2);
}

.newbui .chat-wrap.chat-question--enabled {
  --clr-chat-questions: #d4d4d4;
}

.newbui .chat-wrap.chat-question--enabled .chat-form {
  background: transparent;
}

.newbui .chat-wrap .chat-question-trigger-icon {
  display: none;
}

.newbui .chat-wrap.chat-question--enabled .chat-question-trigger-icon {
  display: flex;
}

.newbui .chat-wrap .chat-question-close-trigger-icon,
.newbui .chat-wrap .chat-question-open-trigger-icon {
  fill: #fff;
  color: #fff;
  transform-origin: center;
}

.newbui .chat-wrap.chat-question--enabled .chat-question-open-trigger-icon {
  display: block;
  animation: zoomie 1.5s infinite alternate;
}

.newbui .chat-wrap.chat-question--enabled.chat-question--open .chat-typing-status span {
  background: var(--clr-chat-questions);
  box-shadow: 0 0 0 2px var(--clr-chat-questions);
  /* padding: 0 0.4rem; */
  /* margin-left: 26px; */
  border-radius: 3px;
}

/** anim start **/
@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 72px 0;
  }
}

.active-consultation {
  animation: progress-bar-stripes 2s linear infinite;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  /* text-align: center; */
  color: #fff;
  background-color: #62c281 !important;
  background-image: linear-gradient(
    45deg,
    #4d9a66 25%,
    transparent 25%,
    transparent 50%,
    #4d9a66 50%,
    #4d9a66 75%,
    transparent 75%,
    transparent
  );
  background-size: 36px 36px;
}

/** anim end **/
@media screen and (max-width: 500px) {
  .chat-window {
    right: 0;
    width: 100vw;
    height: 100%;
  }
}

/** anim end **/
@media screen and (max-width: 320px) {
  .newbui .chat-action-bar .btn-action {
    min-width: inherit;
    padding: 4px 6px;
  }
}
