.astp {
  padding-bottom: 4.5rem;
  white-space: break-spaces;
}

.astp > div:not(.astrologerTopArea) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.astp .astrologerTopArea {
  display: flex;
  padding: 1rem 1.5rem;
  background: var(--primary);
  color: #fff;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
  min-height: var(--toolbar-height);
}

.astp .astrologerTopArea .title {
  margin-left: 1rem;
  margin-right: auto;
}

.astp .astrologerTopArea .astrologerArrowIcon {
  margin-top: 0 !important;
  font-size: 1.25rem;
}

.new-style .astrologerInfo.astrologerSingleInfo {
  padding: 0;
}

.new-style .astrologerInfo.astrologerSingleInfo .astrologerDetailsWrap {
  background: #f5f5f5 !important;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 1rem;
  border-radius: 0;
}

.astp .profileHeading {
  color: var(--primary);
  margin: 0.5rem 0;
}

.astp .profileHeading:after {
  content: ":";
}

.astp .aboutAstrologerPara {
  position: relative;
}

.astp .aboutAstrologerPara.close {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.astp .aboutAstrologerPara .showMore {
  position: absolute;
  bottom: 0;
  background: #fff;
  right: 0;
  font-weight: bold;
}

.astp .aboutAstrologerPara .showMoreBtn,
.astp .showMoreReviewsWrap .showMoreReviewsBtn {
  background: #fff;
  border: 0;
  font-weight: bold;
  color: var(--clr-red);
  font-family: var(--font-family);
  line-height: inherit;
  padding: 0 0.5rem;
  font-size: 0.8rem;
}

.astp .showMoreReviewsWrap .showMoreReviewsBtn.Mui-disabled {
  color: rgba(0, 0, 0, 0.5);
}

.astp .showMoreReviewsWrap {
  text-align: right;
  margin-top: 0.6rem;
}

.astp .aboutAstrologerPara.open .more {
  display: none;
}

.astp .aboutAstrologerPara.open .showMore {
  position: static;
  text-align: center;
  margin-top: 0.5rem;
}

.astp .astrologerWhiteBg {
  background: #fff;
}

.astp .profileReviewsWrap {
  background: var(--clr-light-yellow);
  border-radius: 1rem;
}

.astp .profileSection {
  text-align: left;
  padding-bottom: 1rem;
}

.astp .astrologerReviewItem:not(:first-child) {
  margin-top: 1.25rem;
}

.astp .astrologerReviewItemHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.astp .astrologerFooterBar {
  background: #fff;
  position: fixed;
  padding: 0 !important;
  bottom: 0;
  z-index: 105;
  max-width: 460px;
  margin: auto;
  width: 100%;
  display: flex;
  gap: 1px;
}

.astp .astrologerFooterBar button.btn-action.MuiButton-root {
  flex: 1 1;
  border-radius: 0;
  padding: 0.9rem;
}

.new-style.astp .astrologerInfo .footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.new-style.astp .astrologerInfo .info-block {
  white-space: initial;
  margin-bottom: 0.3rem;
}

.astp #sideIcons {
  display: none;
}
