.phoneNumber :global(input) {
  font-family: var(--font-family);
  font-size: 1rem;
  padding: 1rem 1.25rem;
}

.infoText {
  font-size: 0.95rem;
  margin: 1em;
}

.countryCode {
  font-family: var(--font-family);
  line-height: 42px;
  font-size: 1rem;
  padding: 0 4px;
  color: #5d5d5d;
  position: relative;
  top: 0.5em;
}

.otpField :global(input) {
  font-family: var(--font-family);
  font-size: 1.7rem;
  text-align: center;
  font-weight: 600;
  max-width: 200px;
  letter-spacing: 0.6em;
}

.phoneNumber :global(input):hover,
.otpField :global(input):hover {
  border-color: var(--primary);
}

.phoneNumber :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline),
.otpField :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: var(--clr-red);
}

.otpLink:global(.MuiButton-root) {
  color: rgb(24, 29, 188);
  font-weight: bold;
  background: transparent;
  border: 0;
}

.otpInfoText {
  color: #209f9f;
}
