.op50 {
  opacity: 0.5;
}
.astrologerTopArea {
  display: flex;
  padding: 35px 20px;
  flex-direction: row;
  justify-content: space-between;
  background: #ee600d;
}
.bottomCurve {
  height: 56px;
  border-radius: 0 0 18px 18px;
  background: #ee600d;
}
.profileImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 6px solid #ffffff;
  margin: auto;
  margin-top: -85px;
  background-color: #efefef;
  position: relative;
  overflow: hidden;
}
.newAstBadge {
  position: absolute;
  bottom: 0;
  background-color: #f41212;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 6px 0 6px 0;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.3);
}
.profileImage .imageAvatar {
  width: 100%;
  height: 100%;
  margin: 0;
}
.profileCard {
  text-align: center;
}
.highLights {
  border-radius: 10px;
  background-color: #efefef;
  margin: 16px 8px;
  display: flex;
}
.highLights .block {
  display: flex;
  width: 30%;
  padding: 8px;
  flex-direction: column;
}
.highLights .block > * {
  display: block;
}
.highLights .block big {
  font-size: 1.4rem;
}
.highLights .block:nth-child(2) {
  background-color: #00c180;
  color: #ffffff;
  width: 40%;
}

#astrologerBg {
  max-width: 650px;
  margin: 0 auto;
  background-color: #fff;
}

#astrologerBg .astrologerArrowIcon {
  color: white;
  margin-top: 3px;
}

.astrologerFavIcon {
  border-radius: 5px;
  padding: 5px;
  font-size: 32px !important;
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.3) !important;
}

.astrologerFavIconActive {
  color: #ffffff !important;
}

#astrologerShareIcon {
  border-radius: 5px !important;
  padding: 5px;
  font-size: 32px;
  color: #ffffff;
}

.astrologerWhiteBg {
  margin-top: 0px;
  background-color: #fbead0;
  min-height: auto;
}

#astrologerTopDiv {
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
}

.profileImageWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
}

#astrologerImage {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 18px;
  height: 90px;
  width: 90px;
  object-fit: cover;
  border-radius: 18px;
  border: 0px solid #ffffff;
}

.profileReviewWrap {
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileInfoWrap h3 {
  color: white;
  font-size: 1.5rem;
  margin: 23px 5px 23px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profileInfo {
  line-height: 26px;
  margin-bottom: 20px;
}

.chargeicons {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.chargeText {
  font-weight: 600;
  letter-spacing: 1px;
}

#callButtonMini {
  font-size: 24px;
  padding: 4px;
  border-radius: 5px !important;
  margin: 0 auto;
  pointer-events: none;
  color: #ee600d;
  background: #fdf2ec;
}

#astrologerRatingNumber1 {
  font-weight: 600;
  font-size: 26px;
}

#astrologerStarRating1 {
  font-size: 18px;
}

#astrologerCustomerRating1 {
  font-size: 12px;
  margin-top: 3px;
}

.aboutAstrolgerWrap {
  padding: 20px 20px 10px 20px;
  background-color: #fbead0;
}

.profileHeading {
  font-weight: bold;
  font-size: 0.9rem;
  display: inline-block;
  color: #ee600d;
}

.aboutAstrologerPara {
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.4rem;
}

.offerStar {
  display: inline-block;
  width: 36px;
  height: 36px;
  position: relative;
  text-align: center;
  line-height: 36px;
  color: #fff;
  vertical-align: middle;
}
.offerStar > svg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.offerStar > small {
  position: absolute;
  left: 50%;
  font-size: 0.7rem;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);
}
.offerStar > small b {
  font-size: 0.8rem;
  font-weight: 400;
}

.astrologerReadMore {
  text-align: right;
  margin-top: 5px;
}

.profileReviewsWrap {
  padding: 20px;
}

.profileReviews {
  padding: 20px 0;
}

.linearRatingSingle {
  display: flex;
  font-size: 0.8rem;
  line-height: 18px;
}

#Excellent,
#Best,
#Good,
#Average,
#Poor {
  float: center;
  width: 50%;
  margin-top: 6px;
  margin-right: 10px;
}

#linearRatingDiv .MuiLinearProgress-colorPrimary {
  background-color: #f7d7a6 !important;
}
#Excellent .MuiLinearProgress-barColorPrimary {
  background-color: #31ca31;
}
#Best .MuiLinearProgress-barColorPrimary {
  background-color: #9dd808;
}
#Good .MuiLinearProgress-barColorPrimary {
  background-color: #fdc716;
}
#Average .MuiLinearProgress-barColorPrimary {
  background-color: #f87b15;
}
#Poor .MuiLinearProgress-barColorPrimary {
  background-color: #e03109;
}

#astrologerRatingNumber2 {
  font-size: 50px;
  line-height: 50px;
  margin: 0;
}

#astrologerStarRating2 {
  font-size: 16px;
}

#astrologerCustomerRating2 {
  font-size: 0.8rem;
  margin-top: 0px;
  text-align: center;
}

.astrologerReviewsDiv {
  font-size: 0.9rem;
  padding: 10px;
  background-color: #f9dfb9;
  margin-bottom: 10px;
  border-radius: 10px;
}
.astrologerReviewsName {
  font-weight: 700;
  text-transform: capitalize;
  color: #5e5e5e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.astrologerReviewsDiv img {
  padding: 1px !important;
  border-radius: 50% !important;
}

.astrologerReviewsStarRatingDiv {
  font-size: 11px !important;
  margin-left: -2px;
}

.astrologerReviewsStarRating {
  font-size: 16px !important;
  margin-right: 10px;
}

.astrologerRatingNumber3 {
  font-weight: bold;
}

.astrologerReviewsTop {
  text-align: right;
  font-size: 0.8rem;
  opacity: 0.5;
}

.astrologerReviewReview {
  text-align: left;
  margin-top: 8px;
  font-size: 0.9rem;
  width: calc(100% - 8px);
}
.astrologerReviewsUName {
  padding: 4px 10px 10px 10px;
  font-size: 0.8rem;
  font-style: italic;
  display: inline-block;
  color: #ee600d;
}

#communicationDiv {
  position: sticky;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

.communicationIcon {
  padding-right: 7px;
}

.linearRatingSingleText {
  width: 35% !important;
}

#shareviaText {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 25px;
}
#shareParentDiv {
  text-align: center;
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
}
.socialMediaButton {
  border-radius: 50% !important;
  height: 45px;
  width: 45px;
}
.shareButtonCopyURL {
  background-color: #ffffff;
  padding: 9px;
  font-size: 45px !important;
  border-radius: 50% !important;
}
.socialMediaButton:focus {
  outline: none !important;
}
.socialMediaText {
  font-size: 0.8em;
}
#shareBox .MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 10px !important;
}
#shareBox .react-share__ShareButton {
  outline: none !important;
}
@media (max-width: 320px) {
  #shareBox .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 5px !important;
  }
  #astrologerTopDiv {
    grid-template-columns: 1.2fr 2fr !important;
  }
}
#shareBox .MuiDrawer-paper {
  height: 40%;
  background: rgb(236 236 236);
}
#shareBox .MuiBackdrop-root {
  background: none !important;
}

.circle-loading-20px {
  margin-left: 0px !important;
}

.noReviews {
  text-align: center;
  padding: 0 10px 0 0;
}
.profileInfoWrap path {
  pointer-events: none;
}
#communicationDiv .MuiButton-label,
#communicationDiv .MuiSvgIcon-root {
  pointer-events: none;
}

button.callButtonMiniButton,
button.callButtonMiniButton:hover {
  color: #fff;
  padding: 5px 15px;
  z-index: 11;
  font-size: 0.8rem;
  min-width: 80px;
  background: #00c180;
  font-weight: bold;
  border-radius: 20px;
}

button.callButtonMiniButton.booknow,
button.callButtonMiniButton.booknow:hover {
  color: #fff;
  padding: 5px 15px;
  z-index: 11;
  font-size: 0.8rem;
  min-width: 80px;
  background: #f17c38;
  font-weight: bold;
  border-radius: 20px;
}
button.callButtonMiniButton.booknow:disabled {
  background-color: #cccccc;
  color: #9f9f9f;
}

.profileInfoWrap span {
  pointer-events: none;
}

.favoriteButtonLoading {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  right: 66px !important;
  background-color: #ee600d !important;
  top: 40px !important;
  color: rgba(255, 255, 255, 0.56) !important;
  border-radius: 25px !important;
}

.warningModal {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 10px;
  max-width: 450px;
  width: 90%;
  margin: auto;
  text-align: center;
  outline: none;
}

.warningModalButton {
  background-color: #ee600d !important;
  margin: 30px 0px 15px 0px !important;
  color: white !important;
}

.astrologerProfileSadIcon {
  color: #555555;
  font-size: 100px !important;
}
.profileCloseIcon {
  font-size: 20px;
  display: flex;
  position: absolute;
  right: 24px;
}

.connect-modal-head {
  font-size: 1.4em;
  padding: 0 15px;
}

.connect-modal-subhead {
  font-size: 1em;
  padding: 0 15px;
  margin: 0 0 5px 0;
}

.connect-modal-wrap {
  font-size: 1.4em;
  padding: 0 15px;
  margin-bottom: 15px;
}

.connect-modal-wrap .MuiChip-root {
  margin-right: 5px;
}
button.callButtonMiniButton.offline,
button.callButtonMiniButton.away,
button.callButtonMiniButton.busy {
  background-color: #cfcfcf;
  color: #565656;
}
.astrologer-card .action-region .item button.call.busy {
  animation: progress-bar-stripes 2s linear infinite;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  text-align: center;
  color: #fff;
  background: #62c281;
  background-image: linear-gradient(
    45deg,
    #4d9a66 25%,
    transparent 25%,
    transparent 50%,
    #4d9a66 50%,
    #4d9a66 75%,
    transparent 75%,
    transparent
  );
  background-size: 36px;
}
.ast-section-title {
  color: #ee600d;
  padding: 32px 0 8px 0;
  text-align: center;
}
.profile-astro-message {
  font-size: 0.9rem;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 24px 24px 0 24px;
  margin-top: 16px;
}
.profile-astro-message:before {
  content: "❝";
  color: #cfcfcf;
  font-size: 4rem;
  position: absolute;
  left: -4px;
  top: -24px;
}
.profile-astro-message:after {
  content: "❝";
  color: #cfcfcf;
  font-size: 4rem;
  position: absolute;
  transform: rotate(180deg);
  right: -2px;
  bottom: -24px;
}
.profile-author {
  padding-left: 24px;
  font-weight: 700;
  margin-top: 8px;
  font-style: italic;
  opacity: 0.6;
}
.profile-author:before {
  content: "~ ";
}
.gallery .profileHeading {
  margin-top: 8px;
}
.ast-card-ctabar {
  display: flex;
  width: calc(100% + 20px);
  background-color: #f17c3c;
  margin-left: -10px;
  margin-bottom: -10px;
  height: 36px;
  justify-content: space-between;
}
.ast-card-ctabar .item {
  display: inline-flex;
  width: 50%;
  padding: 0;
  height: 100%;
  vertical-align: top;
  font-size: 0.8rem;
  max-width: 50%;
  align-items: center;
  flex-direction: row;
}
.ast-card-ctabar .item:only-child {
  width: 100%;
  max-width: none;
}

.confirm-booking .MuiDialog-paper {
  margin: 8px;
  width: calc(100% - 16px);
  border-radius: 16px;
}
.confirm-booking .dialog {
  padding: 16px;
  min-height: 80vh;
}

.confirm-booking .title {
  background-color: #ee600d;
  color: #fff;
  width: calc(100% + 32px);
  margin: -16px 0 0 -16px;
  padding: 16px;
  font-weight: 700;
}
.confirm-booking .details {
  font-weight: 400;
  padding: 10px;
  background-color: #fbead0;
  border-radius: 10px;
  margin: 10px 0;
}
.confirm-booking .content {
  padding: 16px 0;
}
.confirm-booking .buttons {
  width: 100%;
  margin: 0 0 -16px -16px;
  position: absolute;
  bottom: 16px;
}
.confirm-booking .buttons button {
  border: none;
  width: 50%;
  height: 48px;
  background-color: #ee600d;
  color: #fff;
  font-weight: 700;
}
.confirm-booking .buttons button:first-child {
  font-weight: 400;
  background-color: #bbb;
  color: #333;
}

.confirm-booking .book-slot.inactive {
  background-color: #eee;
  color: #999;
}
.confirm-booking .book-slot.selected {
  background-color: #00c180;
  color: #fff;
  width: calc(100% - 16px);
  margin-left: 16px;
}

/*.ast-card-ctabar .item:first-child {
  border-right: 1px solid #4dda8f;
}
.ast-card-ctabar .item:last-child {
  border-left: 1px solid #339b63;
}*/
.ast-card-ctabar .item button {
  border-radius: 0;
  height: 100%;
  width: 100%;
  margin-left: 0;
}
.ast-card-ctabar .item button:disabled {
  background-color: #cfcfcf;
}
.confirm-booking .book-slot.open.active span:last-child {
  color: #00aa00;
}
.confirm-booking .book-slot.inactive span:last-child {
  color: #ff6666;
}
.confirm-booking .book-slot.booked.inactive span:last-child {
  color: #fff;
}
.confirm-booking .book-slot.booked {
  background-color: #ff7e7e;
  color: #fff;
}
/* .ast-card-ctabar .item:first-child {
  max-width: 84px;
  border-right: 1px solid #fff;
  justify-content: center;
  font-weight: 700;
  background-color: #f5f5f5;
}
.ast-card-ctabar .item:last-child {
  width: auto;
  display: inline-flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0;
  border-left: 1px solid #fff;
} */
/** puja **/
.puja-list {
  padding: 10px 8px;
  overflow-x: hidden;
}
.puja {
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 18px;
  overflow: hidden;
}
.puja .info {
  padding: 10px 16px;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.puja .action button,
.puja-dialog.dialog button {
  width: 50%;
  color: #fff;
  padding: 5px 15px;
  height: 100%;
  font-size: 0.8rem;
  min-width: 80px;
  background: #00c180;
  font-weight: bold;
  white-space: nowrap;
  border: none;
  text-transform: capitalize;
}
.puja-dialog .checkout {
  position: fixed;
  width: 100vw;
  max-width: 450px;
  background-color: #000000b5;
  height: 100vh;
  left: 50%;
  bottom: 0;
  padding: 0;
  box-shadow: 0 0 6px #0005;
  transform: translate3d(-50%, 0%, 10px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 120;
}
.puja-dialog .checkout input[type="radio"] {
  transform: scale(1.3);
}
.puja-dialog .checkout .loc-item {
  margin-bottom: 10px;
}
.puja-dialog .checkout .scroller {
  height: calc(100% - 72px);
  padding: 16px;
  overflow-y: auto;
}
.puja-dialog .coupon {
  border: 1px solid #ccc;
  padding: 8px;
  margin-right: 8px;
  text-transform: uppercase;
  outline: none;
  border-radius: 2px;
}
.puja-dialog small {
  font-size: 0.7rem;
}
.puja-dialog .coupon::placeholder {
  text-transform: none;
}

.puja-dialog i.no-icon {
  display: inline-block;
  font-style: normal;
  padding: 0;
  background-color: red;
  color: #fff;
  width: 32px;
  text-align: center;
  height: 18px;
  border-radius: 10px;
  font-weight: 700;
  margin-left: 8px;
}
.puja-dialog i.yes-icon {
  display: inline-block;
  font-style: normal;
  padding: 0;
  background-color: #00c180;
  color: #fff;
  width: 32px;
  font-weight: 700;
  text-align: center;
  height: 18px;
  border-radius: 10px;
  margin-left: 8px;
}

.puja-dialog .checkout .body {
  background-color: #ffffff;
  padding: 16px 0;
  width: 100%;
  max-height: calc(100vh - 30px);
  padding-top: 0;
  padding-bottom: 64px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0 -8px 20px 3px #00000069;
  transform: translateY(102%);
  transition: transform 0.2s ease-out;
}
.priest-puja-info .photo {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.priest-puja-info .photo .MuiAvatar-root {
  width: 56px;
  height: 56px;
}

.puja-dialog .checkout .body.show {
  transform: translateY(0);
}
.puja-dialog .checkout .items span {
  display: inline-flex;
  width: calc(100% - 98px);
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
}
.puja-dialog .checkout .items span i {
  font-style: normal;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}
.priest-puja-info > div.title {
  display: block;
  font-weight: 700;
  color: #fff;
  padding: 8px 16px;
  padding-top: 24px;
  text-align: center;
}
.priest-puja-info.type-homa {
  background-color: #795548;
}
.puja-dialog .checkout .items span input {
  margin: 0;
  height: 14px;
  width: 14px;
  margin-right: 4px;
}
.puja-dialog .checkout .items span:nth-child(even) {
  width: 98px;
  align-items: flex-end;
  flex-direction: column;
}
.puja .details {
  width: 100%;
  max-height: 120px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  font-size: 0.9rem;
}
.puja .priests {
  color: #323232;
  padding-top: 8px;
}
.puja .act-item {
  display: inline-flex;
  width: 50%;
  padding: 0 16px;
  background-color: #ff0;
  height: 100%;
  align-items: center;
  font-weight: 700;
}
.puja .action {
  height: 48px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}
.puja .action strike {
  color: #555555;
}
.puja .image {
  width: 100%;
  height: 50vw;
  max-height: calc(412px * 0.56); /*0.56 -> 16:9 aspect ratio*/
  background-color: #efefef;
  margin-right: 10px;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}
.puja .image img {
  width: 100%;
}
.puja .title {
  text-align: center;
  color: #ee600d;
  font-size: 0.9rem;
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.homa .title {
  color: #795548;
}
.puja .title img.separator {
  margin-top: 2px;
  margin-bottom: 12px;
}
.puja .fees {
  padding: 0;
  margin-top: 12px;
}
.puja .fees span {
  display: inline-block;
}
.puja .fees .price-block {
  display: inline-block;
}
.puja .fees span.price {
  color: #00c180;
  font-weight: 700;
  font-size: 0.9rem;
  white-space: nowrap;
  margin-left: 8px;
}

.puja .action button {
  background-color: #ee600d;
  color: #fff;
  width: 100%;
}
.homa .action button {
  background-color: #795548;
  color: #fff;
  width: 100%;
}
.puja-dialog.dialog {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 100;
  max-width: 460px;
  transform: translateX(-50%);
  overflow: hidden;
}
.puja-dialog.dialog .content img {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 0px;
  border-radius: 0 0 36px 36px;
  box-shadow: 0 6px 6px #0003;
}
.puja-dialog.dialog > .title {
  background-color: #0005;
  color: #fff;
  font-weight: 700;
  padding: 24px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  z-index: 100;
  width: 100%;
  white-space: nowrap;
  transform: translateZ(10px);
  transition: all 0.4s ease-out;
  text-overflow: ellipsis;
}
.puja-dialog.dialog > .title span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer-perc {
  position: absolute;
  background-color: #ffeb3b;
  display: flex;
  width: 90px;
  height: 40px;
  color: #202020;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
  left: -8px;
  top: 14px;
  box-shadow: 2px 4px 4px 0px #00000087;
}
.offer-perc-bttn {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offer-perc-bttn span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 8px;
  color: #323232;
}
.offer-perc-bttn .priceValue {
  max-width: 100px;
  line-height: 0.8rem;
}
.offer-perc-bttn .priceValue strike {
  color: #525252;
  font-size: 0.8rem;
}
.offer-perc-bttn b {
  display: inline-block;
  margin-top: 4px;
  margin-left: 4px;
}
.offer-perc-bttn svg.perc {
  fill: #ee600d;
}
.offer-perc-bttn svg.price {
  fill: #00c180;
}
.puja-dialog.dialog .priest {
  margin-top: 56px;
  background-color: #fff;
  padding: 0;
  border-radius: 10px;
  position: relative;
}
.priest .name {
  color: #ee600d;
  font-size: 1rem;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-top: 42px;
}
.price-details {
  background-color: #f1f1f1;
  padding: 8px 0;
  border-radius: 0 0 10px 10px;
}
.price-details li {
  display: inline-block;
  width: 100%;
  color: #525252;
  vertical-align: top;
  text-align: left;
  padding-top: 8px;
  font-size: 0.8rem;
  background-color: #f1f1f1;
  padding: 16px;
}
.price-details li b {
  display: inline-block;
  width: 100px;
}
.price-details li div {
  margin-bottom: 4px;
}
.price-details li:nth-child(2) {
  border-left: 1px solid #aaa;
}
.price-details li div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  line-height: 1.4rem;
}

.priest .name .main-language,
.priest .name .languages {
  font-size: 0.8rem;
  color: #525252;
}
.priest .photo {
  width: 70px;
  height: 70px;
  display: block;
  position: absolute;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
  border-radius: 50%;
}
.priest .photo .MuiAvatar-root {
  width: 70px;
  height: 70px;
  border: 4px solid #fbead0;
}
.puja-list .puja-steps {
  padding: 0 32px;
}
.price-details li:last-child b {
  display: inline-block;
  min-width: 68px;
}
.puja-list .puja-highlights.list {
  background-color: #fff;
  padding: 16px 16px 8px 16px;
  border-radius: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.puja-list .puja-steps li {
  padding: 4px;
  padding-left: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.puja-dialog.dialog .content p:nth-child(odd) {
  font-weight: 700;
  margin-top: 16px;
  color: #ee600d;
}
.puja-dialog.dialog.type-puja > .title.active {
  background: #ee600d;
}
.puja-dialog.dialog.type-homa > .title.active {
  background: #795548;
}
/*.puja-list .puja-steps li::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  background-color: #ee623c;
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 10px;
}*/
.puja-list .puja-steps li span,
.puja-list .puja-steps li em {
  display: inline-flex;
  vertical-align: middle;
  width: calc(100% - 46px);
}
.puja-list .puja-steps li em {
  width: 38px;
  height: 38px;
  background-color: #00db92;
  font-style: normal;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
}
.white-marker {
  padding-top: 30px;
  width: calc(100% + 16px);
  margin-left: -8px;
  background-color: #fff;
  margin-top: -30px;
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 6px 6px #0003;
}
.puja-list .subfilter {
  padding: 16px;
  text-align: center;
}
.puja-list .subfilter .item {
  background: #ffffff;
  color: #795548;
  padding: 4px 14px;
  border-radius: 10px;
  margin: 0 4px;
  display: inline-block;
  margin-bottom: 8px;
  text-transform: capitalize;
  min-width: 40px;
}
.filter-pujas {
  padding-top: 16px;
}
.filter-pujas > div {
  display: flex;
  justify-content: center;
}
.filter-pujas li {
  display: inline-flex;
  align-items: center;
}
.filter-pujas li:first-child {
  padding-left: 0;
}
.filter-pujas .item {
  border: 1px solid #ee600d;
  color: #ee600d;
  padding: 4px 8px;
  border-radius: 0;
  margin: 0;
  display: inline-block;
  min-width: 60px;
  text-align: center;
  border-width: 1px 0 1px 1px;
  background-color: #fff;
}
.filter-pujas li .item:first-child {
  border-radius: 18px 0 0 18px;
}
.filter-pujas li .item:last-child {
  border-radius: 0 18px 18px 0;
  border-right: 1px solid #ee600d;
}
.puja .image .id-label {
  position: absolute;
  z-index: 10;
  color: #464646;
  right: 4px;
  top: 4px;
  background-color: #fff;
  padding: 0 4px;
  border-radius: 8px;
  font-weight: 700;
}
.filter-pujas .item.active {
  background-color: #ee600d;
  color: #fff;
  padding: 4px 8px;
  border-radius: 0;
  display: inline-block;
}
.puja-list .puja-steps .list {
  margin: 16px 0;
}
.puja-list .puja-highlights {
  margin-top: 8px;
  padding: 0 8px;
}
.puja-list .puja-highlights .item {
  border-radius: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #fff;
}
.puja-list .significance {
  font-size: 0.9rem;
  padding: 24px 16px;
  background: #ffffff;
  border-radius: 12px;
  color: #5a5a5a;
  line-height: 1.4rem;
}
.puja-list .significance .title,
.puja-list .puja-steps .title {
  color: #ee600d;
  margin-bottom: 10px;
}
.puja-list .puja-highlights .item svg {
  /* background: #ee600d;
  border-radius: 50%;
  border: 8px solid #ee600d; */
  width: 32px;
  height: 32px;
  margin: 0 8px;
  fill: #ee600d;
  vertical-align: middle;
  float: left;
}
.puja-list .puja-highlights .item span {
  background-color: #ffeed3;
  padding: 8px;
  border-radius: 14px;
  /* margin-left: 8px; */
  display: flex;
  color: #323232;
  width: 100%;
  align-items: center;
}
.cover-image {
  position: relative;
}
.cover-image .puja-fire-img {
  width: calc(100% + 16px);
  border-radius: 0 0 24px 24px;
  transform: translate(-8px, -8px);
  height: 300px;
}
.cover-image h3 {
  font-size: 2rem;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 48px;
  white-space: nowrap;
  transform: translate3d(-50%, -50%, 10px);
}
.puja-dialog .priest-list {
  text-align: center;
  margin-top: 36px;
  color: #795548;
  font-size: 1rem;
  padding: 0 16px;
}
.puja-dialog.type-homa .priest-list {
  color: #795548;
}
.puja-dialog .priest-list big {
  color: #ee600d;
}
.puja-dialog.dialog .content {
  padding: 10px;
  background-color: #fbead0;
  overflow: auto;
  height: calc(100% - 0px);
  padding-bottom: 24px;
  padding-top: 0;
}
svg.separator.svg-homa {
  fill: #795548;
}
svg.separator.svg-puja {
  fill: #ee600d;
}
.puja-dialog input + .prasad-cost {
  opacity: 0.3;
  text-decoration: none;
}
.puja-dialog input:checked + .prasad-cost {
  opacity: 1;
}
.puja-dialog.dialog .priest .action {
  margin-top: 8px;
  background: #efefef;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-bottom: -16px;
  border-radius: 0 0 10px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.puja-dialog.dialog .priest .action span,
.puja-dialog.dialog .priest .action button {
  display: inline-flex;
  width: 50%;
  height: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.puja .fees strike {
  color: #727272;
}
.puja .fees b {
  display: inline-block;
  min-width: 76px;
}
svg.separator {
  width: 180px;
  height: 16px;
  margin: auto;
  fill: #ee600d;
}
.type-homa svg.separator,
.type-puja svg.separator {
  fill: #795548;
}
.puja-list .section-title {
  color: #795548;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  padding: 10px 0;
  padding-top: 32px;
}
.puja-list .section-title.err {
  color: #ee600d;
}
.puja .details .short-desc {
  min-height: 64px;
}
.categorized-list.category .list-items,
.categorized-list.diety .list-items,
.categorized-list.multi .list-items {
  display: block;
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}
.categorized-list.category .list-items .puja,
.categorized-list.diety .list-items .puja,
.categorized-list.multi .list-items .puja {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  width: 85%;
  min-width: 310px;
  scroll-snap-align: center;
}
.categorized-list.category .list-items .puja:only-child,
.categorized-list.diety .list-items .puja:only-child,
.categorized-list.multi .list-items .puja:only-child {
  width: 100%;
}
.categorized-list.category .list-items .puja:first-child,
.categorized-list.diety .list-items .puja:first-child,
.categorized-list.multi .list-items .puja:first-child {
  margin-left: 0;
}

.puja .priests img {
  border-radius: 50%;
  width: 30px;
  background-color: #ccc;
  display: none;
}
.puja .priests img.visible + img {
  display: none;
}
.puja .priests img.visible {
  display: inline-block;
}
.puja .priests .avatar {
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  height: 30px;
}
.puja .priests .avatar:last-child {
  margin-right: 24px;
}
.profileType {
  color: #525252;
  margin-bottom: 8px;
  font-weight: 700;
}
.pujasByastrologer {
  text-align: left;
  padding: 24px;
  background-color: #fbead0;
}
.cat-heading {
  margin-top: 16px;
  margin-bottom: -10px;
  color: #ee600d;
  text-align: center;
}
.cat-heading b {
  background-color: #ee600d;
  padding: 4px 14px;
  color: #fff;
  border-radius: 24px;
  font-weight: 400;
}
.pujasByastrologer .pujaItem {
  margin: 24px 0;
  padding: 0;
  border-radius: 10px;
  background-color: #fdf7ec;
  height: 120px;
}
.pujasByastrologer .pujaItem li {
  display: inline-flex;
  max-width: calc(100% - 128px);
  flex-direction: column;
  justify-content: flex-start;
  vertical-align: top;
  margin-left: 8px;
  padding: 4px 8px 4px 0;
  height: 90px;
  overflow: hidden;
  align-items: self-start;
}
.pujaItem img {
  height: 90px;
  border-radius: 10px 0 0 0;
  display: inline-flex;
  vertical-align: top;
}
.pujaItem .title.type-puja {
  color: #ee600d;
}
.pujaItem .title.type-homa {
  color: #795548;
}
.pujaItem .price-bar span {
  color: #00c180;
  font-weight: 700;
  margin-left: 8px;
}
.pujaItem .price-bar strike {
  color: #444;
  font-weight: 400;
}
.pujaItem .price-bar button {
  border: none;
  background-color: #00c180;
  height: 100%;
  color: #fff;
  padding: 0 14px;
  border-radius: 0 0 10px 0;
}
.pujasByastrologer .actions button {
  width: 100%;
}
.non-loggedin-user {
  width: calc(100% + 32px);
  background-color: #fbead0;
  margin-left: -16px;
  padding: 16px;
  margin-bottom: -16px;
  padding-top: 24px;
  padding-bottom: 32px;
}
.non-loggedin-user > div {
  margin-bottom: 8px;
}
.non-loggedin-user label {
  display: inline-block;
  width: 120px;
}
.non-loggedin-user input {
  padding: 8px;
  display: inline-block;
  width: calc(90% - 120px);
  border-radius: 8px;
  border: 1px solid #ccc;
}
.non-loggedin-user i {
  color: #f00;
  padding-left: 2px;
  padding-right: 4px;
  font-style: normal;
}
.priest-puja-info {
  background-color: #ee600d;
  width: 100%;
  margin-left: 0;
  margin-top: 0px;
  border-radius: 12px 12px 0 0;
  padding: 10px 0;
}
.availability-details {
  text-align: left;
  padding: 0 10px;
  font-size: 0.8rem;
  margin-bottom: 24px;
}
.availability-details b,
.availability-details span {
  color: #323232;
}
.availability-details span {
  padding-left: 10px;
  padding-bottom: 4px;
  display: inline-block;
}
.availability-details i.online-yes,
.availability-details i.online-no {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #00c180;
  border-radius: 50%;
  margin: 0 4px;
}
.availability-details svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-left: 4px;
  height: 18px;
}
.availability-details i.online-no {
  background-color: #ff0000;
}
.availability-details .inactive,
.availability-details .active {
  margin-top: 4px;
}
.availability-details .inactive {
  opacity: 0.4;
}
.puja-dialog .checkout .loc-item span {
  justify-content: flex-start;
  width: 100%;
}
.puja-dialog .checkout .loc-item span input {
  margin-right: 12px;
}
.puja-dialog .checkout .loc-item .notice {
  font-size: 0.8rem;
  border-radius: 8px;
  padding: 8px;
  padding-left: 24px;
  color: #555;
}
.app-install-banner {
  background-color: #4ac281;
  color: #fff;
  position: fixed;
  width: 100vw;
  max-width: 460px;
  left: 0;
  top: 20px;
  z-index: 90;
  height: 48px;
  align-items: center;
  padding: 8px;
  display: flex;
  transition: all 0.4s linear;
  justify-content: space-between;
  box-shadow: 0 0 8px #000;
  opacity: 0;
  will-change: top;
}
.app-install-banner button {
  border: none;
  padding: 8px 24px;
  background-color: #fff;
  border-radius: 24px;
  color: #323232;
}
@media (max-width: 320px) {
  #shareBox .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 5px !important;
  }
  #astrologerTopDiv {
    grid-template-columns: 1.2fr 2fr !important;
  }
  .puja .act-item {
    width: 55%;
  }
  .puja .action button,
  .puja-dialog.dialog button {
    width: 45%;
  }
}
@keyframes flow-from-left-to-right {
  0%,
  5% {
    transform: translate3d(0, 0, 0);
  }
  90%,
  100% {
    transform: translate3d(calc(-100% + 100vw), 0, 0);
  }
}
@keyframes flash {
  0% {
    box-shadow: inset 0 0 66px 16px #fffa;
  }
  100% {
    box-shadow: none;
  }
}

#fa-ast-story > div {
  transition: background-color 2s ease-out;
}
#shareBox {
  transform: translateZ(10px);
}
.profileReviews {
  justify-content: center;
}

/** astrologer card css */
.group-container.no-padding {
  padding: 0;
}
.astrologer-cards-holder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
.astrologer-card-tile {
  width: calc(100vw - 24px);
  padding: 12px;
  scroll-snap-align: start;
  max-width: 460px;
}
.ast-section-title.online b {
  background: #00c180;
  color: #fff;
  padding: 6px 16px;
  border-radius: 18px;
}
.astrologer-card {
  --card-color: #ffffff;
  width: calc(100vw - 24px);
  max-width: calc(460px - 24px);
  height: auto;
  background: var(--card-color);
  border-radius: 24px;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 3px 10px #0000005c;
}
.astrologer-card.promo {
  --card-color: #feedda;
}
.astrologer-card .cover-region {
  height: 62vw;
  max-height: 300px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 24px 24px 0 0;
  border: 4px solid var(--card-color);
  border-bottom: 0;
}
.astrologer-card .cover-region.audio {
  height: calc(62vw - 36px);
}
.astrologer-card .cover-region img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.astrologer-card .cover-region .film {
  position: absolute;
  width: 100%;
  height: 72px;
  bottom: 0;
  background: linear-gradient(0deg, #000, transparent);
  color: #fff;
  display: flex;
  align-items: flex-end;
  padding: 8px;
  justify-content: space-between;
}
.astrologer-card .cover-region .film .rating {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  flex-direction: column;
}
.astrologer-card .cover-region .film .rating svg.fav-icon {
  height: 24px;
  width: 24px;
  fill: #fff8;
}
.astrologer-card .cover-region .film .rating svg.fav-icon.red {
  fill: #f44336aa;
}
.astrologer-card .cover-region .film .name {
  font-size: 1.1rem;
  width: calc(100% - 60px);
}
.astrologer-card .cover-region .film .name > div {
  white-space: nowrap;
  width: calc(100% - 10px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.astrologer-card .cover-region .film .name .experience {
  font-size: 0.9rem;
}
.astrologer-card .cover-region .film .rating svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-top: -2px;
  fill: #fe0;
}
.astrologer-card .info-region .price b {
  color: #00c180;
  font-size: 0.8rem;
  font-weight: 400;
}
.astrologer-card .info-region {
  height: calc(100% - 62vw - 40px);
  padding: 8px 16px;
}
.astrologer-card .action-region {
  height: 40px;
  display: flex;
}
.astrologer-card .action-region .item {
  width: 50%;
}
.astrologer-card .action-region .item button {
  width: 100%;
  background-color: #ee600d;
  height: 100%;
  border-radius: 0;
  color: #fff;
  font-size: 0.8rem;
}
.astrologer-card .action-region .item button:disabled {
  background: #cfcfcf;
  color: #6f6f6f;
}
.astrologer-card .action-region .item button.call {
  background-color: #00c180;
}
.astrologer-card .info-region .price {
  color: #ee600d;
  font-weight: 400;
  font-size: 1rem;
}
.astrologer-card .info-region .cashback {
  color: #ffffff;
  background: #00c180;
  padding: 2px;
  font-size: 0.8rem;
  border-radius: 2px;
}
.astrologer-card .info-region .cashback.special {
  color: #000000;
  background: #ffee00;
}
.astrologer-card .info-region .key-info {
  padding: 18px 0 12px 0;
  font-size: 0.8rem;
  height: auto;
  color: #525252;
}
.astrologer-card .info-region .key-info b,
.astrologer-card .info-region .key-info span {
  display: inline-block;
  width: 80px;
  vertical-align: top;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.astrologer-card .info-region .key-info span {
  width: calc(100% - 80px);
}
.astrologer-card .info-region .review-section {
  height: auto;
}
.astrologer-card .info-region .review-section p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.8rem;
}
.astrologer-card .info-region .review-section .review-head {
  color: #ee600d;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}
.astrologer-card .info-region .review-section i {
  color: #525252;
  font-size: 0.8rem;
}
.astrologer-card .ast-audio {
  --audio-panel: #8b0a2e;
  border-radius: 0 0 20px 20px;
  border: 4px solid var(--card-color);
  border-width: 0 4px 0 4px;
}
.astrologer-card .status {
  position: absolute;
  width: auto;
  height: auto;
  background: #cfcfcf;
  left: 12px;
  top: 12px;
  border-radius: 8px;
  padding: 0 8px;
}
.astrologer-card .status.online {
  background: #00c180;
  color: #fff;
}
.astrologer-card .info-region .price i {
  color: #00c180;
  font-style: normal;
  font-size: 1rem;
  font-weight: 700;
}
.astrologer-card .info-region .price {
  color: #323232;
  font-weight: 400;
  font-size: 0.8rem;
}
.astrologer-card .special-offer {
  position: absolute;
  background: #ffee00;
  color: #323232;
  right: 0;
  padding: 6px 12px;
  border-radius: 0 0 0 14px;
  font-size: 0.8rem;
  box-shadow: -2px 2px 6px #000a;
}
/*.astrologer-card .ast-audio audio::-webkit-media-controls-current-time-display,
.astrologer-card .ast-audio audio::-webkit-media-controls-time-remaining-display,
.astrologer-card .ast-audio audio::-webkit-media-controls-mute-button,
.astrologer-card .ast-audio audio::-webkit-media-controls-play-button,
.astrologer-card .ast-audio audio::-webkit-media-controls-volume-slider,
.astrologer-card .ast-audio audio::-webkit-media-controls-timeline,
.astrologer-card .ast-audio audio::-webkit-media-controls-seek-back-button {
  filter: none;
  text-shadow: none;
}*/
