a {
  text-decoration: none;
}

#loginTopBar,
#registerTopArrow {
  display: flex;
  padding-left: 0px;
  color: #2e2e2e;
  padding-top: 40px;
}

.userActionsArrow {
  color: #ff8400;
}

.loginTroubleText {
  opacity: 0.8;
  margin-top: 4px;
  margin-left: auto;
  margin-right: 25px;
}

.infoIcon {
  margin-right: -47px !important;
  margin-left: 35px;
  margin-top: 10px;
  font-size: 15px !important;
  color: #ee600d;
}

#otpTimer {
  display: block;
}

#triesText {
  font-size: 12px !important;
}

#resendBtn {
  color: #ee600d;
  text-align: center !important;
  margin-top: 10px;
}

.resendBtnWrap {
  text-align: center;
}

#otpFormDiv {
  text-align: center;
}

#otpFormDiv input {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  max-width: 200px;
  letter-spacing: 5px;
}

#otpFormDiv input:focus {
  outline: none !important;
  border-bottom: 1px solid #ee600d !important;
}

#loading {
  margin-top: 30px;
  text-align: center;
}

#registerPhoneLogo,
#loginPhoneLogo,
#otpPhoneLogo {
  display: block;
  margin: 40px auto 20px auto;
  height: 100px;
}

#registerText,
#loginText,
#otpText {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

#otpTopArrow {
  margin-left: 20px;
  color: #ff8400;
  padding-top: 24px;
  position: fixed;
  top: 0px;
}

#registerInfoText,
#loginInfoText,
#otpInfoText,
#otpTimer,
#triesText,
#triesDiv {
  display: block;
  text-align: center;
  margin: 10px 50px;
  opacity: 0.8;
}

#registerFormDiv,
#loginFormDiv,
#otpFormDiv {
  background-color: #f2f4f5;
  border-radius: 0px;
  padding: 24px 16px;
  margin: 20px 0 0 0;
  min-height: 40vh;
}

#otpFormInputAlign,
#registerFormInputAlign,
#loginFormInputAlign,
#triesDiv {
  display: flex;
  justify-content: center;
}

#registerLocSelect,
#loginLocSelect,
#registerLocSelect:focus,
#loginLocSelect:focus {
  background: white;
  border: 1px solid black;
  border-radius: 6px;
  margin-right: 10px;
}

#registerPhoneInput,
#loginPhoneInput,
#registerPhoneInput:focus,
#loginPhoneInput:focus {
  background: white;
  padding-bottom: 8px !important;
  border: 1px solid black;
  padding-top: 10px !important;
  border-radius: 6px;
}

#registerSubmitButton,
#loginSubmitButton,
#otpSubmitButton {
  color: white;
  width: 85%;
  margin: auto;
  display: block;
  margin-top: 25px;
  border-radius: 40px;
  padding: 10px;
  background: #ee600d;
}
#loginLaterButton {
  background: transparent;
  color: #5d5d5d;
  box-shadow: none;
  width: 100%;
  margin-top: 25px;
  border-radius: 40px;
  padding: 10px;
}

#registerSubmitArrow,
#loginSubmitArrow,
#otpSubmitArrow {
  color: white;
  border-radius: 24px !important;
  background: rgba(255, 255, 255, 0.35);
  border: none !important;
  position: absolute;
  right: 15px;
}

.registerExtensionOptions,
.loginExtensionOptions {
  font-size: 12px;
}
.userActionParent {
  padding-bottom: 1px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
}

.userActionParent .MuiSelect-selectMenu {
  width: 45px !important;
}

.userActionParent .MuiOutlinedInput-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.userActionParent .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 35px !important;
  padding-top: 8px !important;
}

.userActionParent .MuiAutocomplete-input,
.userActionParent .MuiAutocomplete-inputFocused {
  padding: 0px !important;
  padding-left: 5px !important;
}
.userActionParent .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 30px !important;
  width: 100px !important;
}
.signupnowCTA {
  color: #ee600d;
  font-weight: 600;
}
#registerFormDiv .MuiFormControlLabel-root,
#otpFormDiv .MuiFormControlLabel-root {
  margin-right: 0px;
  margin-top: 25px;
}
#registerFormDiv .MuiTypography-body1,
#otpFormDiv .MuiTypography-body1 {
  font-size: 13px;
  margin-left: 10px;
}
.loginTitle {
  display: block;
  text-align: center;
  width: 100%;
  color: #ed6237;
  font-weight: 700;
  font-size: 1rem;
}
.MuiFormControlLabel-label {
  text-align: left;
}
