.insufficientBalance {
  padding: 1.5rem;
  color: var(--clr-primary);
}

.insufficientBalance :global(h4) {
  font-size: 2rem;
  margin: 6px 0;
  font-weight: 400;
  color: var(--clr-red);
}

.walletStatus {
  display: flex;
  gap: 0.8em;
  margin-bottom: 1rem;
}

.walletStatus :global(.status) {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25em;
}

.walletStatus :global(.icon) {
  color: #898a97;
  margin-top: -0.25rem;
}

.walletStatus :global(.MuiSvgIcon-root) {
  font-size: 1.75rem;
}

.walletStatus :global(.title) {
  font-size: 0.9rem;
  font-weight: 600;
}

.walletStatus :global(.pts) {
  display: flex;
}

.walletStatus :global(.pts .name) {
  width: 65px;
}

.walletStatus :global(.pts .red) {
  color: var(--clr-red);
}
