/**/
.predictions {
  padding: 10px;
  padding-top: 30px;
  background-color: #fdf7ec;
}
.daily-placeholder {
  height: 330px;
}
.sign-block {
  margin: 0 0 16px 0;
}
.title-block {
  color: #323232;
  width: 100%;
  margin: 0;
  display: block;
  padding: 4px 0;
  position: relative;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 700;
  border-radius: 12px;
}
.title-block .switch input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.title-block .switch {
  border-radius: 24px;
  margin: auto;
  overflow: hidden;
  display: block;
  vertical-align: middle;
  font-weight: 400;
  margin-bottom: 4px;
  width: 200px;
}
.title-block .switch label {
  background: #f8ddb4;
  padding: 4px 8px;
  display: inline-block;
  transition: background 0.4s ease-out;
  width: 50%;
}
.title-block .switch input:checked + label {
  background: #ee600d;
  color: #fff;
}
.title-block .switch input:checked + label + label {
  color: #323232;
  background: #f8ddb4;
}
.title-block .switch input + label + label {
  background: #ee600d;
  color: #fff;
}
.content-block {
  white-space: nowrap;
  position: relative;
  font-size: 0.9rem;
  height: 220px;
  overflow: hidden;
  /* border-radius: 10px; */
  padding: 0;
  margin-bottom: 10px;
}
.content-block.open {
  height: auto;
  max-height: 60vh;
  overflow-y: auto;
}
.content-block .read-more {
  position: absolute;
  left: 0;
  bottom: 0;
  background: -webkit-linear-gradient(0deg, #fdf7ec 25%, rgba(0, 0, 0, 0));
  background: linear-gradient(0deg, #fdf7ec 25%, rgba(0, 0, 0, 0));
  width: 100%;
  padding: 10px 16px;
  padding-top: 84px;
  text-align: center;
  color: #ee600d;
  font-weight: 700;
}
.sign-block {
  transition: margin 0.4s ease-out, opacity 0.6s ease-out;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  white-space: normal;
  vertical-align: top;
  background-color: transparent;
  padding: 16px;
  border-radius: 10px;
  position: relative;
  /* line-height: 1.6; */
}
.content-block.open .sign-block {
  background-color: transparent;
  padding: 0 16px;
}
.content-block.open .sign-block .sign-name {
  left: 16px;
  top: 60px;
}
.sign-block.moved,
.sign-options.moved {
  margin-left: -100%;
  opacity: 0;
}
.sign-block img {
  margin: 0 18px 18px 18px;
  visibility: visible;
}
button.sign-cta,
button.sign-cta:hover {
  color: #fff;
  padding: 5px 15px;
  z-index: 11;
  font-size: 0.8rem;
  min-width: 80px;
  background: #00c180;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 20px;
  margin-top: 8px;
}
button.sign-cta:hover {
  opacity: 0.8;
}
.sign-name {
  position: absolute;
  top: 76px;
  left: 34px;
  width: 56px;
  text-align: center;
  font-size: 0.8rem;
  color: #ee600d;
}
.sign-icon-text {
  font-family: "astrovisionregular";
  font-size: 52px;
  color: #ee600d;
  position: absolute;
  left: 16px;
  top: 10px;
  width: 56px;
  text-align: center;
  font-weight: 400;
}
.sign-selector {
  position: absolute;
  width: calc(100% - 32px);
  max-width: 360px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fdf7ec;
  outline: none;
  padding: 0;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.sign-selector .sign-options {
  transition: margin 0.4s ease-out, opacity 0.6s ease-out;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  white-space: normal;
  vertical-align: top;
  padding: 16px;
  border-radius: 10px;
  position: relative;
}
.sign-selector .title-block {
  text-align: center;
  margin-top: 20px;
}
.sign-item {
  position: relative;
  display: block;
  width: 33.33%;
  height: 94px;
  float: left;
  margin-bottom: 12px;
}
.sign-item .sign-icon-text {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: 0;
}
.sign-item .sign-name {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: auto;
  bottom: 16px;
  color: #323232;
  font-weight: 700;
}
.sign-item .sign-ref {
  font-size: 0.7rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: auto;
  bottom: 0;
  position: absolute;
  white-space: nowrap;
  color: #5d5d5d;
}
.sign-cta-panel > div {
  text-align: center;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.title-block .title {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding: 0 8px 2px 8px;
  border-radius: 0;
  font-weight: 400;
  color: #151515;
  /* border-bottom: 2px solid #ee600d; */
  margin-bottom: 10px;
  margin-top: 16px;
}
.title-block .title.h3 {
  font-size: 1rem;
}
.sign-cta-panel {
  padding: 16px;
  background-color: #f8ddb4;
  border-radius: 10px;
}
.dp-no-profile {
  padding: 10px 0;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0;
}
.dp-no-profile button {
  padding: 8px 16px;
  border: none;
  background-color: #ee600d;
  color: #fff;
  border-radius: 10px;
}
.dp-no-profile .upd-bttn {
  margin-top: 8px;
}
.dp-no-profile .infobox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
}
.daily-widgets {
  border-radius: 0 0 20px 20px;
  background-color: #fdf7ec;
  overflow: hidden;
  position: relative;
}
.daily-widgets .widget-item-list {
  scrollbar-width: none; /* Firefox */
}
.daily-widgets .sign-cta-panel {
  display: none;
}
.daily-widgets.expanded .sign-cta-panel {
  display: block;
  position: absolute;
  bottom: 0;
  left: 4px;
  width: calc(100% - 8px);
  border-radius: 16px 16px 0 0;
}
.daily-widgets .widget-item-list {
  white-space: nowrap;
  overflow-x: auto;
  height: calc(100% - 124px);
  scroll-behavior: smooth;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
.daily-widgets ::-webkit-scrollbar {
  display: none;
}
.widget-item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  scroll-snap-align: center;
}
.widget-slide-marker {
  padding: 8px;
  font-size: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.widget-slide-marker .marker {
  background: linear-gradient(0deg, transparent 6px, #ee600d 6px, #ee600d 10px, transparent 10px);
  display: inline-block;
  padding: 8px 2px;
  margin: 0 6px 0 6px;
  opacity: 0.3;
  border-radius: 4px;
}
.widget-slide-marker .marker.active {
  opacity: 1;
}
.daily-widgets.expanded {
  position: fixed;
  width: 100vw;
  max-width: 460px;
  height: 100%;
  z-index: 104;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translate3d(-50%, 0, 1px);
  top: 0;
  border-radius: 0;
}
.daily-widgets.expanded .read-more {
  display: none;
}
.daily-widgets.expanded .content-block {
  height: auto;
}
.daily-widgets.expanded .daily-placeholder {
  max-height: none;
}
.daily-widgets.expanded .widget-item-list {
  height: 100%;
  /* padding-bottom: 126px; */
}
.daily-widgets .close-icon {
  fill: rgb(0 0 0 / 22%);
  width: 36px;
  height: auto;
  vertical-align: middle;
  margin: 8px 0 0 8px;
}
.daily-widgets .tap-left,
.daily-widgets .tap-right {
  position: absolute;
  width: 45%;
  background: rgba(238, 98, 60, 0);
  left: -30%;
  top: 20%;
  height: 60%;
  border-radius: 50%;
  transition: all 0.4s ease-out;
}
.daily-widgets .tap-right {
  left: auto;
  right: -30%;
}
.daily-widgets .tap-left.active,
.daily-widgets .tap-right.active {
  background: rgba(238, 98, 60, 0.1);
}
.timing-caption {
  /* font-style: italic; */
  padding-bottom: 16px;
  font-weight: 700;
  color: #ee600d;
  font-size: 0.8rem;
}
.sign-block p {
  display: block;
  margin-bottom: 8px;
}
li {
  list-style: none;
}
.numerology-items li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  max-width: 180px;
  text-align: center;
  margin: 0 0 16px 0;
}
.numerology-items li:last-child {
  display: block;
  margin: auto;
}
.ldir {
  display: block;
  width: 94px;
  height: 94px;
  position: relative;
  margin: auto;
}
.ldir .compass,
.ldir .needle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  margin: 0;
  top: 0;
  transition: rotation 0.4s linear;
}
.ldir.north .needle {
  transform: rotate(0deg);
}
.ldir.east .needle {
  transform: rotate(90deg);
}
.ldir.west .needle {
  transform: rotate(270deg);
}
.ldir.south .needle {
  transform: rotate(180deg);
}
.ldir.north-east .needle {
  transform: rotate(45deg);
}
.ldir.north-west .needle {
  transform: rotate(315deg);
}
.ldir.south-west .needle {
  transform: rotate(225deg);
}
.ldir.south-east .needle {
  transform: rotate(135deg);
}

/**/
.numerology.colors .box .color {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 8px auto;
  border: 1px solid #bebebe;
}
.numerology.colors .box .name {
  color: #ee600d;
  font-weight: 700;
  display: block;
  padding: 8px 10px 0 10px;
}
.numerology-items .lucky-number {
  font-size: 3rem;
  display: block;
  color: #ee623c;
}
.numerology.colors .box {
  display: inline-block;
  text-align: center;
}
.numerology .color.blue {
  background: #0090ff;
}
.numerology .color.red {
  background: red;
}
.numerology .color.black {
  background: #000;
}
.numerology .color.cream {
  background: #fffac2;
}
.numerology .color.grayish {
  background: #dadada;
}
.numerology .color.green {
  background: #11f100;
}
.numerology .color.milky {
  background: #feffea;
}
.numerology .color.orange {
  background: #ff9600;
}
.numerology .color.pink {
  background: #ff1595;
}
.numerology .color.white {
  background: #fff;
}
.numerology .color.yellow {
  background: #fffc00;
}
/**/
.panchanga li.diarysecondprediction,
.panchanga li.diaryfirstprediction,
.panchanga li.raahukaalstart,
.panchanga li.abhijitstart,
.panchanga li.day {
  margin-top: 16px;
}
.panchanga li b {
  display: inline-block;
  width: 40%;
  padding-right: 4px;
  color: #323232;
}
.panchanga li span {
  padding-left: 4px;
}
.panchanga .location svg {
  fill: #ee600d;
  width: 24px;
  height: auto;
  margin-right: 4px;
  margin-left: -12px;
}
.panchanga .location {
  margin-bottom: 20px;
  border: 2px dashed #ee623c;
  padding: 4px 16px;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  font-size: 0.9rem;
}
/**/
.mantra img.lord {
  width: 94px;
  border-radius: 50%;
  margin: -8px 0 16px 0;
  display: block;
}
.mantra li {
  margin-bottom: 16px;
}
.mantra li > * {
  display: block;
}
.mantra li.deity > * {
  display: inline-block;
}
.mantra li.deity span {
  padding-left: 4px;
}
.muhurtha-row {
  margin-bottom: 16px;
}
.muhurtha-row .elapsed {
  opacity: 0.5;
}
.chart {
  margin-top: 10px;
}
.widget-item:nth-child(even) .predictions {
  background-color: #fbf0da;
}
.widget-item:nth-child(even) .content-block .read-more {
  background: -webkit-linear-gradient(0deg, #fbf0da 25%, rgba(0, 0, 0, 0));
  background: linear-gradient(0deg, #fbf0da 25%, rgba(0, 0, 0, 0));
}
.daily-widgets.expanded .widget-item,
.daily-widgets.expanded .daily-placeholder,
.daily-widgets.expanded .predictions,
.daily-widgets.expanded .predictions .content-block {
  height: 100%;
}
.daily-widgets.expanded .predictions .content-block {
  padding-bottom: 126px;
  overflow-y: auto;
}
.panchanga .pob {
  background-color: #f1f1f1;
  padding: 16px 8px;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
  margin: auto;
  margin-top: 24px;
}
img.separator {
  display: block;
  width: 60%;
  margin: auto;
  margin-top: -8px;
}
.no-prediction + .read-more {
  visibility: hidden;
}
.muhurtha {
  position: relative;
  height: 220px;
  overflow-y: hidden;
}
.daily-widgets.expanded .muhurtha {
  height: auto;
  position: static;
}
@media screen and (min-width: 360px) {
  .title-block small {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 600px) {
  .widget-slide-marker {
    padding-top: 16px;
  }
}
