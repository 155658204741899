#parentDiv {
  margin-left: 20px;
  margin-right: 20px;
}

#orangeBg {
  background-color: #ee600d !important;
}

#whiteBg {
  margin-top: 50px;
  height: 100%;
  background-color: white;
  border-radius: 20px 20px 0 0 !important;
  margin-left: -20px;
  margin-right: -20px;
}

.ca-pac-container {
  z-index: 90;
}

.userProfileChildTopArea {
  margin-top: 28px;
  line-height: 6px;
  font-size: 17px;
}

.userProfileTopArea {
  display: flex;
  flex-direction: row;
}

#userProfileArrowIcon {
  color: #fff;
  margin: 24px 15px 0 0px;
}

#userProfileNameText {
  color: white;
  margin-top: 21px;
}

#userProfileImage {
  height: 130px;
  width: 130px;
  font-size: 2.6rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -193px;
  z-index: 1;
  border: 5px solid white;
  background-color: #ee600d;
}

#orangeBg .MuiFormHelperText-root.Mui-error {
  display: none;
}

#userProfileImage::after {
  content: "-";
  color: #ee600d;
  background-color: #000000;
  opacity: 0.3;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 91px;
  width: 130px;
}

#userProfileImageBorder {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -61px;
  z-index: 1;
  border-radius: 50% !important;
  border-spacing: 1px;
  width: 134px;
  height: 140px;
}

#camera {
  position: relative;
  top: -224px;
  color: white;
  opacity: 0.8;
  font-size: 22px;
  width: 100%;
  margin-right: auto !important;
  z-index: 3;
  margin-left: auto !important;
  margin-right: -11px;
}

#userProfileNameText2 {
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  margin-top: -205px;
  margin-bottom: 18px;
}

#userProfileFormDiv {
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
}

#userProfileFormDiv .MuiInputBase-root {
  height: 44px !important;
  background-color: #f7f7f7;
}

#userProfileFormDiv .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  width: 100% !important;
  height: 44px !important;
  background-color: #f7f7f7;
}

#userProfileFormDiv .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  font-size: 0.9rem !important;
}

.formInputs {
  width: 100% !important;
  padding-bottom: 20px !important;
}

#nameInput,
#mobInput,
#relationInput,
#emailInput,
#placeInput,
#horoInput,
#genderInput,
#dobInput,
#timeInput {
  font-size: 1rem;
}

#nameLabel {
  margin-top: 0 !important;
}

#nameLabel,
#mobLabel,
#relationLabel,
#emailLabel,
#placeLabel,
#horoLabel,
#genderLabel,
#dobLabel,
#timeLabel {
  float: left;
  margin-bottom: 3px;
  font-size: 0.9rem;
}

#userProfileFormDiv > label {
  display: none;
}

#userProfileFormDiv .MuiInputLabel-outlined {
  font-size: 1rem;
  margin-top: -2px;
}

#userProfileFormDiv .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}

#userProfileSaveButton {
  color: white;
  border-radius: 0;
  padding: 10px;
  background: #00c180;
  font-weight: bold;
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  z-index: 100;
  box-shadow: none;
  max-width: 450px;
  transform: translateX(-50%);
}

#userProfileSubmitArrowIcon {
  color: white;
  background: rgba(255, 255, 255, 0.35);
  border: none !important;
  position: absolute;
  border-radius: 20px !important;
  right: 15px;
}

.familyChildTopArea {
  margin-top: 25px;
  line-height: 6px;
  font-size: 19px;
}

.familyTopArea {
  display: flex;
  flex-direction: row;
}

#familyParentDiv {
  margin-left: 20px;
  margin-right: 20px;
}

.familyArrowIcon {
  color: #ff8400 !important;
  margin: 24px 15px 0 0px;
  background-color: #e563392b !important;
}

#familyText {
  color: #ee600d;
  margin-bottom: 37px;
  margin-top: 0;
}

.familyProfileDiv {
  background-color: #ee600d;
  height: 120px;
  width: 100%;
  color: white;
  border-radius: 10px !important;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  position: relative;
}

.familyProfileDivOthers {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(206, 206, 206);
  height: 120px;
  width: 100%;
  border-radius: 10px !important;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  position: relative;
}

.familyProfileDivOthers .MuiAvatar-root,
.familyProfileDiv .MuiAvatar-root {
  height: 70px;
  width: 70px;
  top: 25px;
  margin: auto;
  border-radius: 50% !important;
}

.familyProfileDiv .familyProfileDetailDiv,
.familyProfileDivOthers .familyProfileDetailDiv {
  font-size: 0.8em;
  line-height: 1.8em;
  display: flex;
  flex-direction: column;
}

.familyUserProfileName {
  font-weight: 400;
  color: #ee600d;
}

.familyUserProfilePrimary {
  font-size: 8px;
  position: relative;
  left: 33%;
  top: -67px;
}

#addUser {
  position: fixed;
  background-color: #ee600d;
  color: white;
  bottom: 4%;
  left: 78%;
  height: 55px;
  width: 55px;
  border-radius: 50% !important;
  padding: 12px;
}

.familyProfileDiv .familyEditButton,
.familyProfileDivOthers .familyEditButton {
  position: absolute;
  color: black;
  border-radius: 5px !important;
  background: #dedede;
  padding: 5px;
  top: 10px;
  right: 10px;
  font-size: 24px;
}

.familyProfileDiv .familyEditButton {
  color: white;
  background: rgba(255, 255, 255, 0.28);
}

#orangeBg .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

#orangeBg .MuiInput-root {
  border: 1px solid #b7b3b3;
  padding-left: 14px;
  background: #f7f7f7;
  border-radius: 5px !important;
}

#orangeBg .MuiSelect-selectMenu {
  width: 100% !important;
  text-align: left;
  font-size: 0.9rem;
}

#orangeBg .MuiOutlinedInput-input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

#orangeBg .MuiAutocomplete-input,
#orangeBg .MuiAutocomplete-inputFocused {
  padding: 0px !important;
  padding-left: 5px !important;
}

.user-profile-empty-wrap {
  text-align: center;
  margin-bottom: 30px;
}

#orangeBg input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.circle-loading-20px {
  margin: 0px !important;
}

#orangeBg .MuiAvatar-img {
  width: 110%;
  height: 110%;
}

.placeOfBirthTrim {
  display: unset;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

#familyProfileSaveButton {
  color: white;
  width: 100%;
  margin-top: 35px;
  border-radius: 40px !important;
  padding: 10px;
  background: #ee600d;
  font-weight: bold;
}

#userProfileFormDiv .MuiInputLabel-outlined[data-shrink="false"] {
  color: #ff4d4d;
}

.UPFormPreCC {
  position: relative;
}

.UPFormPreCC #orangeBg {
  background: #fff !important;
}

.UPFormPreCC .userProfileTopArea {
  display: none;
}

.UPFormPreCC #whiteBg {
  margin-top: 25px;
}

.UPFormPreCC .horoscope-style-input,
.UPFormPreCC #emailLabel,
.UPFormPreCC #emailLabel ~ .formInputs,
.UPFormPreCC .text-info {
  display: none;
}

.UPFormPreCC #userProfileSaveButton {
  position: static;
  max-width: unset;
  width: auto;
  transform: none;
}

.UPFormPreCC .footer {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  margin: 0 -20px;
}

.UPFormPreCC .MuiFormLabel-root.Mui-focused,
.UPFormPreCC .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  color: var(--primary);
  border-color: var(--primary);
}

.UPFormPreCC .circle-loading {
  position: absolute;
  top: 0;
  background: rgba(246, 246, 246, 0.25);
}

.newbui .ca-pac-container {
  z-index: 10000;
}
