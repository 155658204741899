.chat-window {
  --chat-header-height: 58px;
}

.chat-wrap.free-consult .circle-loading,
.chat-wrap.free-consult .chat-action-bar,
.chat-wrap.free-consult .chat-window-header,
.chat-wrap.free-consult .chat-window-footer,
.chat-wrap.free-consult .chat-window-previous-conversation,
.chat-wrap.free-consult .chat-message-text,
.chat-wrap:not(.free-consult) .free-consult-window .chat-window-header {
  display: none;
}

.chat-wrap:not(.free-consult) .free-consult-window .chat-window-header {
  /* display: flex;
  position: fixed;
  z-index: 200; */
}

.chat-wrap .free-consult-window {
  margin: -12px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-wrap.free-consult .free-consult-window {
  margin: 0;
}

.chat-wrap:not(.free-consult) .free-consult-window {
  margin-top: 0;
}

.chat-wrap.free-consult .free-consult-window {
  padding-top: var(--chat-header-height);
  height: 100vh;
}

.chat-wrap .free-consult-window .chat-window-header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.chat-wrap .free-consult-window .chat-window-body {
  height: auto;
  overflow-x: hidden;
}

.chat-wrap.free-consult .free-consult-window .chat-window-body {
  padding-top: 0;
  padding-bottom: 0;
}

.chat-wrap .free-consult-window .chat-window-footer {
  display: flex;
  margin-top: auto;
  height: auto;
}

.chat-wrap .free-consult-window .chat-message-text {
  display: flex;
}

.chat-wrap .free-consult-window .chat-message.highlight {
  background: var(--clr-green);
  color: #fff;
}

.chat-wrap .free-consult-window .chat-message.transparent {
  background: transparent;
}

.free-consult-window .chat-message.meta span {
  color: inherit;
}

.chat-wrap {
  height: 100vh;
}

.free-consult-window .info-text {
  background: var(--clr-meta-bg, "grey");
  width: 100%;
  padding: 1rem;
  text-align: center;
  white-space: pre-line;
}

.chat-wrap.free-consult .chat-window-body {
  height: 100vh;
}

.free-consult-window .astrologer-display {
  flex: 100%;
}

.free-consult-window .name {
  display: block;
  flex: 100%;
}

.free-consult-window .chat-message .chat-message {
  margin: 0 auto;
}

.free-consult-window .astrologer-card-component {
  padding: 0 1rem;
}

.free-consult-window .astrologer-card-component .ast-card-ctabar {
  display: none;
}

.checkCircle {
  color: var(--clr-green);
  margin-bottom: -7px;
  margin-left: 5px;
}

.component-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
  font-weight: normal;
}

.birth-details-component .selected-button {
  margin-bottom: -10px;
}

.birth-details-component .selected-button strong {
  font-size: 14px;
  color: var(--clr-green);
}

.birth-details-component .selected-button strong.no {
  color: var(--clr-red3);
}

.free-consultation .chat-main-window {
  display: none !important;
}

.astrologer-card-component {
  pointer-events: none;
}

/* Hide greetings msg */
.chat-messages:has(.free-consult-window) .chat-message:nth-child(6) {
  display: none;
}

/* Height adjustment for astrologer banner  */
.chat-window-body.status-pending {
  height: calc(100% - 229px);
}

.chat-window-body.status-pending ~ .chat-window-footer {
  height: 132px;
}

.chat-wrap.free-consult .chat-window-footer .chat-form button {
  align-items: center;
  justify-content: center;
}
