/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  -youbkit-touch-callout: none;
  -youbkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

html {
  background-color: #e2d0b3;
  height: auto;
  min-height: 100vh;
  font-size: 16px;
}

html,
div,
p {
  scroll-behavior: smooth;
}

.MuiAlert-filledWarning.warningSnackBar {
  background-color: #ee600d;
  position: fixed;
  left: auto;
  right: auto;
  width: calc(100% - 8px);
  margin-left: 0;
  max-width: 400px;
  border-radius: 16px 16px 0 0;
  transition: bottom 0.4s ease-out !important;
  padding-bottom: 24px;
  bottom: -50vh;
}

.MuiAlert-filledWarning.warningSnackBar.open {
  bottom: 0;
}

.MuiAlert-filledWarning.warningSnackBar .MuiAlert-action {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 16px;
  margin-top: 4px;
}

.MuiAlert-filledWarning.warningSnackBar .MuiAlert-icon {
  display: none;
}

html body {
  max-width: 460px;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
}

.rupee-symbol {
  font-family: sans-serif;
  font-weight: 700;
}

.desktop {
  max-width: 460px;
  margin: auto;
}

.circle-loading {
  position: fixed;
  background-color: transparent;
  height: 100%;
  margin-left: -20px !important;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

div.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

div.MuiSnackbar-anchorOriginTopCenter {
  top: 0;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  bottom: 56px;
  width: 100%;
  min-width: 320px;
  height: 100vh;
  background-color: #0009;
}

.pay-link div.MuiSnackbar-anchorOriginTopCenter {
  bottom: 0;
  height: 100%;
}

.creditGroup {
  width: 100%;
}

.creditGroup .name {
  padding: 4px 16px;
  background-color: #fff4e3;
  display: inline-block;
  color: #802f00;
  border-radius: 18px;
  margin-bottom: 8px;
  font-size: 0.8rem;
  text-shadow: 1px 1px #fff4df;
  margin-right: 4px;
}

.creditGroup .head {
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 4px;
  text-shadow: 1px 1px #fff8eb;
  color: #795548;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  margin-top: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.forceupdate {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

.forceupdate .dialog {
  background-color: rgb(251, 234, 208);
  padding: 16px 16px 32px 16px;
  border-radius: 16px;
  text-align: center;
}

.forceupdate .cta {
  padding: 24px 0px;
}

.forceupdate .cta button {
  background-color: rgb(238, 96, 13);
  color: rgb(255, 255, 255);
  border: none;
  padding: 14px 16px;
  border-radius: 24px;
}

.forceupdate img {
  width: 126px;
  display: block;
  margin: 24px auto 8px auto;
}

.forceupdate .sub {
  text-align: center;
  font-weight: 700;
  margin-bottom: 24px;
}

.forceupdate h2 {
  text-align: center;
}

.banner-svg {
  width: 90%;
  height: auto;
  margin: auto;
  display: block;
}
