::placeholder {
  font-family: "Roboto", sans-serif;
}

.astrologerConnectWrap,
.waitingListWrap {
  padding: 30px;
  background: #fbead0;
  height: 100vh;
}

.multi-ripple {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

#thankyouCancelButton {
  display: inline-block;
  box-shadow: none;
  height: 100%;
  width: 40%;
  font-size: 0.9rem;
  background-color: #efefef;
  color: #5e5e5e;
  border-radius: 0;
  font-weight: 400;
}

.multi-ripple .MuiAvatar-root {
  width: 60%;
  height: 60%;
  border-radius: 50%;
  margin: 20%;
  position: absolute;
  left: 0;
  border: 2px solid #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.ratingPanel {
  color: #5e5e5e;
  margin-top: 8px;
  width: 100%;
  max-width: 400px;
  padding-top: calc(100vh - 672px);
  padding-bottom: 56px;
}

#thankyouQuestionsDiv {
  margin-left: 10px;
}

.multi-ripple div.ripple {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0.3rem solid #ee600d;
  -webkit-animation: 1.5s ripple infinite;
  animation: 1.5s ripple infinite;
}

.multi-ripple div:nth-child(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@keyframes ripple {
  from {
    transform: scale(0.5);
    opacity: 1;
  }

  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

.waitingCloseIcon {
  font-size: 25px;
}

.timerImg {
  position: relative;
  margin-top: 30px;
  text-align: center;
}

.pleaseWaitText {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

.waitingNoteText {
  color: #000000a1;
  font-size: 14px;
}

.pleaseReceiveText {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 16vh;
}

.astrologerConnectBottomWrap,
.waitingListBottomWrap {
  text-align: center;
  margin-top: 30px;
  justify-content: space-around;
}

#dailyIconDiv,
#weeklyIconDiv {
  border-radius: 25px;
  padding: 30px 0px;
  max-width: 95px;
  font-size: 14px;
}

#dailyIconDiv {
  background-color: #ffead93b;
  border: 1px solid #ee600d;
}

#weeklyIconDiv {
  background-color: #ff005e;
  color: white !important;
}

#dailyIconDiv svg,
#weeklyIconDiv svg {
  font-size: 55px;
  padding: 10px;
  border-radius: 50%;
}

#dailyIconDiv svg {
  background-color: #ffb581;
  color: #ee600d;
}

#weeklyIconDiv svg {
  background-color: #ffe5e5;
  color: #ff005e;
}

.timerText {
  font-weight: 300;
  margin-top: 88px;
  color: #ee600d;
  opacity: 0.6;
  font-size: 2.5rem;
}

.congratsText {
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.4em;
  text-align: center;
  color: #44ca00;
}

.congratsText2 {
  font-size: 1.2em;
  text-align: center;
  color: #44ca00;
}

.timerText2 {
  position: absolute;
  color: white;
  margin-top: 35px;
}

#waitingQueueText {
  line-height: 100px;
  font-size: 3.5em;
  margin-bottom: 20px;
}

#waitingTime {
  color: #000000a1;
}

#waitingQueueUnderline {
  border-bottom: 3px solid#ff0047;
  padding-bottom: 3px;
}

#waitingTimeTime {
  color: black !important;
  font-weight: 900;
  border-bottom: 1px solid #ff0047;
}

#lastUpdated {
  font-size: 0.8em;
  color: #000000a1;
  margin-bottom: 20px;
}

#findSimilarAstrologers {
  font-size: 1.03em;
  margin-bottom: 20px;
  text-decoration: underline;
}

#waitingTimeCloseIcon,
#waitingTimeSearchIcon {
  padding-top: 10px;
  padding-right: 10px;
}

.emoji {
  font-size: 2em !important;
  padding: 3px;
}

.feedbackParentDiv {
  margin-left: 20px;
  margin-right: 20px;
}

.feedbackTopArea {
  display: flex;
  flex-direction: row;
}

.feedbackArrowIcon {
  color: #ff8400;
  margin: 26px 15px 0 0px;
}

.feedbackChildTopArea {
  margin-top: 27px;
  line-height: 6px;
  font-size: 19px;
}

.feedbackText {
  color: #ee600d;
  margin-bottom: 37px;
}

#emojiRatingDiv,
#starRatingDiv {
  text-align: center;
}

#starRating {
  font-size: 2.8em;
  padding-top: 10px;
}

#feedbackPrevIcon,
#feedbackNextIcon {
  border: 1px solid grey;
  color: grey;
  border-radius: 20px !important;
  font-size: 16px;
}

#feedbackPrev {
  font-size: 10px;
  margin-top: -2px;
  margin-right: 5px;
}

#feedbackNext {
  font-size: 10px;
  margin-top: -2px;
  margin-left: 5px;
}

#questionBar {
  align-items: center;
  display: flex;
  margin-bottom: -8px;
  justify-content: space-between;
}

#questionDiv {
  margin-right: 10px;
  margin-left: 10px;
}

#question {
  margin-bottom: 50px;
  height: 30px;
  text-align: justify;
}

#next {
  margin-right: -24px;
}

.thankyou {
  font-family: "Arizonia", cursive;
  font-size: 4.5em;
  text-align: center;
  position: relative;
  top: 50px;
  color: #ee600d;
  font-weight: 900;
}

#thankyou2 {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 15px;
}

#thankyouImg {
  width: 180px;
}

.you {
  margin-top: -40px;
}

.thankyouCloseIcon {
  font-size: 20px;
  text-align: right;
}

#prev_next .MuiButton-text {
  padding: 0px 5px;
  color: grey !important;
}

#prev_next {
  margin-right: 6px;
  margin-bottom: 2px;
}

#thankyouImage {
  height: 70px;
  width: 70px;
  border-radius: 18px;
}

#thankyouImageBorder {
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  border: 1px solid#ee600d;
  border-radius: 50%;
  border-spacing: 1px;
  width: 69px;
  height: 69px;
  border-style: dashed;
}

#thankyouName {
  font-weight: 900;
  font-size: 1.263em;
  display: flex;
  justify-content: center;
  position: relative;
  top: -60px;
}

#thankyouRate {
  font-size: 0.9rem;
  line-height: 1.2rem;
  display: block;
}

.thankyouStarRating {
  font-size: 2rem !important;
}

#thankyouParentDiv {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  padding-top: 16px;
  position: relative;
  overflow-x: hidden;
}

.callDetails {
  background-color: #fbead0;
  padding: 20px 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.callDetails span {
  display: inline-block;
  padding: 0 10px;
  font-size: 0.9rem;
}

.callDetails .rate {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #9f9f9f;
}

.callDetails .final {
  color: #00c180;
  padding-top: 10px;
  font-weight: 700;
}

#thankyouParentDiv .no-free-call {
  color: red;
  margin-top: 8px;
  font-size: 0.8rem;
}

.callDetails span:nth-child(odd) {
  width: 65%;
}

.callDetails span:nth-child(even) {
  width: 35%;
  text-align: right;
}

.callDetails span b {
  color: #ee600d;
}

.titleContainer {
  margin-top: 64px;
  font-size: 1rem;
  color: #ee600d;
  text-align: center;
}

.newbui .titleContainer svg {
  /* display: none; */
}

#feedbackLeaveCommentDiv {
  margin-top: -45px;
}

#thankyouLeaveComment,
#feedbackLeaveComment {
  font-size: 0.8923em;
  padding-bottom: 5px;
}

#thankyouCommentBox,
#feedbackCommentBox {
  width: 100%;
  padding-bottom: 34px !important;
  padding: 14px;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
  background-color: #eeeeee;
  resize: none;
}

#thankyouSubmitButton,
#feedbackSubmitButton {
  width: 60%;
  color: white;
  background: #00c180;
  border-radius: 0;
  font-size: 0.9rem;
  height: 100%;
  font-weight: 400;
  box-shadow: none;
}

.bottomBar {
  position: fixed;
  bottom: 0;
  display: block;
  width: 100%;
  left: 50%;
  height: 48px;
  border-top: 1px solid #cfcfcf;
  transform: translateX(-50%);
  max-width: 460px;
}

#thankyouSubmitArrow,
#feedbackSubmitArrow {
  border-radius: 20px !important;
  color: white;
  background: rgba(255, 255, 255, 0.35);
  border: none !important;
  position: absolute;
  right: 15px;
}

.callBalance {
  margin: 25px 10px;
  background-color: #f5f5f5;
  line-height: 30px;
  padding: 25px 20px;
  border-radius: 10px;
}

.callInfoFlex {
  display: flex;
  justify-content: space-between;
}

.callInfoGreen {
  color: #ee600d;
  font-size: 14px;
}

button.astrologerConnectBottoms {
  background-color: #01c180;
  margin: 15px auto !important;
  width: auto;
  min-width: 180px;
  color: white !important;
  border-radius: 24px;
  font-weight: 400;
}

.astrologerConnectFailMessage {
  margin-top: 15%;
  margin-bottom: 15%;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
}

.astrologerConnectBottoms {
  box-shadow: none !important;
}

.astrologerConnectCTA {
  text-align: center;
  font-size: 14px;
  margin: 5px 0 35px 0 !important;
}

.astrologerConnectOR {
  text-align: center;
  font-size: 14px;
}

@media (max-width: 320px) {
  #thankyouName {
    font-size: 1em !important;
  }

  .bottomBarText {
    font-size: 0.66em !important;
  }

  #prev_next {
    margin-right: 0px !important;
  }
}

.astrologerConnectWrap.newUI,
.waitingListWrap.newUI {
  background: #fff;
}

.newUI .multi-ripple div.ripple {
  border-color: var(--clr-green);
}

.newUI .multi-ripple {
  transform: scale(2) translateY(20px);
}

.newUI .timerImg > div:nth-child(2) {
  border-color: var(--primary) !important;
}

.newUI .multi-ripple .MuiAvatar-root {
  border: 0;
  box-shadow: 0 1.25rem 2rem rgba(0, 150, 136, 0.5);
}
